<ion-content class="bg-gray-200" [fullscreen]="true">
  <div class="flex flex-col flex-auto w-full bg-white border border-b-2 sticky top-0 z-50"
    [ngClass]="{'p-3': platform.is('desktop'), 'pt-8': !platform.is('desktop')}">
    <div class="flex flex-wrap w-full">
      <div class="flex items-center justify-between w-full">
        <div class="cursor-pointer p-3" (click)="backToHome()">
          <img class="logo-text w-36" src="assets/images/logo/logo-text.png">
        </div>
        <button class="mr-2" mat-icon-button (click)="backToHome()">
          <mat-icon style="font-size: 32px !important; width: 32px !important; height: 32px !important; color: #5ba74d"
            class="cursor-pointer hover:text-green-100" [svgIcon]="'home'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="flex flex-col bg-white">
    <div class="text-black py-6 p-4">
      <div class="flex flex-row items-center w-full">
        <h3 class="m-0" *ngIf="animal && !loading">{{ animal.identification }} {{ animal.name ? (animal.identification
          ?
          ' - ' + animal.name : animal.name) : '' }}</h3>
        <ion-skeleton-text class="py-2" animated style="width: 60%" *ngIf="loading"></ion-skeleton-text>
      </div>
    </div>
  </div>
  <div class="mt-2 p-4 mb-10">
    <div class="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
      <div class="flex items-center justify-center w-full">
        <div>
          <div class="flex items-center justify-center rounded-full overflow-hidden ring-4 ring-bg-card w-full">
            <div class="inset-0 flex items-center justify-center">
              <img class="object-cover avatar" [src]="animal && env.api + '/animal-photo/' + animal.id"
                onerror="this.src = 'assets/animal.svg'">
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full" *ngIf="loading">
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text class="pb-1" animated style="width: 60%"></ion-skeleton-text>
      </div>
      <div class="flex flex-col w-full" *ngIf="!loading">
        <div class="flex flex-row">
          <div class="font-bold mr-2">Pai: </div>
          <div>{{ animal && animal.dad || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row">
          <div class="font-bold mr-2">Mãe: </div>
          <div>{{ animal && animal.mother || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row">
          <div class="font-bold mr-2">Data de nascimento: </div>
          <div>{{ animal && animal.dateBirth || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">Último Parto: </div>
          <div>{{ animal && animal.dateLastPregnant || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">Com aborto: </div>
          <div>{{ animal && animal.withAbortion ? 'Sim' : 'Não' }}</div>
        </div>
        <div class="flex flex-row">
          <div class="font-bold mr-2">Sexo: </div>
          <div>{{ animal && animal.sex || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row">
          <div class="font-bold mr-2">Categoria: </div>
          <div>{{ animal && animal.productiveState || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">Estágio Reprodutivo: </div>
          <div>{{ animal && animal.reproductiveState || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">DEL: </div>
          <div>{{ animal && animal.del || '0' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.touroCobertura && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">Último touro informado em cobertura: </div>
          <div>{{ animal && animal.touroCobertura || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">Limite Cobertura: </div>
          <div>{{ animal && animal.limetedCoverage || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F'">
          <div class="font-bold mr-2">Cobertura: </div>
          <div>{{ animal && animal.dateCoverage || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F'">
          <div class="font-bold mr-2">Quantidade de Coberturas </div>
          <div>{{ animal && animal.quantifyCoverage || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">Diferença Limite Cobertura: </div>
          <div>{{ animal && animal.diferenceLimit || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">Dias Aberto: </div>
          <div>{{ animal && animal.openDays || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">Previsão de Parto: </div>
          <div>{{ animal && animal.dateNextPregnant || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">IEP dia: </div>
          <div>{{ animal && animal.iepDays || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">IEP mês: </div>
          <div>{{ animal && animal.iepMonth || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages !== 1">
          <div class="font-bold mr-2">Previsão de Secagem: </div>
          <div>{{ animal && animal.dateDrying || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages === 1">
          <div class="font-bold mr-2">Peso no nascimento: </div>
          <div>{{ animal && animal.primeiraPesagem + ' Kg' || 'Não informado' }}</div>
        </div>
        <div class="flex flex-row" *ngIf="animal.sex === 'F' && animal.idCurrentStages === 1">
          <div class="font-bold mr-2">Ultimo peso: </div>
          <div>{{ animal && animal.ultimaPesagem + ' Kg' || 'Não informado' }}</div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-2 mt-4">
      <div class="grid grid-cols-2 gap-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
        <button class="hover:bg-green-100" mat-stroked-button style="color: #5ba74d" (click)="editar(animal)">
          Editar
        </button>
        <button class="hover:bg-green-100" mat-stroked-button style="color: #5ba74d" (click)="deletarAnimal(animal)">
          Excluir
        </button>
        <button class="hover:bg-green-100" mat-stroked-button style="color: #5ba74d" (click)="vender(animal)">
          Vender
        </button>
        <button class="hover:bg-green-100" mat-stroked-button style="color: #5ba74d" (click)="morte(animal)">
          Morte
        </button>
      </div>

      <div class="flex flex-col w-full" *ngIf="animal && animal.sex === 'F' && animal.idCurrentStages !== 1">
        <div class="flex flex-col flex-auto bg-white shadow-md rounded overflow-hidden" *ngIf="!lactations.length">
          <div class="flex flex-row items-center justify-center p-6" style="background-color: #5ba74d">
            <div>
              <div class="font-bold text-md text-white uppercase tracking-wider">Periodos do animal</div>
            </div>
          </div>
          <div class="flex flex-col items-center justify-center p-4">
            <div>Animal não foi pesado</div>
          </div>
        </div>
        <div class="flex flex-col flex-auto bg-white shadow-md rounded overflow-hidden" *ngIf="lactations.length">
          <div class="flex flex-row items-center justify-between py-6 px-3" style="background-color: #5ba74d">
            <button mat-icon-button (click)="setPage(page-1)" [disabled]="page <= 0">
              <mat-icon class="text-white" [svgIcon]="'arrow_back_ios'"></mat-icon>
            </button>
            <div class="flex flex-col items-center justify-center">
              <div class="font-bold text-md text-white uppercase tracking-wider">Histórico de Lactação</div>
              <div class="text-sm text-white font-medium">Inicio: {{ lactations && lactations[page] &&
                lactations[page].start ? lactations[page].start : 'Sem período' }} - Fim: {{ lactations &&
                lactations[page] && lactations[page].end ? lactations[page].end : 'Sem período' }}</div>
            </div>
            <button mat-icon-button (click)="setPage(page+1)" [disabled]="page >= lactations.length-1">
              <mat-icon class="text-white" [svgIcon]="'arrow_forward_ios'"></mat-icon>
            </button>
          </div>
          <div class="overflow-auto">
            <table class="w-full bg-transparent" mat-table matSort [dataSource]="lactationDataSource"
              [trackBy]="trackByFn" #lactationTable>

              <ng-container matColumnDef="n">
                <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t" mat-header-cell mat-sort-header
                  *matHeaderCellDef>
                  <span class="whitespace-no-wrap">
                    Nº
                  </span>
                </th>
                <td mat-cell *matCellDef="let lactation">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
                    {{ lactation.n }}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="data">
                <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t" mat-header-cell mat-sort-header
                  *matHeaderCellDef>
                  <span class="whitespace-no-wrap">
                    Data
                  </span>
                </th>
                <td mat-cell *matCellDef="let lactation">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
                    {{ lactation.data }}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="dias">
                <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t" mat-header-cell mat-sort-header
                  *matHeaderCellDef>
                  <span class="whitespace-no-wrap">
                    Dias
                  </span>
                </th>
                <td mat-cell *matCellDef="let lactation">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
                    {{ lactation.days }}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="del">
                <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t" mat-header-cell mat-sort-header
                  *matHeaderCellDef>
                  <span class="whitespace-no-wrap">
                    DEL
                  </span>
                </th>
                <td mat-cell *matCellDef="let lactation">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
                    {{ lactation.del }}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="litros">
                <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t" mat-header-cell mat-sort-header
                  *matHeaderCellDef>
                  <span class="whitespace-no-wrap">
                    Litros
                  </span>
                </th>
                <td mat-cell *matCellDef="let lactation">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
                    {{ lactation.sum_weighings }}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="acumulado">
                <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t" mat-header-cell mat-sort-header
                  *matHeaderCellDef>
                  <span class="whitespace-no-wrap">
                    Acumulado
                  </span>
                </th>
                <td mat-cell *matCellDef="let lactation">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
                    {{ lactation.total_acumulado }}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="producao">
                <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t" mat-header-cell mat-sort-header
                  *matHeaderCellDef>
                  <span class="whitespace-no-wrap">
                    Produção Período
                  </span>
                </th>
                <td mat-cell *matCellDef="let lactation">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
                    {{ lactation.producaoPeriodo }}
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="lactationTableColumns"></tr>
              <tr class="order-row h-8" mat-row *matRowDef="let row; columns: lactationTableColumns;"></tr>
            </table>
          </div>
        </div>
      </div>

      <div matRipple class="flex mt-4 flex-col w-full bg-white rounded p-4"
        *ngIf="animal && animal.sex === 'F' && animal.idCurrentStages !== 1 && chartOptions.series[0].data.length">
        <div class="flex items-center justify-between">
          <div class="flex flex-col">
            <div class="font-bold text-md text-secondary uppercase tracking-wider">
              Curva de lactação
            </div>
            <!-- <div class="text-sm text-hint text-gray-500 font-medium">
              Reprodução do seu rebanho.
            </div> -->
          </div>
        </div>
        <div class="flex flex-row w-full items-center my-5">
          <div class="flex flex-col w-full" id="chart" *ngIf="!loadingLactacao">
            <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
              [stroke]="chartOptions.stroke" [dataLabels]="chartOptions.dataLabels" [grid]="chartOptions.grid"
              [colors]="chartOptions.colors">
            </apx-chart>
          </div>
        </div>
      </div>

      <app-historico-crescimento *ngIf="animal && animal.idCurrentStages === 1"></app-historico-crescimento>

      <app-resumo-bn [animal]="animal" *ngIf="animal && animal.idCurrentStages === 1"></app-resumo-bn>

      <div class="flex flex-col w-full">
        <div class="flex flex-row items-center justify-between w-full">
          <h3>Ocorrências</h3>
          <button class="hover:bg-green-100" mat-stroked-button style="color: #5ba74d" (click)="ocorrencia(animal)">
            Nova Ocorrência
          </button>
        </div>
        <mat-button-toggle-group class="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 ml-1 mt-2 mb-4"
          [(ngModel)]="selectedOccurence">
          <mat-button-toggle class="hover:bg-green-100" value="" (click)="filterOrorrencias('')" checked>Todas
          </mat-button-toggle>
          <mat-button-toggle class="hover:bg-green-100" value="manejo" (click)="filterOrorrencias('manejo')">Manejo
          </mat-button-toggle>
          <mat-button-toggle class="hover:bg-green-100" value="nutricao" (click)="filterOrorrencias('nutricao')">
            Nutrição</mat-button-toggle>
          <mat-button-toggle class="hover:bg-green-100" value="outros" (click)="filterOrorrencias('outros')">Outros
          </mat-button-toggle>
          <mat-button-toggle class="hover:bg-green-100" value="reproducao" (click)="filterOrorrencias('reproducao')">
            Reprodução</mat-button-toggle>
          <mat-button-toggle class="hover:bg-green-100" value="sanidade" (click)="filterOrorrencias('sanidade')">
            Sanidade</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="grid grid-cols-1 gap-4 w-full" *ngIf="filteredOcorrencias?.length">
          <treo-card class="flex flex-row items-center justify-between p-6 w-full bg-white"
            *ngFor="let o of filteredOcorrencias">
            <div class="flex flex-col">
              <div class="font-medium">{{ o.date | date: 'dd/MM/yyyy' }} - {{ occurencesTypes[o.type] || 'Outros' }}
              </div>
              <div>{{ o. description }}</div>
            </div>
            <button mat-icon-button (click)="deletarOcorrencia(o.id_animal, o.id)">
              <mat-icon class="cursor-pointer text-red-500" [svgIcon]="'delete'"></mat-icon>
            </button>
          </treo-card>
        </div>
        <div class="grid grid-cols-1 gap-4 w-full" *ngIf="!filteredOcorrencias?.length">
          <treo-card class="flex flex-row items-center justify-between p-6 w-full bg-white">
            <div class="flex flex-col">
              <div>Sem ocorrências</div>
            </div>
          </treo-card>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full">
    <button mat-raised-button class="fixed bottom-0 w-full" style="background-color: #5ba74d" (click)="dismiss()">
      Voltar
    </button>
  </div>
</ion-content>