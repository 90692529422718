import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { ConnectivityService } from './../../../connectivity.service';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntregaLeiteService {
  private dairy: BehaviorSubject<any>;

  constructor(
    private _http: HttpClient,
    private connectivityProvider: ConnectivityService
  ) {
    this.dairy = new BehaviorSubject([]);
  }

  get dairy$(): Observable<any> {
    return this.dairy.asObservable();
  }

  getDairy() {
    return this._http.get(`${environment.api}/dairy`)
        .pipe(
            catchError((error) => {
                console.log(error);
                const dairy = localStorage.getItem('dairy');
                return of(dairy ? JSON.parse(dairy) : []);
            }),
            tap((items) => {
                localStorage.setItem('dairy', JSON.stringify(items));
                this.dairy.next(items);
            })
        );
  }

  entregas(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.connectivityProvider.appIsOnline$.subscribe(online => {
        console.log(online)
        if (online) {
          this._http.post(`${environment.api}/entregas`, data)
            .subscribe(
              res => resolve(res),
              err => reject(err)
            );
        } else {
          const fila = JSON.parse(localStorage.getItem('fila') || '[]');
          // const index = fila.findIndex(e => e.route === `${environment.api}/entregas`);
          // if (index !== -1)
          //     fila.splice(index, 1);
          fila.push({
            route: `${environment.api}/entregas`,
            method: 'POST',
            data: data
          });
          localStorage.setItem('fila', JSON.stringify(fila));
          resolve(true);
        }
      });
    });
  }

  putEntregas(entrega): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put(`${environment.api}/entregas`, { ...entrega })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  entregasMes(month, year): Promise<any> {
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    return new Promise((resolve, reject) => {
      this._http.get(`${environment.api}/entregasMes/${month}/${year}/${propriedade.idPropriedade}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  findRevenueStorage(month, year): Promise<any> {
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    return new Promise((resolve, reject) => {
      this._http.post(`${environment.api}/findRevenueStorage`, { 
        id_farm: propriedade.idPropriedade,
        month,
        year
       })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  deleteEntrega(idEntrega): Promise<any> {
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    return new Promise((resolve, reject) => {
      this._http.delete(`${environment.api}/entregas/${idEntrega}/${propriedade.idPropriedade}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  revenuesUpdate(revenuesUpdate): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put(`${environment.api}/revenuesUpdate`, revenuesUpdate)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  revenueInvoice(revenueInvoice): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(`${environment.api}/revenue-invoice`, revenueInvoice)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  revenuesDell(revenuesDell): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(`${environment.api}/revenuesDell`, revenuesDell)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

}
