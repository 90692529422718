import { ConnectivityService } from './../../../connectivity.service';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PesagemLeiteService {

  constructor(
    private _httpClient: HttpClient,
    private connectivityProvider: ConnectivityService
  ) { }

  getLotsLactatingAnimals(): Promise<any> {
    return new Promise((resolve, reject) => {
      const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
      if (!propriedade.idPropriedade) {
        return resolve([]);
      }
      this._httpClient.get(`${environment.api}/lotsLactatingAnimals/${propriedade.idPropriedade}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  getAnimalsBatch(): Promise<any> {
    return new Promise((resolve, reject) => {
      const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
      if (!propriedade.idPropriedade) {
        return resolve([]);
      }
      this._httpClient.get(`${environment.api}/animalsBatch/${propriedade.idPropriedade}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  pesagensLeite(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.connectivityProvider.appIsOnline$.subscribe(online => {
        console.log(online)
        if (online) {
          this._httpClient.post(`${environment.api}/pesagensLeite`, data)
            .subscribe(
              res => resolve(res),
              err => reject(err)
            );
        } else {
          const fila = JSON.parse(localStorage.getItem('fila') || '[]');
          // const index = fila.findIndex(e => e.route === `${environment.api}/pesagensLeite`);
          // if (index !== -1)
          //     fila.splice(index, 1);
          fila.push({
            route: `${environment.api}/pesagensLeite`,
            method: 'POST',
            data: data
          });
          localStorage.setItem('fila', JSON.stringify(fila));
          resolve(true);
        }
      });
    });
  }

  lotsLactatingAnimals(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.api}/lotsLactatingAnimals`, data)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  sync(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.api}/sync/weighings`, data)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  delete(idPesagem, PropriedadeId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete(`${environment.api}/pesagens/${idPesagem}/${PropriedadeId}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  update(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.api}/pesagem`, {
        ...data
      })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  relatorio(filtro): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.api}/pesagensLeite/relatorio`, {
        ...filtro
      })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }
}