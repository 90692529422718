import { ModalController } from '@ionic/angular';
import { Component, OnInit, NgZone } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {

  title;
  data = [];
  filteredData = [];

  constructor(
    private modalController: ModalController,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.filteredData = JSON.parse(JSON.stringify(this.data));
  }

  filter(e) {
    this.ngZone.run(() => {
      this.filteredData = this.data.filter(item => item.label.toUpperCase().includes(e.toUpperCase()));
    });
  }

  dismiss(value = false) {
    this.modalController.dismiss(value);
  }

}
