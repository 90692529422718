import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ResumoDetalhadoService {
  private resumoRebanho: BehaviorSubject<any>;

  constructor(
    private _httpClient: HttpClient
  ) {
    this.resumoRebanho = new BehaviorSubject([]);
   }


   get resumoRebanho$(): Observable<any> {
    return this.resumoRebanho.asObservable();
  }

  getResumoRebanho() {    
    const dataPropriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade'));             
    
    return  this._httpClient.get(`${environment.api}/summaryHerd/${dataPropriedade.idPropriedade}`).pipe(
      catchError((error) => {
          console.log(error);                    
          const resumoRebanho: any = localStorage.getItem('Leitebem/resumoRebanho');                  
          return of(resumoRebanho ? JSON.parse(resumoRebanho) : []);
      }),
      tap((items) => {
          localStorage.setItem('Leitebem/resumoRebanho', JSON.stringify(items));
          console.log('items')
          console.log(items)
          this.resumoRebanho.next(items);
      })
    );
  }

  reportPDF(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.api}/report/pdf`, data)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }
}
