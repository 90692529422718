<ion-content class="bg-gray-200" [fullscreen]="true">
  <div class="flex flex-col flex-auto w-full bg-white border border-b-2 sticky top-0 z-50"
    [ngClass]="{'p-3': platform.is('desktop'), 'pt-8': !platform.is('desktop')}">
    <div class="flex flex-wrap w-full">
      <div class="flex items-center justify-between w-full">
        <div class="cursor-pointer p-3" (click)="backToHome()">
          <img class="logo-text w-36" src="assets/images/logo/logo-text.png">
        </div>
        <button class="mr-2" mat-icon-button (click)="backToHome()">
          <mat-icon style="font-size: 32px !important; width: 32px !important; height: 32px !important; color: #5ba74d" class="cursor-pointer hover:text-green-100" [svgIcon]="'home'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="px-4 pb-4 mb-10">
    <h3 class="mt-4" *ngIf="animal">{{ animal.identification }} {{ animal.name ? (animal.identification ? ' | ' + animal.name : animal.name) : '' }}</h3>
    <div>Informe o peso do animal e a data da pesagem.</div>
    <form class="mt-4" [formGroup]="form">
      <mat-form-field class="w-full">
        <mat-label>Data da Pesagem</mat-label>
        <div (click)="calendar('datePesagem', 'Data da Pesagem')" class="flex flex-row items-center w-full">
          <input matInput [formControlName]="'datePesagem'" [matDatepicker]="picker" [disabled]="isMobile">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label>Peso do Animal (Kg)</mat-label>
        <input matInput [formControlName]="'peso'" type="tel" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',', align: 'left', allowNegative: false, precision: 2, nullable: false}" placeholder="Peso do Animal (Kg)">
      </mat-form-field>

      <button class="submit-button treo-mat-button-large w-full" mat-flat-button [color]="'primary'"
        style="background-color: #5ba74d" [disabled]="form.disabled" (click)="save()">
        <span *ngIf="!form.disabled">
          Salvar
        </span>
        <mat-progress-spinner *ngIf="form.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
      </button>
    </form>
  </div>
  <div class="w-full">
    <button mat-raised-button class="fixed bottom-0 w-full" style="background-color: #5ba74d" (click)="dismiss()">
      Voltar
    </button>
  </div>
</ion-content>