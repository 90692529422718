import { catchError, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VideosDeAjudaService {

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient
    ) {
    }

    getModulosVideos() {
        return this._httpClient.get(`${environment.api}/helpVideos/modulos`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    return of([]);
                }),
                tap((items) => {
                    localStorage.setItem('helpVideos', JSON.stringify(items))
                    return of(items);
                })
            )
    }

    getVideos(module): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.api}/helpVideos/module/${module}`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        })
    }

}