import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoberturaService {
  private coberturas: BehaviorSubject<any>;

  constructor(
    private _httpClient: HttpClient
  ) {
    this.coberturas = new BehaviorSubject([]);
  }

  get coberturas$(): Observable<any> {
    return this.coberturas.asObservable();
  }

  clear() {
    this.coberturas.next([]);
  }

  getCoberturas() {
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    return this._httpClient.get(`${environment.api}/coberturas/${propriedade.idPropriedade}`)
      .pipe(
        catchError((error) => {
          console.log(error);
          return of(this.coberturas.next([]));
        }),
        tap((items: any) => {
          this.coberturas.next(items);
        })
      );
  }

  save(reprocess): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.api}/coberturas`, { reprocess })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }
}
