<ion-content class="bg-white" [fullscreen]="true">
  <div class="mt-6 p-4">
    <div class="flex flex-row items-center w-full p-4">
      <h1 class="m-0">{{ title }}</h1>
    </div>

    <div class="flex flex-row items-center justify-center w-full my-4">
      <mat-calendar #calendar class="max-w" [selected]="data.date" (selectedChange)="dismiss($event)"
        startView="month" [maxDate]="maxDate"></mat-calendar>
    </div>
  </div>
  <div class="w-full">
    <button mat-raised-button class="fixed bottom-0 w-full" style="background-color: #5ba74d" (click)="dismiss()">
      Voltar
    </button>
  </div>
</ion-content>