import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PropertyService {
    private propriedades: BehaviorSubject<any>;
    private selectedProperty: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient
    ) {
        this.propriedades = new BehaviorSubject([]);
        this.selectedProperty = new BehaviorSubject(null);
    }

    get propriedades$(): Observable<any> {
        return this.propriedades.asObservable();
    }

    setSelectedProperty() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade'));
        this.selectedProperty.next(propriedade);
    }

    setPropriedade(propriedade) {
        localStorage.setItem('Leitebem/dataPropriedade', JSON.stringify(propriedade));
        this.selectedProperty.next(propriedade);
    }

    get propriedade(): any {
        return JSON.parse(localStorage.getItem('Leitebem/dataPropriedade'));
    }

    get propriedade$(): Observable<any> {
        return this.selectedProperty.asObservable();
    }


    getPropriedades() {
        const user = JSON.parse(localStorage.getItem('Leitebem/dataUser'));
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade'));
        const idGroupAdmin = 3;
        const isAdmin = user?.groups && user?.groups.length && user?.groups.length > 0 && user?.groups.find(el => el.group_id === idGroupAdmin);
        let urlGetProperty = this._httpClient.post(`${environment.api}/propriedadesId`, { idProprietario: user?.id});
        if (isAdmin && isAdmin.group_id === idGroupAdmin) {
            urlGetProperty = this._httpClient.get(`${environment.api}/propriedades/${user?.id}`);
        }

        return  urlGetProperty.pipe(
                catchError((error) => {
                    console.log(error);
                    const propriedades: any = localStorage.getItem('Leitebem/propriedades');
                    if (!propriedade && propriedades.length === 1) {
                        const prop = {
                            sisategIntegration: propriedades[0].sisategIntegration,
                            cpf: propriedades[0].cpf,
                            idPropriedade: propriedades[0].id,
                            namePropriedade: propriedades[0].nomeFazenda,
                            proprietario: propriedades[0].name,
                            areaProdutiva: propriedades[0].areaProdutiva,
                            idProprietario: propriedades[0].idProprietario,
                        };
                        localStorage.setItem('Leitebem/dataPropriedade', JSON.stringify(prop));
                        this.selectedProperty.next(prop);
                        location.reload();
                    }
                    return of(propriedades ? JSON.parse(propriedades) : []);
                }),
                tap((items) => {
                    localStorage.setItem('Leitebem/propriedades', JSON.stringify(items));
                    if (!propriedade && items.length === 1) {
                        const prop = {
                            sisategIntegration: items[0].sisategIntegration,
                            cpf: items[0].cpf,
                            idPropriedade: items[0].id,
                            namePropriedade: items[0].nomeFazenda,
                            proprietario: items[0].name,
                            areaProdutiva: items[0].areaProdutiva,
                            idProprietario: items[0].idProprietario,
                        };
                        localStorage.setItem('Leitebem/dataPropriedade', JSON.stringify(prop));
                        this.selectedProperty.next(prop);
                        location.reload();
                    }
                    this.propriedades.next(items);
                })
            );
    }

}
