import { GestaoRebanhoService } from './gestao-rebanho.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GestaoRebanhoResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {GestaoRebanhoService} _gestaoRebanhoService
     */
    constructor(
        private _gestaoRebanhoService: GestaoRebanhoService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._gestaoRebanhoService.getAnimaisFazenda();
    }
}

@Injectable({
    providedIn: 'root'
})
export class BreedsResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {GestaoRebanhoService} _gestaoRebanhoService
     */
    constructor(
        private _gestaoRebanhoService: GestaoRebanhoService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._gestaoRebanhoService.getBreeds();
    }
}

@Injectable({
    providedIn: 'root'
})
export class RbConfigResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {GestaoRebanhoService} _gestaoRebanhoService
     */
    constructor(
        private _gestaoRebanhoService: GestaoRebanhoService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._gestaoRebanhoService.getRbConfig();
    }
}
