import { ConnectivityService } from './../../connectivity.service';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnimaisService {

  constructor(
    private _http: HttpClient,
    private connectivityProvider: ConnectivityService
  ) { }

  create(formData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.connectivityProvider.appIsOnline$.subscribe(online => {
        console.log(online)
        if (online) {
          this._http.post(`${environment.api}/animals`, formData)
            .subscribe(
              res => resolve(res),
              err => reject(err)
            );
        } else {
          const fila = JSON.parse(localStorage.getItem('fila') || '[]');
          // const index = fila.findIndex(e => e.route === `${environment.api}/animals`);
          // if (index !== -1)
          //     fila.splice(index, 1);
          const object = {};
          formData.forEach((value, key) => object[key] = value);
          fila.push({
            route: `${environment.api}/animals`,
            method: 'POST',
            data: object
          });
          localStorage.setItem('fila', JSON.stringify(fila));
          resolve(true);
        }
      });
    });
  }

  update(formData): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put(`${environment.api}/animal`, formData)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
    /*     return new Promise((resolve, reject) => {
          this.connectivityProvider.appIsOnline$.subscribe(online => {
            console.log(online)
            if (online) {
              this._http.put(`${environment.api}/animal`, formData)
                .subscribe(
                  res => resolve(res),
                  err => reject(err)
                );
            } else {
              const fila = JSON.parse(localStorage.getItem('fila') || '[]');
              // const index = fila.findIndex(e => e.route === `${environment.api}/animals`);
              // if (index !== -1)
              //     fila.splice(index, 1);
              const object = {};
              formData.forEach((value, key) => object[key] = value);
              fila.push({
                route: `${environment.api}/animals`,
                method: 'POST',
                data: object
              });
              localStorage.setItem('fila', JSON.stringify(fila));
              resolve(true);
            }
          });
        }); */
  }

  estagiosReproducao(id): Promise<any> {
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    return new Promise((resolve, reject) => {
      this._http.delete(`${environment.api}/estagiosReproducao/${id}/${propriedade.idPropriedade}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  estagiosReproducaoAdd(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(`${environment.api}/estagiosReproducaoAdd`, data)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  animalsSold(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(`${environment.api}/animalsSold`, data)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  animalsDead(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(`${environment.api}/animalsDead`, data)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  animalsOccurences(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(`${environment.api}/animalsOccurences`, data)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  animalDesativar(id): Promise<any> {
    console.log(id)
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    return new Promise((resolve, reject) => {
      this._http.put(`${environment.api}/animalDesativar`, { idAnimal: id, idPropriedade: propriedade.idPropriedade })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  getAnimal(id): Promise<any> {
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    return new Promise((resolve, reject) => {
      this._http.get(`${environment.api}/animal/${propriedade.idPropriedade}/${id}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }
}
