import { takeUntil, filter } from 'rxjs/operators';
import { CoberturaService } from './cobertura.service';
import { Platform, ModalController, LoadingController, AlertController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { GestaoRebanhoService } from '../modals/gestao-rebanho/gestao-rebanho.service';
import { SelectComponent } from '../modals/components/select/select.component';
import { closeAllModals } from "app/app.service";

@Component({
  selector: 'app-cobertura',
  templateUrl: './cobertura.component.html',
  styleUrls: ['./cobertura.component.scss'],
})
export class CoberturaComponent implements OnInit {
  animals = [];
  maleAnimals = [];
  femaleAnimals = [];
  coberturas = [];
  filteredCoberturas = [];
  originalCoberturas = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  mon = moment;

  constructor(
    public platform: Platform,
    private modalController: ModalController,
    private _gestaoRebanhoService: GestaoRebanhoService,
    private _coberturaService: CoberturaService,
    private loadingController: LoadingController,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    this._gestaoRebanhoService.animaisFazenda$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(animals => {
        console.log(animals)
        this.animals = animals;
        this.maleAnimals = animals.filter(a => a.sex === 'M');
        this.femaleAnimals = animals.filter(a => a.sex === 'F');
      })
    this.load();
  }

  async load() {
    const loading = await this.loadingController.create({
      message: 'Por Favor Aguarde',
    });
    await loading.present();
    this._coberturaService.getCoberturas()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((coberturas: any) => {
        this.coberturas = this.filteredCoberturas = coberturas;
        this.originalCoberturas = JSON.parse(JSON.stringify(coberturas));
        loading.dismiss();
      }, err => {
        console.log(err);
        loading.dismiss();
      });
  }

  filter(text) {
    this.filteredCoberturas = this.coberturas.filter(c => c.identification.toUpperCase().includes(text.toUpperCase()) || c.name.toUpperCase().includes(text.toUpperCase()))
  }

  async save() {
    const loading = await this.loadingController.create({
      message: 'Por Favor Aguarde',
    });
    await loading.present();
    const process = [];
    for (const [i, c] of this.coberturas.entries()) {
      if (this.originalCoberturas[i].idBull !== c.idBull) {
        process.push(c)
      }
    }
    this._coberturaService.save(process).then(async () => {
      loading.dismiss();
      const alert = await this.alertController.create({
        header: 'Feito!',
        message: `Coberturas salvas com sucesso.`,
        buttons: ['OK']
      });
      await alert.present();
      const { data } = await alert.onWillDismiss();
      alert.dismiss();
      this.load();
    })
      .catch(async () => {
        loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Ops!',
          message: `Erro ao salvar coberturas. Tente novamente mais tarde.`,
          buttons: ['OK']
        });
        await alert.present();
      })
  }

  dismiss() {
    this.modalController.dismiss();
  }

  backToHome() {
    closeAllModals();
  }

}
