import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GestaoRebanhoService } from './../gestao-rebanho.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, Platform, AlertController, LoadingController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { closeAllModals } from "app/app.service";

@Component({
  selector: 'app-resumo-bn-config',
  templateUrl: './resumo-bn-config.component.html',
  styleUrls: ['./resumo-bn-config.component.scss'],
})
export class ResumoBnConfigComponent implements OnInit {
  form: FormGroup;
  rbConfig;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _formBuilder: FormBuilder,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private _gestaoRebanhoService: GestaoRebanhoService,
    public platform: Platform
  ) { }

  ngOnInit() {
    this.form = this._formBuilder.group({
      rb_peso_nascimento: [40, [Validators.required]],
      rb_peso_ideal: [300, [Validators.required]],
      rb_qtd_dias: [180, [Validators.required]],
    });
    this._gestaoRebanhoService.rbConfig$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(rbConfig => {
        if (rbConfig) {
          this.form.patchValue({
            ...rbConfig
          })
        }
      });
  }

  async salvar() {
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    if (!propriedade.idPropriedade) {
      return;
    }
    const loading = await this.loadingController.create({
      message: 'Por Favor Aguarde',
    });
    await loading.present();
    this._gestaoRebanhoService.editarResumoBezerrasConfig({
      propriedade_id: propriedade.idPropriedade,
      rb_peso_nascimento: this.form.value.rb_peso_nascimento,
      rb_peso_ideal: this.form.value.rb_peso_ideal,
      rb_qtd_dias: this.form.value.rb_qtd_dias,
    }).then(async () => {
      await this._gestaoRebanhoService.getRbConfig().toPromise();
      const alert = await this.alertController.create({
        header: 'Feito!',
        message: `Configurações salvas com sucesso!`,
        buttons: ['OK']
      });
      loading.dismiss();
      await alert.present();
      const { role } = await alert.onDidDismiss();
      this.modalController.dismiss();
    }).catch(async err => {
      console.log(err);
      const alert = await this.alertController.create({
        header: 'Ops...',
        message: `Erro ao savar configurações. Tente novamente mais tarde.`,
        buttons: ['OK']
      });
      loading.dismiss();
      await alert.present();
    })
  }

  dismiss() {
    this.modalController.dismiss();
  }

  backToHome() {
    closeAllModals();
  }

}
