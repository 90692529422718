<ion-content class="bg-gray-200" [fullscreen]="true">
  <div class="flex flex-col flex-auto w-full bg-white border border-b-2 sticky top-0 z-50"
    [ngClass]="{'p-3': platform.is('desktop'), 'pt-8': !platform.is('desktop')}">
    <div class="flex flex-wrap w-full">
      <div class="flex items-center justify-between w-full">
        <div class="cursor-pointer p-3" (click)="backToHome()">
          <img class="logo-text w-36" src="assets/images/logo/logo-text.png">
        </div>
        <button class="mr-2" mat-icon-button (click)="backToHome()">
          <mat-icon style="font-size: 32px !important; width: 32px !important; height: 32px !important; color: #5ba74d" class="cursor-pointer hover:text-green-100" [svgIcon]="'home'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="flex flex-col bg-white">
    <div class="text-black px-4 pb-4">
      <div class="flex flex-row items-center justify-between w-full">
        <h1>Gestão de rebanho</h1>
        <button mat-button class="text-gray-700 ml-3 mt-1" (click)="openHelpVideos()" *ngIf="showHelp">
          <mat-icon svgIcon="help"></mat-icon> Ajuda
        </button>
      </div>

      <mat-accordion>
        <mat-expansion-panel>

          <mat-expansion-panel-header class="my-1">
            <mat-panel-title>
              <mat-icon [svgIcon]="'filter_alt'"></mat-icon>
              Filtros
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-form-field class="w-full">
            <mat-label>Estágios</mat-label>
            <div (click)="select('Estágio')" class="w-full">
              <mat-select #singleSelect [(ngModel)]="stage" required disabled>
                <mat-option *ngFor="let stage of stages" [value]="stage.value">
                  {{ stage.label }}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>

          <mat-form-field class="w-full">
            <mat-label>Ordenar por</mat-label>
            <div (click)="select2('Ordenar por', 'ordenarPor')" class="w-full">
              <mat-select #singleSelect [(ngModel)]="filters.ordenarPor" required disabled>
                <mat-option *ngFor="let por of ordenarPor" [value]="por.value">
                  {{ por.label }}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>

          <mat-form-field class="w-full" *ngIf="stage === 1">
            <mat-label>De</mat-label>
            <div (click)="select2('De', 'de')" class="w-full">
              <mat-select #singleSelect [(ngModel)]="filters.de" required disabled>
                <mat-option *ngFor="let m of meses" [value]="m.value">
                  {{ m.label }}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>

          <mat-form-field class="w-full" *ngIf="stage === 1">
            <mat-label>Até</mat-label>
            <div (click)="select2('Até', 'ate')" class="w-full">
              <mat-select #singleSelect [(ngModel)]="filters.ate" required disabled>
                <mat-option *ngFor="let m of meses" [value]="m.value">
                  {{ m.label }}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>

          <div class="flex flex-col w-full mb-4">
            <div class="font-semibold"></div>
            <mat-radio-group [(ngModel)]="filters.order" (change)="filter2()">
              <mat-radio-button value="asc">Crescente</mat-radio-button>
              <mat-radio-button value="desc" class="ml-4">Decrescente</mat-radio-button>
            </mat-radio-group>
          </div>

        </mat-expansion-panel>
      </mat-accordion>

      <div class="grid lg:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-2">
        <div class="flex flex-col w-full">
          <button class="w-full hover:bg-green-100 mt-4" mat-stroked-button style="color: #5ba74d" (click)="novo()">
            Adicionar animal
          </button>
        </div>

        <div class="flex flex-col w-full">
          <button class="w-full hover:bg-green-100 mt-4" mat-stroked-button style="color: #5ba74d"
            (click)="resumoDetalhado()">
            Resumo detalhado
          </button>
        </div>

        <div class="flex flex-col w-full">
          <button class="w-full hover:bg-green-100 mt-4" mat-stroked-button style="color: #5ba74d"
            (click)="cobertura()">
            Cobertura
          </button>
        </div>
      </div>

      <mat-form-field class="treo-mat-dense treo-mat-no-subscript w-full mt-4">
        <input class="text-black" matInput [autocomplete]="'off'" [(ngModel)]="search" [placeholder]="'Buscar'"
          (keyup)="filter()">
      </mat-form-field>
    </div>
  </div>
  <div class="mt-2 p-4 mb-10">
    <div class="grid grid-cols-1 gap-4">
      <treo-card class="flex flex-row justify-between p-6 w-full bg-white" *ngFor="let card of filteredArray">
        <div class="flex flex-col">
          <div class="font-bold cursor-pointer" (click)="set(card.id)">{{ card.identification + ( card.name ? ' - ' +
            card.name : '' )
            }}</div>
          <div>{{ card.nameStages }}</div>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item class="bg-black text-white" (click)="registrarPesagem(card)"
            *ngIf="correctStage(card.idCurrentStages, card.dateBirth) === 'Bezerra' || correctStage(card.idCurrentStages, card.dateBirth) === 'Novilha'">Registrar
            pesagem</button>
          <button mat-menu-item class="bg-black text-white" (click)="open(card)"
            *ngIf="correctStage(card.idCurrentStages, card.dateBirth) !== 'Bezerra'">Registrar evento</button>
          <button mat-menu-item class="bg-black text-white" (click)="desfazer(card)"
            *ngIf="correctStage(card.idCurrentStages, card.dateBirth) !== 'Bezerra'">Desfazer evento</button>
        </mat-menu>
        <button mat-icon-button>
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon svgIcon="visibility" style="color: #5ba74d" ></mat-icon>
          </button>
        </button>
      </treo-card>
      <div *ngIf="!filteredArray.length">Sem animais</div>
    </div>
  </div>

  <div class="w-full">
    <button mat-raised-button class="fixed bottom-0 w-full" style="background-color: #5ba74d" (click)="dismiss()">
      Voltar
    </button>
  </div>
</ion-content>