import { EmployeesService } from './employees.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EmployeesResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {EmployeesService} _employeesService
     */
    constructor(
        private _employeesService: EmployeesService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._employeesService.getEmployees();
    }
}
