import { HomeService } from './home.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DashboardControlStageResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getDashboardControlStage();
    }
}

@Injectable({
    providedIn: 'root'
})
export class DashboardDryingResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getDashboardDrying();
    }
}

@Injectable({
    providedIn: 'root'
})
export class DashboardPregnantResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getDashboardPregnant();
    }
}

@Injectable({
    providedIn: 'root'
})
export class DashboardDelayedResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getDashboardDelayed();
    }
}

@Injectable({
    providedIn: 'root'
})
export class CoveredAnimalsResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getCoveredAnimals();
    }
}

@Injectable({
    providedIn: 'root'
})
export class DashboardWeightsResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getDashboardWeights();
    }
}

@Injectable({
    providedIn: 'root'
})
export class DashboardWeighingsAverageResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getDashboardWeighingsAverage();
    }
}

@Injectable({
    providedIn: 'root'
})
export class DashboardRecipeResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getDashboardRecipe();
    }
}

@Injectable({
    providedIn: 'root'
})
export class EntregasMesResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getEntregasMes();
    }
}

@Injectable({
    providedIn: 'root'
})
export class DashboardLastDeliveriesResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getDashboardLastDeliveries();
    }
}

@Injectable({
    providedIn: 'root'
})
export class TotalEntregasResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getTotalEntregas();
    }
}

@Injectable({
    providedIn: 'root'
})
export class TotalEntregasFaturadasResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getTotalEntregasFaturadas();
    }
}

@Injectable({
    providedIn: 'root'
})
export class DetailsAnimalByPeriodResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getDetailsAnimalByPeriod(localStorage.getItem('selectedDate') ? moment(localStorage.getItem('selectedDate')).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), 'M');
    }
}

@Injectable({
    providedIn: 'root'
})
export class AnimaisLactantesResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getAnimaisLactantes();
    }
}

@Injectable({
    providedIn: 'root'
})
export class HelpersFarmResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getHelpersFarm();
    }
}

@Injectable({
    providedIn: 'root'
})
export class PesagensLeiteIdPropriedadeResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getPesagensLeiteIdPropriedade();
    }
}

@Injectable({
    providedIn: 'root'
})
export class AnimalsDeadResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getAnimalsDead();
    }
}

@Injectable({
    providedIn: 'root'
})
export class AnimalsSoldResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getAnimalsSold();
    }
}

@Injectable({
    providedIn: 'root'
})
export class QualidadeLeiteResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getQualidadeLeite();
    }
}

@Injectable({
    providedIn: 'root'
})
export class AnimaisCobertosNaoCobertosResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getAnimaisCobertosNaoCobertos();
    }
}
