import { ConnectivityService } from './../../connectivity.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EmployeesService {
    private employees: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private connectivityProvider: ConnectivityService
    ) {
        this.employees = new BehaviorSubject([]);
    }

    get employees$(): Observable<any> {
        return this.employees.asObservable();
    }

    getEmployees() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/funcionarios/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const employees = localStorage.getItem('employees');
                    return of(employees ? JSON.parse(employees) : []);
                }),
                tap((items) => {
                    localStorage.setItem('employees', JSON.stringify(items));
                    this.employees.next(items);
                })
            );
    }


    save(employee): Promise<any> {
        return new Promise((resolve, reject) => {
            this.connectivityProvider.appIsOnline$.subscribe(online => {
                console.log(online)
                if (online) {
                    this._httpClient.post(`${environment.api}/funcionarios`, employee)
                        .subscribe(
                            res => resolve(res),
                            err => reject(err)
                        );
                } else {
                    const fila = JSON.parse(localStorage.getItem('fila') || '[]');
                    const index = fila.findIndex(e => e.route === `${environment.api}/funcionarios` && e.data.cpf.replace(/\D[^\.]/g, "") === employee.cpf);
                    if (index !== -1)
                        fila.splice(index, 1);
                    fila.push({
                        route: `${environment.api}/funcionarios`,
                        method: 'POST',
                        data: employee
                    });
                    localStorage.setItem('fila', JSON.stringify(fila));
                    const employees = JSON.parse(localStorage.getItem('employees') || '[]');
                    const employeeindex = employees.findIndex(e => e.cpf.replace(/\D[^\.]/g, "") === employee.cpf);
                    if (employeeindex !== -1) {
                        employees.splice(index, 1);
                        employees[employeeindex] = employee;
                    } else {
                        employees.push(employee);
                    }
                    localStorage.setItem('employees', JSON.stringify(employees));
                    resolve(true);
                }
            });
        });
    }

    edit(employee): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${environment.api}/funcionarios`, employee)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    delete(id, PropriedadeId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(`${environment.api}/helpersFarm/${id}/${PropriedadeId}`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }
}