import { HomeService } from './../../landing/home/home.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PesagensTotalResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {HomeService} _homeService
     */
    constructor(
        private _homeService: HomeService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return this._homeService.getPesagensTotal();
    }
}
