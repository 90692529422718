import { CurrencyPipe } from "@angular/common";

export const maskMoney = (value) => {
  return value;
  // const c = new CurrencyPipe('pt-BR');
  // if (!value) { return c.transform(0, 'R$'); }
  // const v = checkDot(value);
  // return c.transform(v, 'R$');
};

export const money = (value) => {
  const c = new CurrencyPipe('pt-BR');
  if (!value) { return c.transform(0, 'R$'); }
  const v = checkDot(value);
  return c.transform(v, 'R$');
};

export const checkDot = (valor) => {
  const v = valor.toString().split('.');
  if (v.length > 2) {
    const value = valor.toString().split('.').join('');
    return parseFloat(value) / 100;
  } else {
    return parseFloat(valor);
  }
}

export const maskPhone = (value) => {
  return value ? (value.length === 10 ? value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1)$2-$3") : value.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, "($1)$2$3-$4")) : '';
}

export const validateCpf = (cpf) => {
  if (!cpf) return false;

  cpf = cpf.replace(/\D/g, '');

  if (
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;

  let accumulator = 0;
  let rest;

  for (let i = 1; i <= 9; i += 1) {
    accumulator += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (accumulator * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(9, 10), 10)) return false;

  accumulator = 0;

  for (let i = 1; i <= 10; i += 1) {
    accumulator += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (accumulator * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(10, 11), 10)) return false;

  return true;
};

export const optionsLabelValue = (options, label, value) => {
  return options.map(o => {
    return {
      label: o[label],
      value: o[value]
    }
  });
}

export const formatarValor = (valor) => {
  if (isNaN(valor))
    return '0,000';
  if (valor >= 1000)
    return Number(valor).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 });
  return Number(valor).toFixed(3).replace('.', ',');
}
