<ion-content class="bg-gray-200" [fullscreen]="true">
  <div class="flex flex-col flex-auto w-full bg-white border border-b-2 sticky top-0 z-50"
    [ngClass]="{'p-3': platform.is('desktop'), 'pt-8': !platform.is('desktop')}">
    <div class="flex flex-wrap w-full">
      <div class="flex items-center justify-between w-full">
        <div class="cursor-pointer p-3" (click)="backToHome()">
          <img class="logo-text w-36" src="assets/images/logo/logo-text.png">
        </div>
        <button class="mr-2" mat-icon-button (click)="backToHome()">
          <mat-icon style="font-size: 32px !important; width: 32px !important; height: 32px !important; color: #5ba74d"
            class="cursor-pointer hover:text-green-100" [svgIcon]="'home'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="px-4 pb-4 mb-10">
    <h1>Coberturas</h1>
    <div class="w-full mb-4">
      <mat-form-field class="treo-mat-dense treo-mat-no-subscript w-full mt-4">
        <input class="text-black" matInput [autocomplete]="'off'" [placeholder]="'Buscar'"
          (keyup)="filter($event.target.value)">
      </mat-form-field>
    </div>
    <div class="grid grid-cols-1 gap-4 w-full">
      <treo-card class="flex flex-col bg-white p-6 w-full" *ngFor="let c of filteredCoberturas">
        <div class="font-semibold">{{ c.identification }} {{ c.name ? (c.identification ? ' - ' + c.name : c.name) : '' }}</div>
        <div class="flex flex-row items-center">
          <div>Registro:&nbsp;</div>
          <div>{{ c.register }}</div>
        </div>
        <div class="flex flex-row items-center">
          <div>Data da cobertura:&nbsp;</div>
          <div>{{ mon(c.dateRegister.split("T")[0]) | date:'dd/MM/yyyy' }}</div>
        </div>
        <div class="flex flex-row items-center">
          <div class="mr-4">Boi:&nbsp;</div>
          <mat-select #singleSelect [(ngModel)]="c.idBull" required>
            <mat-option *ngFor="let a of maleAnimals" [value]="a.id">
              {{ a.identification }} {{ a.name ? (a.identification ? ' - ' + a.name : a.name) : '' }}
            </mat-option>
          </mat-select>
          <!-- <div (click)="select('animal', 'animals', 'Animais')" class="w-full">
            <mat-select #singleSelect [(ngModel)]="c.idBull" disabled>
              <mat-option *ngFor="let a of animals" [value]="a.value">
                {{ a.label }}
              </mat-option>
            </mat-select>
          </div> -->
        </div>
      </treo-card>
      <button class="submit-button treo-mat-button-large w-full mb-12" mat-flat-button [color]="'primary'"
        style="background-color: #5ba74d" (click)="save()">
        Salvar
      </button>
    </div>
  </div>

  <div class="w-full">
    <button mat-raised-button class="fixed bottom-0 w-full" style="background-color: #5ba74d" (click)="dismiss()">
      Voltar
    </button>
  </div>
</ion-content>