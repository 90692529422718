import { ConnectivityService } from './../../connectivity.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private user: BehaviorSubject<any>;
    private estados: BehaviorSubject<any>;
    private termo: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private connectivityProvider: ConnectivityService,
    ) {
        this.user = new BehaviorSubject(null);
        this.estados = new BehaviorSubject([]);
        this.termo = new BehaviorSubject([]);
    }

    get user$(): Observable<any> {
        return this.user.asObservable();
    }

    get estados$(): Observable<any> {
        return this.estados.asObservable();
    }

    get termo$(): Observable<any> {
        return this.termo.asObservable();
    }

    getUser(id) {
        return this._httpClient.post(`${environment.api}/locateIdUser`, { id })
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const user = localStorage.getItem('user');
                    return of(user ? JSON.parse(user) : null);
                }),
                tap((items) => {
                    localStorage.setItem('user', JSON.stringify(items));
                    this.user.next(items);
                })
            );
    }

    getEstados() {
        return this._httpClient.get(`${environment.api}/estados`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const estados = localStorage.getItem('estados');
                    return of(estados ? JSON.parse(estados) : []);
                }),
                tap((items) => {
                    localStorage.setItem('estados', JSON.stringify(items));
                    this.estados.next(items);
                })
            );
    }

    getTerm() {
        return this._httpClient.get(`${environment.api}/term/1`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const termo = localStorage.getItem('termo');
                    return of(termo ? JSON.parse(termo) : null);
                }),
                tap((items) => {
                    localStorage.setItem('termo', JSON.stringify(items));
                    this.termo.next(items);
                })
            );
    }

    getCidades(estadoId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.api}/cidadesId`, { estadoId })
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    save(user): Promise<any> {
        return new Promise((resolve, reject) => {
            this.connectivityProvider.appIsOnline$.subscribe(online => {
                console.log(online)
                if (online) {
                    this._httpClient.put(`${environment.api}/user-upDate`, user)
                        .subscribe(
                            res => resolve(res),
                            err => reject(err)
                        );
                } else {
                    const fila = JSON.parse(localStorage.getItem('fila') || '[]');
                    const index = fila.findIndex(e => e.route === `${environment.api}/user-upDate`);
                    if (index !== -1)
                        fila.splice(index, 1);
                    fila.push({
                        route: `${environment.api}/user-upDate`,
                        method: 'PUT',
                        data: user
                    });
                    localStorage.setItem('fila', JSON.stringify(fila));
                    resolve(true);
                }
            });
        });
    }

    alterarSenha(user): Promise<any> {
        return new Promise((resolve, reject) => {
            this.connectivityProvider.appIsOnline$.subscribe(online => {
                console.log(online)
                if (online) {
                    this._httpClient.put(`${environment.apiOauth}/users`, user)
                        .subscribe(
                            res => resolve(res),
                            err => reject(err)
                        );
                } else {

                    const fila = JSON.parse(localStorage.getItem('fila') || '[]');
                    const index = fila.findIndex(e => e.route === `${environment.apiOauth}/users`);
                    if (index !== -1)
                        fila.splice(index, 1);
                    fila.push({
                        route: `${environment.apiOauth}/users`,
                        method: 'PUT',
                        data: user
                    });
                    localStorage.setItem('fila', JSON.stringify(fila));
                    resolve(true);
                }
            });
        });
    }

    desativarConta(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.api}/desativarConta`, {
                user_id: user?.id
            })
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }
}