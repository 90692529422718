<ion-content class="bg-gray-200" [fullscreen]="true">
  <div class="pt-6 bg-white w-full min-h-full mb-10">
    <div class="p-4">
      <div class="flex flex-row items-center w-full">
        <h1 class="text-black m-0">{{ title }}</h1>
      </div>
  
      <div class="flex flex-row w-full my-4">
  
        <mat-form-field class="treo-mat-dense treo-mat-no-subscript w-full">
          <input class="text-black" matInput [autocomplete]="'off'" [placeholder]="'Buscar'" (keyup)="filter($event.target.value)">
        </mat-form-field>
  
        <!-- Search button -->
        <button style="min-height: 41px;" class="search-button -ml-16 mr-2" mat-flat-button [color]="'primary'">
          <mat-icon [svgIcon]="'search'"></mat-icon>
        </button>
  
      </div>
    </div>
    <div class="flex flex-row justify-between p-6 w-full border-t-2 last:border-b-2 cursor-pointer hover:bg-green-100" *ngFor="let d of filteredData" (click)="dismiss(d.value)">
      <div>{{ d.label }}</div>
    </div>
    <div class="flex flex-row items-center justify-center p-6" *ngIf="!filteredData || !filteredData.length">Sem dados</div>
  </div>
  <div class="w-full">
    <button mat-raised-button class="fixed bottom-0 w-full" style="background-color: #5ba74d" (click)="dismiss()">
      Voltar
    </button>
  </div>
</ion-content>