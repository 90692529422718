import { PropertyService } from './property.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PropriedadesResolvers implements Resolve<any>
{
    /**
     * Constructor
     *
     * @param {PropertyService} _propertyService
     */
    constructor(
        private _propertyService: PropertyService
    )
    {}
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        this._propertyService.setSelectedProperty();
        return this._propertyService.getPropriedades();
    }
}
