<div class="flex flex-col flex-auto bg-white shadow-md rounded overflow-hidden">
  <div class="flex flex-row items-center justify-between py-6 px-3" style="background-color: #5ba74d">
    <div class="flex flex-col items-center justify-center">
      <div class="font-bold text-md text-white uppercase tracking-wider">GMD - Histórico de Crescimento</div>
    </div>
    <!-- <button mat-stroked-button class="mr-4" style="color: #fff">
      Nova Pesagem
    </button> -->
    <button mat-icon-button [matTooltip]="'Nova Pesagem'" (click)="editarPesagem()" style="color: #fff">
      <mat-icon [svgIcon]="'add'"></mat-icon>
    </button>
  </div>
  <div class="overflow-auto">
    <table class="w-full bg-transparent" mat-table matSort [dataSource]="historicoCrescimentoDataSource"
      [trackBy]="trackByFn" #historicoCrescimentoTable>

      <ng-container matColumnDef="n">
        <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t pr-2" mat-header-cell mat-sort-header
          *matHeaderCellDef>
          <span class="whitespace-no-wrap">
            Nº
          </span>
        </th>
        <td mat-cell *matCellDef="let historicoCrescimento">
          <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
            {{ historicoCrescimento.n }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="data">
        <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t pr-2" mat-header-cell mat-sort-header
          *matHeaderCellDef>
          <span class="whitespace-no-wrap">
            Data da pesagem
          </span>
        </th>
        <td mat-cell *matCellDef="let historicoCrescimento">
          <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
            {{ historicoCrescimento.data  }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="dias">
        <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t pr-2" mat-header-cell mat-sort-header
          *matHeaderCellDef>
          <span class="whitespace-no-wrap">
            Dias
          </span>
        </th>
        <td mat-cell *matCellDef="let historicoCrescimento">
          <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
            {{ historicoCrescimento.days }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="acumulado">
        <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t pr-2" mat-header-cell mat-sort-header
          *matHeaderCellDef>
          <span class="whitespace-no-wrap">
            GMD
          </span>
        </th>
        <td mat-cell *matCellDef="let historicoCrescimento">
          <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
            {{ historicoCrescimento.total_acumulado }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="pesagem">
        <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t pr-2" mat-header-cell mat-sort-header
          *matHeaderCellDef>
          <span class="whitespace-no-wrap">
            Pesagem
          </span>
        </th>
        <td mat-cell *matCellDef="let historicoCrescimento">
          <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
            {{ historicoCrescimento.weight }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="acoes">
        <th class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t pr-2" mat-header-cell mat-sort-header
          *matHeaderCellDef>
          <span class="whitespace-no-wrap">
            
          </span>
        </th>
        <td mat-cell *matCellDef="let historicoCrescimento">
          <span class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap">
            <button mat-icon-button>
              <mat-icon class="cursor-pointer text-green-500 hover:text-green-100" [svgIcon]="'edit'" (click)="editarPesagem(historicoCrescimento)"></mat-icon>
            </button>
            <button mat-icon-button>
              <mat-icon class="cursor-pointer text-red-500 hover:text-red-100" [svgIcon]="'delete'" (click)="deletarPesagem(historicoCrescimento.id)"></mat-icon>
            </button>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="historicoCrescimentoTableColumns"></tr>
      <tr class="order-row h-8" mat-row *matRowDef="let row; columns: historicoCrescimentoTableColumns;"></tr>
    </table>
  </div>
</div>