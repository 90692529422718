import { PesagemLeiteService } from './../../home/pesagem-leite/pesagem-leite.service';
import { DatabaseProvider } from './../../../../../database';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import * as moment from 'moment';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    private dashboardControlStage: BehaviorSubject<any>;
    private dashboardDrying: BehaviorSubject<any>;
    private dashboardPregnant: BehaviorSubject<any>;
    private dashboardDelayed: BehaviorSubject<any>;
    private coveredAnimals: BehaviorSubject<any>;
    private dashboardWeights: BehaviorSubject<any>;
    private dashboardWeighingsAverage: BehaviorSubject<any>;
    private dashboardRecipe: BehaviorSubject<any>;
    private entregasMes: BehaviorSubject<any>;
    private dashboardLastDeliveries: BehaviorSubject<any>;
    private totalEntregas: BehaviorSubject<any>;
    private totalEntregasFaturadas: BehaviorSubject<any>;
    private pesagensTotal: BehaviorSubject<any>;
    private detailsAnimalByPeriod: BehaviorSubject<any>;
    private detailsAnimalByPeriodAllDay: BehaviorSubject<any>;
    private detailsAnimalByPeriodAllDayPeriod: BehaviorSubject<any>;
    private animaisLactantes: BehaviorSubject<any>;
    private helpersFarm: BehaviorSubject<any>;
    private herdCalvesAndHeifers: BehaviorSubject<any>;
    private herdHeifers: BehaviorSubject<any>;
    private herdLacting: BehaviorSubject<any>;
    private herdDry: BehaviorSubject<any>;
    private herdMale10: BehaviorSubject<any>;
    private herdMale11: BehaviorSubject<any>;
    private herdMale12: BehaviorSubject<any>;
    private herdMale13: BehaviorSubject<any>;
    private pesagensLeiteIdPropriedade: BehaviorSubject<any>;
    private animalsDead: BehaviorSubject<any>;
    private animalsSold: BehaviorSubject<any>;
    private qualidadeLeite: BehaviorSubject<any>;
    private animaisCobertosNaoCobertos: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _databaseProvider: DatabaseProvider,
        private _pesagemLeiteService: PesagemLeiteService,
        private _dbProvider: DatabaseProvider,
        private platform: Platform
    ) {
        this.dashboardControlStage = new BehaviorSubject([]);
        this.dashboardDrying = new BehaviorSubject([]);
        this.dashboardPregnant = new BehaviorSubject([]);
        this.dashboardDelayed = new BehaviorSubject([]);
        this.coveredAnimals = new BehaviorSubject([]);
        this.dashboardWeights = new BehaviorSubject([]);
        this.dashboardWeighingsAverage = new BehaviorSubject([]);
        this.dashboardRecipe = new BehaviorSubject([]);
        this.entregasMes = new BehaviorSubject([]);
        this.dashboardLastDeliveries = new BehaviorSubject([]);
        this.totalEntregas = new BehaviorSubject([]);
        this.totalEntregasFaturadas = new BehaviorSubject([]);
        this.pesagensTotal = new BehaviorSubject([]);
        this.detailsAnimalByPeriod = new BehaviorSubject([]);
        this.detailsAnimalByPeriodAllDay = new BehaviorSubject([]);
        this.detailsAnimalByPeriodAllDayPeriod = new BehaviorSubject([]);
        this.animaisLactantes = new BehaviorSubject([]);
        this.herdCalvesAndHeifers = new BehaviorSubject([]);
        this.herdHeifers = new BehaviorSubject([]);
        this.herdLacting = new BehaviorSubject([]);
        this.herdDry = new BehaviorSubject([]);
        this.herdMale10 = new BehaviorSubject([]);
        this.herdMale11 = new BehaviorSubject([]);
        this.herdMale12 = new BehaviorSubject([]);
        this.herdMale13 = new BehaviorSubject([]);
        this.helpersFarm = new BehaviorSubject([]);
        this.pesagensLeiteIdPropriedade = new BehaviorSubject([]);
        this.animalsDead = new BehaviorSubject([]);
        this.animalsSold = new BehaviorSubject([]);
        this.qualidadeLeite = new BehaviorSubject([]);
        this.animaisCobertosNaoCobertos = new BehaviorSubject([]);
    }

    get dashboardControlStage$(): Observable<any> {
        return this.dashboardControlStage.asObservable();
    }

    get dashboardDrying$(): Observable<any> {
        return this.dashboardDrying.asObservable();
    }

    get dashboardPregnant$(): Observable<any> {
        return this.dashboardPregnant.asObservable();
    }

    get dashboardDelayed$(): Observable<any> {
        return this.dashboardDelayed.asObservable();
    }

    get coveredAnimals$(): Observable<any> {
        return this.coveredAnimals.asObservable();
    }

    get dashboardWeights$(): Observable<any> {
        return this.dashboardWeights.asObservable();
    }

    get dashboardWeighingsAverage$(): Observable<any> {
        return this.dashboardWeighingsAverage.asObservable();
    }

    get dashboardRecipe$(): Observable<any> {
        return this.dashboardRecipe.asObservable();
    }

    get entregasMes$(): Observable<any> {
        return this.entregasMes.asObservable();
    }

    get dashboardLastDeliveries$(): Observable<any> {
        return this.dashboardLastDeliveries.asObservable();
    }

    get totalEntregas$(): Observable<any> {
        return this.totalEntregas.asObservable();
    }

    get totalEntregasFaturadas$(): Observable<any> {
        return this.totalEntregasFaturadas.asObservable();
    }

    get pesagensTotal$(): Observable<any> {
        return this.pesagensTotal.asObservable();
    }

    get detailsAnimalByPeriod$(): Observable<any> {
        return this.detailsAnimalByPeriod.asObservable();
    }

    get detailsAnimalByPeriodAllDay$(): Observable<any> {
        return this.detailsAnimalByPeriodAllDay.asObservable();
    }

    get detailsAnimalByPeriodAllDayPeriod$(): Observable<any> {
        return this.detailsAnimalByPeriodAllDayPeriod.asObservable();
    }

    get animaisLactantes$(): Observable<any> {
        return this.animaisLactantes.asObservable();
    }

    get helpersFarm$(): Observable<any> {
        return this.helpersFarm.asObservable();
    }

    get herdCalvesAndHeifers$(): Observable<any> {
        return this.herdCalvesAndHeifers.asObservable();
    }

    get herdHeifers$(): Observable<any> {
        return this.herdHeifers.asObservable();
    }

    get herdLacting$(): Observable<any> {
        return this.herdLacting.asObservable();
    }

    get herdDry$(): Observable<any> {
        return this.herdDry.asObservable();
    }

    get herdMale10$(): Observable<any> {
        return this.herdMale10.asObservable();
    }

    get herdMale11$(): Observable<any> {
        return this.herdMale11.asObservable();
    }

    get herdMale12$(): Observable<any> {
        return this.herdMale12.asObservable();
    }

    get herdMale13$(): Observable<any> {
        return this.herdMale13.asObservable();
    }

    get pesagensLeiteIdPropriedade$(): Observable<any> {
        return this.pesagensLeiteIdPropriedade.asObservable();
    }

    get animalsDead$(): Observable<any> {
        return this.animalsDead.asObservable();
    }

    get animalsSold$(): Observable<any> {
        return this.animalsSold.asObservable();
    }

    get qualidadeLeite$(): Observable<any> {
        return this.qualidadeLeite.asObservable();
    }

    get animaisCobertosNaoCobertos$(): Observable<any> {
        return this.animaisCobertosNaoCobertos.asObservable();
    }

    getAnimalsDead() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/animalsDead/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const animalsDead = localStorage.getItem('animalsDead');
                    return of(animalsDead ? JSON.parse(animalsDead) : []);
                }),
                tap((items) => {
                    localStorage.setItem('animalsDead', JSON.stringify(items));
                    this.animalsDead.next(items);
                })
            );
    }

    getAnimalsSold() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/animalsSold/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const animalsSold = localStorage.getItem('animalsSold');
                    return of(animalsSold ? JSON.parse(animalsSold) : []);
                }),
                tap((items) => {
                    localStorage.setItem('animalsSold', JSON.stringify(items));
                    this.animalsSold.next(items);
                })
            );
    }

    getQualidadeLeite() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/entregas/qualidade/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const qualidadeLeite = localStorage.getItem('qualidadeLeite');
                    return of(qualidadeLeite ? JSON.parse(qualidadeLeite) : []);
                }),
                tap((items) => {
                    localStorage.setItem('qualidadeLeite', JSON.stringify(items));
                    this.qualidadeLeite.next(items);
                })
            );
    }

    getPesagensLeiteIdPropriedade() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.post(`${environment.api}/pesagensLeiteIdPropriedade`, {
            PropriedadeId: propriedade.idPropriedade
        })
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const pesagensLeiteIdPropriedade = localStorage.getItem('pesagensLeiteIdPropriedade');
                    return of(pesagensLeiteIdPropriedade ? JSON.parse(pesagensLeiteIdPropriedade) : []);
                }),
                tap((items) => {
                    // localStorage.setItem('pesagensLeiteIdPropriedade', JSON.stringify(items));
                    this.pesagensLeiteIdPropriedade.next(items);
                })
            );
    }

    setPesagensLeiteIdPropriedade(pesagensLeiteIdPropriedade) {
        this.pesagensLeiteIdPropriedade.next(pesagensLeiteIdPropriedade);
    }

    /* getHerdCalvesAndHeifers() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/herdCalvesAndHeifers/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const herdCalvesAndHeifers = localStorage.getItem('herdCalvesAndHeifers');
                    return of(herdCalvesAndHeifers ? JSON.parse(herdCalvesAndHeifers) : []);
                }),
                tap((items) => {
                    localStorage.setItem('herdCalvesAndHeifers', JSON.stringify(items));
                    this.herdCalvesAndHeifers.next(items);
                })
            );
    } */

    /* getHerdHeifers() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/herdHeifers/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const herdHeifers = localStorage.getItem('herdHeifers');
                    return of(herdHeifers ? JSON.parse(herdHeifers) : []);
                }),
                tap((items) => {
                    localStorage.setItem('herdHeifers', JSON.stringify(items));
                    this.herdHeifers.next(items);
                })
            );
    } */

    /* getHerdLacting() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/herdLacting/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const herdLacting = localStorage.getItem('herdLacting');
                    return of(herdLacting ? JSON.parse(herdLacting) : []);
                }),
                tap((items) => {
                    localStorage.setItem('herdLacting', JSON.stringify(items));
                    this.herdLacting.next(items);
                })
            );
    } */

    /* getHerdDry() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/herdDry/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const herdDry = localStorage.getItem('herdDry');
                    return of(herdDry ? JSON.parse(herdDry) : []);
                }),
                tap((items) => {
                    localStorage.setItem('herdDry', JSON.stringify(items));
                    this.herdDry.next(items);
                })
            );
    } */

    /* getHerdMale10() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/herdMale/${propriedade.idPropriedade}/10`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const herdMale = localStorage.getItem('herdMale');
                    return of(herdMale ? JSON.parse(herdMale) : []);
                }),
                tap((items) => {
                    localStorage.setItem('herdMale', JSON.stringify(items));
                    this.herdMale10.next(items);
                })
            );
    } */

    /* getHerdMale11() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/herdMale/${propriedade.idPropriedade}/11`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const herdMale = localStorage.getItem('herdMale');
                    return of(herdMale ? JSON.parse(herdMale) : []);
                }),
                tap((items) => {
                    localStorage.setItem('herdMale', JSON.stringify(items));
                    this.herdMale11.next(items);
                })
            );
    } */

    /* getHerdMale12() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/herdMale/${propriedade.idPropriedade}/12`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const herdMale = localStorage.getItem('herdMale');
                    return of(herdMale ? JSON.parse(herdMale) : []);
                }),
                tap((items) => {
                    localStorage.setItem('herdMale', JSON.stringify(items));
                    this.herdMale12.next(items);
                })
            );
    } */

    /* getHerdMale13() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/herdMale/${propriedade.idPropriedade}/13`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const herdMale = localStorage.getItem('herdMale');
                    return of(herdMale ? JSON.parse(herdMale) : []);
                }),
                tap((items) => {
                    localStorage.setItem('herdMale', JSON.stringify(items));
                    this.herdMale13.next(items);
                })
            );
    } */

    getDashboardControlStage() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/dashboardControlStage/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const dashboardControlStage = localStorage.getItem('dashboardControlStage');
                    return of(dashboardControlStage ? JSON.parse(dashboardControlStage) : []);
                }),
                tap((items) => {
                    localStorage.setItem('dashboardControlStage', JSON.stringify(items));
                    this.dashboardControlStage.next(items);
                })
            );
    }

    getDashboardDrying() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/dashboardDrying/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    // const dashboardDrying = localStorage.getItem('dashboardDrying');
                    this.dashboardDrying.next([]);
                    return of([]);
                }),
                tap((items) => {
                    localStorage.setItem('dashboardDrying', JSON.stringify(items));
                    this.dashboardDrying.next(items);
                })
            );
    }

    getDashboardPregnant() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/dashboardPregnant/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const dashboardPregnant = localStorage.getItem('dashboardPregnant');
                    return of(dashboardPregnant ? JSON.parse(dashboardPregnant) : []);
                }),
                tap((items) => {
                    localStorage.setItem('dashboardPregnant', JSON.stringify(items));
                    this.dashboardPregnant.next(items);
                })
            );
    }

    getDashboardDelayed() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/dashboardDelayed/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const dashboardDelayed = localStorage.getItem('dashboardDelayed');
                    return of(dashboardDelayed ? JSON.parse(dashboardDelayed) : []);
                }),
                tap((items) => {
                    localStorage.setItem('dashboardDelayed', JSON.stringify(items));
                    this.dashboardDelayed.next(items);
                })
            );
    }

    getCoveredAnimals() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/dashboard/coveredAnimals/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const coveredAnimals = localStorage.getItem('coveredAnimals');
                    return of(coveredAnimals ? JSON.parse(coveredAnimals) : []);
                }),
                tap((items) => {
                    localStorage.setItem('coveredAnimals', JSON.stringify(items));
                    this.coveredAnimals.next(items);
                })
            );
    }

    getHelpersFarm() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/helpersFarm/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const helpersFarm = localStorage.getItem('helpersFarm');
                    return of(helpersFarm ? JSON.parse(helpersFarm) : []);
                }),
                tap((items) => {
                    localStorage.setItem('helpersFarm', JSON.stringify(items));
                    this.helpersFarm.next(items);
                })
            );
    }

    getDashboardWeights() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/dashboardWeights/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const dashboardWeights = localStorage.getItem('dashboardWeights');
                    return of(dashboardWeights ? JSON.parse(dashboardWeights) : []);
                }),
                tap((items) => {
                    localStorage.setItem('dashboardWeights', JSON.stringify(items?.[0]));
                    this.dashboardWeights.next(items?.[0]);
                })
            );
    }

    getDashboardWeighingsAverage() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/dashboardWeighingsAverage/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const dashboardWeighingsAverage = localStorage.getItem('dashboardWeighingsAverage');
                    return of(dashboardWeighingsAverage ? JSON.parse(dashboardWeighingsAverage) : []);
                }),
                tap((items) => {
                    localStorage.setItem('dashboardWeighingsAverage', JSON.stringify(items));
                    this.dashboardWeighingsAverage.next(items);
                })
            );
    }

    getDashboardRecipe() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/dashboardRecipe/${propriedade.idPropriedade}/${localStorage.getItem('selectedDate') ? moment(localStorage.getItem('selectedDate')).startOf('month').format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD')}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const dashboardRecipe = localStorage.getItem('dashboardRecipe');
                    return of(dashboardRecipe ? JSON.parse(dashboardRecipe) : []);
                }),
                tap((items) => {
                    localStorage.setItem('dashboardRecipe', JSON.stringify(items));
                    this.dashboardRecipe.next(items);
                })
            );
    }

    getEntregasMes() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/entregasMes/${moment().format('MM')}/${moment().format('YYYY')}/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const entregasMes = localStorage.getItem('entregasMes');
                    return of(entregasMes ? JSON.parse(entregasMes) : []);
                }),
                tap((items) => {
                    localStorage.setItem('entregasMes', JSON.stringify(items));
                    this.entregasMes.next(items);
                })
            );
    }

    getDashboardLastDeliveries() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/dashboardLastDeliveries/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const dashboardLastDeliveries = localStorage.getItem('dashboardLastDeliveries');
                    return of(dashboardLastDeliveries ? JSON.parse(dashboardLastDeliveries) : []);
                }),
                tap((items) => {
                    localStorage.setItem('dashboardLastDeliveries', JSON.stringify(items));
                    this.dashboardLastDeliveries.next(items);
                })
            );
    }

    getTotalEntregas() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/totalEntregas/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const totalEntregas = localStorage.getItem('totalEntregas');
                    return of(totalEntregas ? JSON.parse(totalEntregas) : []);
                }),
                tap((items) => {
                    localStorage.setItem('totalEntregas', JSON.stringify(items));
                    this.totalEntregas.next(items);
                })
            );
    }
    getTotalEntregasFaturadas() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.post(`${environment.api}/totalEntregasFaturadas`, { PropriedadeId: propriedade.idPropriedade })
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const totalEntregasFaturadas = localStorage.getItem('totalEntregasFaturadas');
                    return of(totalEntregasFaturadas ? JSON.parse(totalEntregasFaturadas) : []);
                }),
                tap((items) => {
                    localStorage.setItem('totalEntregasFaturadas', JSON.stringify(items));
                    this.totalEntregasFaturadas.next(items);
                })
            );
    }

    getPesagensTotal() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.post(`${environment.api}/pesagensTotal`, {
            PropriedadeId: propriedade.idPropriedade
        })
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const pesagensTotal = localStorage.getItem('pesagensTotal');
                    return of(pesagensTotal ? JSON.parse(pesagensTotal) : []);
                }),
                tap((items) => {
                    localStorage.setItem('pesagensTotal', JSON.stringify(items));
                    this.pesagensTotal.next(items);
                })
            );
    }

    getPesagensMes(payload): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.api}/pesagensMes`, payload)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    getPesagensDia(payload): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.api}/pesagensLeiteDia`, payload)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    setDetailsAnimalByPeriod(data) {
        localStorage.setItem('detailsAnimalByPeriod', JSON.stringify(data));
        this.detailsAnimalByPeriod.next(data);
        return of(true);
    }

    getDetailsAnimalByPeriod(date, periodo): Observable<any> {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        const detailsAnimalByPeriod = localStorage.getItem('detailsAnimalByPeriod');
        const offline = localStorage.getItem('offline') === 'true';
        if (offline) {
            this.detailsAnimalByPeriod.next(detailsAnimalByPeriod ? JSON.parse(detailsAnimalByPeriod) : []);
            return of(detailsAnimalByPeriod ? JSON.parse(detailsAnimalByPeriod) : []);
        }
        return this._httpClient.get(`${environment.api}/detailsAnimalByPeriod/${propriedade.idPropriedade}/${date}/${periodo}`)
            .pipe(
                catchError((error) => {
                    return of(detailsAnimalByPeriod ? JSON.parse(detailsAnimalByPeriod) : []);
                }),
                tap(async (items) => {
                    await this.sync();
                    localStorage.setItem('detailsAnimalByPeriod', JSON.stringify(items));
                    this.detailsAnimalByPeriod.next(items);
                    localStorage.setItem('periodo', periodo);
                })
            );
    }

    getDetailsAnimalByPeriodPromise(date, periodo): Promise<any> {
        return new Promise((resolve, reject) => {
            const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
            if (!propriedade.idPropriedade) {
                return reject(null);
            }
            const detailsAnimalByPeriod = localStorage.getItem('detailsAnimalByPeriod');
            const offline = localStorage.getItem('offline') === 'true';
            if (offline) {
                return resolve(detailsAnimalByPeriod ? JSON.parse(detailsAnimalByPeriod) : []);
            }
            console.log(date, periodo)
            localStorage.setItem('dataPesagemOld', date);
            localStorage.setItem('periodoOld', periodo);
            console.log(`${environment.api}/detailsAnimalByPeriod/${propriedade.idPropriedade}/${date}/${periodo}`)
            this._httpClient.get(`${environment.api}/detailsAnimalByPeriod/${propriedade.idPropriedade}/${date}/${periodo}`)
                .subscribe(
                    async items => {
                        await this.sync();
                        localStorage.setItem('detailsAnimalByPeriod', JSON.stringify(items));
                        this.detailsAnimalByPeriod.next(items);
                        localStorage.setItem('periodo', periodo);
                        resolve(items);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }


    getDetailsAnimalByPeriodAllDay(date): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
            if (!propriedade.idPropriedade) {
                return reject(null);
            }
            try {
                const promises = await Promise.all([
                    this._httpClient.get(`${environment.api}/detailsAnimalByPeriod/${propriedade.idPropriedade}/${date}/M`).toPromise(),
                    this._httpClient.get(`${environment.api}/detailsAnimalByPeriod/${propriedade.idPropriedade}/${date}/T`).toPromise(),
                    this._httpClient.get(`${environment.api}/detailsAnimalByPeriod/${propriedade.idPropriedade}/${date}/N`).toPromise()
                ]);
                const detailsAnimalByPeriodAllDay = {
                    date,
                    detailsAnimalByPeriod: {
                        M: promises[0],
                        T: promises[1],
                        N: promises[2]
                    }
                }
                localStorage.setItem('detailsAnimalByPeriodAllDay', JSON.stringify(detailsAnimalByPeriodAllDay));
                this.detailsAnimalByPeriodAllDay.next(detailsAnimalByPeriodAllDay);
                return resolve(detailsAnimalByPeriodAllDay);
            } catch (error) {
                console.log(error);
                const detailsAnimalByPeriodAllDay = localStorage.getItem('detailsAnimalByPeriodAllDay');
                this.detailsAnimalByPeriodAllDay.next(detailsAnimalByPeriodAllDay ? JSON.parse(detailsAnimalByPeriodAllDay) : JSON.stringify({ date, detailsAnimalByPeriod: { M: [], T: [], N: [] } }));
                return reject(null);
            }
        });
    }

    setDetailsAnimalByPeriodAllDayPeriod(detailsAnimalByPeriodAllDayPeriod) {
        this.detailsAnimalByPeriodAllDayPeriod.next(detailsAnimalByPeriodAllDayPeriod);
    }

    setDetailsAnimalByPeriodAllDay(data, period) {
        const detailsAnimalByPeriodAllDay = JSON.parse(localStorage.getItem('detailsAnimalByPeriodAllDay') || '{"date":"","detailsAnimalByPeriod":{"M":[],"T":[],"N":[]}}')
        detailsAnimalByPeriodAllDay.detailsAnimalByPeriod[period] = data;
        localStorage.setItem('detailsAnimalByPeriodAllDay', JSON.stringify(detailsAnimalByPeriodAllDay));
        this.detailsAnimalByPeriodAllDay.next(detailsAnimalByPeriodAllDay);
        this.detailsAnimalByPeriodAllDayPeriod.next(data);
        return of(true);
    }

    getAnimaisLactantes() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/animaisLactantes/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const animaisLactantes = localStorage.getItem('animaisLactantes');
                    return of(animaisLactantes ? JSON.parse(animaisLactantes) : []);
                }),
                tap((items) => {
                    localStorage.setItem('animaisLactantes', JSON.stringify(items));
                    this.animaisLactantes.next(items);
                })
            );
    }

    async sync() {
        try {
            if (this.platform.is('mobile')) {
                let data: any = await this._databaseProvider.list();
                data = data.filter(e => e.syncStatus === 'false');
                data.map(e => e.syncStatus = (e.syncStatus === 'true'));
                if (data && data.length) {
                    const synced = await this._pesagemLeiteService.sync({
                        PropriedadeId: data[0].PropriedadeId,
                        dataWeightsOffline: data
                    })
                    for (const pesagem of data) {
                        if (synced.includes(pesagem.hashSync))
                            await this._dbProvider.sync(pesagem.hashSync, true);
                    }
                    const dataPesagem = JSON.parse(localStorage.getItem('dataPesagem') || JSON.stringify(new Date()));
                    const periodo = localStorage.getItem('periodo');
                    await this.getDetailsAnimalByPeriodAllDay(moment(dataPesagem).format('YYYY-MM-DD'));
                    const detailsAnimalByPeriodAllDay = JSON.parse(localStorage.getItem('detailsAnimalByPeriodAllDay') || '{"date":"","detailsAnimalByPeriod":{"M":[],"T":[],"N":[]}}');
                    const detailsAnimalByPeriod = detailsAnimalByPeriodAllDay.detailsAnimalByPeriod[periodo];
                    await this.setDetailsAnimalByPeriodAllDay(detailsAnimalByPeriod, periodo).toPromise();
                    await this.getPesagensLeiteIdPropriedade().toPromise();
                    return true;
                } else {

                }
            } else {
                const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
                if (!propriedade.idPropriedade) {
                    return false;
                }
                const weightsOffline = JSON.parse(localStorage.getItem('weightsOffline') || JSON.stringify([]));
                try {
                    await this._pesagemLeiteService.sync({
                        PropriedadeId: propriedade.idPropriedade,
                        dataWeightsOffline: weightsOffline,
                    });
                    localStorage.setItem('weightsOffline', JSON.stringify([]))
                    await this.getPesagensLeiteIdPropriedade().toPromise();
                    return true;
                } catch (err) {
                    return false;
                }
            }
        } catch (error) {
            return true;
        }
    }

    vincular(obj): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.api}/helpersFarm`, obj)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    desvincular(idHelper): Promise<any> {
        return new Promise((resolve, reject) => {
            const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
            if (!propriedade.idPropriedade) {
                return reject(null);
            }
            this._httpClient.delete(`${environment.api}/helpersFarm/${idHelper}/${propriedade.idPropriedade}`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    getHerdCalvesAndHeifers(): Promise<any> {
        return new Promise((resolve, reject) => {
            const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
            if (!propriedade.idPropriedade) {
                return reject(null);
            }
            this._httpClient.get(`${environment.api}/herdCalvesAndHeifers/${propriedade.idPropriedade}`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    getHerdHeifers(): Promise<any> {
        return new Promise((resolve, reject) => {
            const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
            if (!propriedade.idPropriedade) {
                return reject(null);
            }
            this._httpClient.get(`${environment.api}/herdHeifers/${propriedade.idPropriedade}`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    getHerdLacting(): Promise<any> {
        return new Promise((resolve, reject) => {
            const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
            if (!propriedade.idPropriedade) {
                return reject(null);
            }
            this._httpClient.get(`${environment.api}/herdLacting/${propriedade.idPropriedade}`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    getHerdDry(): Promise<any> {
        return new Promise((resolve, reject) => {
            const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
            if (!propriedade.idPropriedade) {
                return reject(null);
            }
            this._httpClient.get(`${environment.api}/herdDry/${propriedade.idPropriedade}`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    getHerdMale10(): Promise<any> {
        return new Promise((resolve, reject) => {
            const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
            if (!propriedade.idPropriedade) {
                return reject(null);
            }
            this._httpClient.get(`${environment.api}/herdMale/${propriedade.idPropriedade}/10`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    getHerdMale11(): Promise<any> {
        return new Promise((resolve, reject) => {
            const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
            if (!propriedade.idPropriedade) {
                return reject(null);
            }
            this._httpClient.get(`${environment.api}/herdMale/${propriedade.idPropriedade}/11`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    getHerdMale12(): Promise<any> {
        return new Promise((resolve, reject) => {
            const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
            if (!propriedade.idPropriedade) {
                return reject(null);
            }
            this._httpClient.get(`${environment.api}/herdMale/${propriedade.idPropriedade}/12`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    getHerdMale13(): Promise<any> {
        return new Promise((resolve, reject) => {
            const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
            if (!propriedade.idPropriedade) {
                return reject(null);
            }
            this._httpClient.get(`${environment.api}/herdMale/${propriedade.idPropriedade}/13`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }


    getAnimaisCobertosNaoCobertos() {
        const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
        if (!propriedade.idPropriedade) {
            return of([]);
        }
        return this._httpClient.get(`${environment.api}/animaisCobertosNaoCobertos/${propriedade.idPropriedade}`)
            .pipe(
                catchError((error) => {
                    console.log(error);
                    const animaisCobertosNaoCobertos = localStorage.getItem('animaisCobertosNaoCobertos');
                    return of(animaisCobertosNaoCobertos ? JSON.parse(animaisCobertosNaoCobertos) : []);
                }),
                tap((items) => {
                    localStorage.setItem('animaisCobertosNaoCobertos', JSON.stringify(items));
                    this.animaisCobertosNaoCobertos.next(items);
                })
            );
    }
}