<ion-content class="bg-gray-200" [fullscreen]="true">
  <div class="flex flex-col flex-auto w-full bg-white border border-b-2 sticky top-0 z-50"
    [ngClass]="{'p-3': platform.is('desktop'), 'pt-8': !platform.is('desktop')}">
    <div class="flex flex-wrap w-full">
      <div class="flex items-center justify-between w-full">
        <div class="cursor-pointer p-3" (click)="backToHome()">
          <img class="logo-text w-36" src="assets/images/logo/logo-text.png">
        </div>
        <button class="mr-2" mat-icon-button (click)="backToHome()">
          <mat-icon style="font-size: 32px !important; width: 32px !important; height: 32px !important; color: #5ba74d" class="cursor-pointer hover:text-green-100" [svgIcon]="'home'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="bg-white">
    <div class="px-4 pb-4" *ngIf="step === 1">
      <h1 class="text-black">{{data?.modal}}</h1>
  
      <!-- Search -->
      <mat-form-field class="treo-mat-dense treo-mat-no-subscript w-full mb-4">
        <input class="text-black" matInput [autocomplete]="'off'" [placeholder]="'Buscar'"
          (keyup)="filter($event.target.value)">
      </mat-form-field>
    </div>
    <div class="px-4 pb-4" *ngIf="step === 2 || step === 3">
      <div class="flex flex-row items-center w-full">
        <!-- <button mat-icon-button class="mr-4" (click)="unset()">
          <mat-icon [svgIcon]="'arrow_back_ios'"></mat-icon>
        </button> -->
        <h1 class="text-black">{{ form.value.animal.animal || form.value.animal.identification + ' - ' +
          form.value.animal.name }}</h1>
      </div>
    </div>
    <div class="px-4 pb-4" *ngIf="step === 4">
      <div class="flex flex-row items-center w-full">
        <!-- <button mat-icon-button class="mr-4" (click)="unset()">
          <mat-icon [svgIcon]="'arrow_back_ios'"></mat-icon>
        </button> -->
        <h1 class="text-black">Confirmação</h1>
      </div>
    </div>
  </div>
  <div class="min-h-full" style="background: var(--ion-background-color, #F1F5F9);">
    <div class="p-4 mb-10 h-full overflow-y" style="background: var(--ion-background-color, #F1F5F9);"
      *ngIf="step === 1">
      <div class="grid grid-cols-1 gap-4" *ngIf="filteredArray.length">
        <treo-card class="flex flex-row justify-between p-6 w-full bg-white"
          *ngFor="let card of filteredArray; let i = index;">

          <div class="flex flex-col w-full" *ngIf="data.key === 'animaiscomcobertura'">
            <div class="font-bold">
              {{ card.identification }}
              {{ card.name ? (card.identification ? ' - ' + card.name : card.name) : '' }}
            </div>
            <div>Data de nascimento: {{ card.dateBirth | date:'dd/MM/yyyy' }}</div>
            <div>Primeira cobertura: {{ card.dataPrimeiraCobertura | date:'dd/MM/yyyy' }}</div>
            <div>Dias para primeira cobertura: {{ card.diff }}</div>
          </div>

          <div class="flex flex-col w-full" *ngIf="data.key === 'bezerrasnovilhassemcobertura'">
            <div class="font-bold">
              {{ card.identification }}
              {{ card.name ? (card.identification ? ' - ' + card.name : card.name) : '' }}
            </div>
            <div>Data de nascimento: {{ card.dateBirth | date:'dd/MM/yyyy' }}</div>
            <div>Previsão para primeira cobertura: {{ card.previsao | date:'dd/MM/yyyy' }}</div>
          </div>

          <div class="flex flex-col w-full">
            <div class="font-bold" *ngIf="data.key !== 'animaisMortos' && data.key !== 'animalsSold'">{{ card.animal }}</div>
            <div class="font-bold" *ngIf="data.key === 'animaisMortos' || data.key === 'animalsSold'">{{ card.animal.identification }} {{
              card.animal.name ? (card.animal.identification ? ' - ' + card.animal.name : card.animal.name) : '' }}
            </div>
            <div *ngIf="this.data.key === 'proximassecagens' || this.data.key === 'secagensatrasadas'">Cobertura: {{
              card.cobertura }}</div>
            <div *ngIf="this.data.key === 'proximassecagens' || this.data.key === 'secagensatrasadas'">Secagem: {{
              card.dataSecagem }}</div>
            <div *ngIf="data.key === 'animaisMortos'">Data da morte: {{ card.date.substr(8, 2) + '/' +
              card.date.substr(5, 2) + '/' + card.date.substr(0, 4) }}</div>

            <div *ngIf="data.key === 'animalsSold'">Valor: {{ card.value | currency: 'BRL'}}</div>

            <div *ngIf="data.key === 'animalsSold'">Data da venda: {{ card.date.substr(8, 2) + '/' +
              card.date.substr(5, 2) + '/' + card.date.substr(0, 4) }}</div>

            <div *ngIf="this.data.key === 'partosprevistos'">{{ card.previsaoParto }}</div>

            <div
              *ngIf="this.data.key === 'vacasatrasadas' || this.data.key === 'aptasparacobertura' || this.data.key === 'realizardiagnostico'"
              class="font-bold">{{ card.identification }} | {{ card.name }}</div>
            <div *ngIf="this.data.key === 'vacasatrasadas' || this.data.key === 'aptasparacobertura'">Último parto: {{
              card.formatedDateRegister }}</div>
            <div *ngIf="this.data.key === 'vacasatrasadas'" class="mt-1">Del: {{ card.days }}</div>
            <div *ngIf="this.data.key === 'vacasatrasadas'">Atraso: {{ card.days - 83 }}</div>
            <div *ngIf="this.data.key === 'aptasparacobertura'" class="grid grid-cols-2 gap-4 mt-1">
              <div class="flex flex-col">
                <div>Del</div>
                <div>{{ card.days }}</div>
              </div>
              <div class="flex flex-col">
                <div>Dias aptos</div>
                <div>{{ card.days - 50 }}</div>
              </div>
            </div>
            <div *ngIf="this.data.key === 'realizardiagnostico'">Data Cobertura: {{ card.dateregister }}</div>
            <div class="flex flex-row items-center" *ngIf="this.data.key === 'entregasnomesatual'">
              <div class="flex items-center justify-center h-12 w-12 rounded-full p-2 mr-4 text-white"
                style="background-color: #5ba74d">{{ card.dataEntrega.split('-')[2].split('T')[0] }}</div>
              <div>{{ card.leiteEntregue.toFixed(2) }}</div>
            </div>
            <div class="flex flex-row items-center" *ngIf="this.data.key === 'rankingdepesagens'">
              <div class="flex items-center justify-center h-12 w-12 rounded-full p-2 mr-4 text-white"
                style="background-color: #5ba74d">{{ i+1 }}</div>
              <div class="flex flex-col">
                <div>{{ card.identification }} | {{ card.name }}</div>
                <div>{{ format(card.sum ? card.sum : 0) }}</div>
                <div>DEL: {{ card.del }}</div>
              </div>
            </div>
            <div class="flex flex-row items-center"
              *ngIf="this.data.key === 'bezerras' || this.data.key === 'novilhas' || this.data.key === 'vacasLactacao' || this.data.key === 'vacasSecas' || this.data.key === 'bezerros' || this.data.key === 'novilhos' || this.data.key === 'touros' || this.data.key === 'rufioes' || this.data.key === 'prenhas' || this.data.key === 'vazias'">
              <div class="flex items-center justify-center h-12 w-12 rounded-full p-2 mr-4 text-white"
                style="background-color: #5ba74d">{{ i+1 }}</div>
              <div class="flex flex-col">
                <div>{{ card.identification }} {{ card.name ? (card.identification ? ' | ' + card.name : card.name) : ''
                  }}</div>
              </div>
            </div>
            <button class="w-full bg-white hover:bg-green-100 my-4" mat-stroked-button style="color: #5ba74d" (click)="resetSold(card)" *ngIf="this.data.key === 'animalsSold'">
              Desfazer venda
            </button>
            <button class="w-full bg-white hover:bg-green-100 my-4" mat-stroked-button style="color: #5ba74d" (click)="resetDead(card)" *ngIf="this.data.key === 'animaisMortos'">
              Desfazer morte
            </button>
          </div>
          <button mat-icon-button
            *ngIf="this.data.key !== 'entregasnomesatual' && this.data.key !== 'rankingdepesagens' && this.data.key !== 'bezerras' && this.data.key !== 'novilhas' && this.data.key !== 'vacasSecas' && this.data.key !== 'vacasLactacao' && this.data.key !== 'novilhos' && this.data.key !== 'bezerros' && this.data.key !== 'touros' && this.data.key !== 'rufioes' && this.data.key !== 'animaisMortos' && this.data.key !== 'animalsSold' && this.data.key !== 'prenhas' && this.data.key !== 'vazias' && this.data.key !== 'animaiscomcobertura' && this.data.key !== 'bezerrasnovilhassemcobertura'"
            (click)="set(card)">
            <mat-icon svgIcon="add"></mat-icon>
          </button>
        </treo-card>
      </div>
      <div class="flex items-center justify-center h-full" *ngIf="!filteredArray.length">
        <h6 class="m-0">Nada encontrado</h6>
      </div>
    </div>

    <div class="mb-10" *ngIf="step === 2">
      <div class="grid grid-cols-1">
        <div
          class="flex flex-row items-center justify-between p-6 w-full border-t-2 last:border-b-2 cursor-pointer hover:bg-green-100"
          *ngFor="let register of registers; let i = index;" (click)="set(register.value)">
          <div>{{ register.label }}</div>
        </div>
      </div>
    </div>

    <div class="p-4 mb-10" *ngIf="step === 3">
      <div class="flex flex-row items-center justify-center w-full my-4">
        <mat-calendar #calendar class="max-w" [selected]="form.value.date" [maxDate]="maxDate"
          (selectedChange)="set($event)"></mat-calendar>
      </div>
    </div>

    <div class="p-4 mb-10" *ngIf="step === 4">
      <div class="flex flex-col w-full my-4">
        <div>Você confirma o registro de <strong>{{ reg(form.value.register) }}</strong> para o animal: <strong>{{
            form.value.animal.animal || form.value.animal.identification + ' - ' + form.value.animal.name }}</strong> em
          <strong>{{ form.value.date | date:'dd/MM/yyyy'}}</strong>?</div>
        <div *ngIf="form.value.register === 'cobertura' || form.value.register === 'novaCobertura'">
          <mat-form-field class="w-full">
            <mat-label>Informe o Touro</mat-label>
            <div (click)="select('idBull', 'bulls', 'Informe o Touro')" class="w-full">
              <mat-select #singleSelect [(ngModel)]="form.value.idBull" disabled>
                <mat-option *ngFor="let bull of bulls" [value]="bull.value">
                  {{ bull.label }}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-2 gap-2 mt-4">
          <button mat-raised-button style="background-color: #5ba74d" (click)="dismiss()">Cancelar</button>
          <button mat-raised-button style="background-color: #5ba74d" (click)="confirm()">Confirmar</button>
        </div>
      </div>
    </div>

    <div class="w-full">
      <button mat-raised-button class="fixed bottom-0 w-full" style="background-color: #5ba74d" (click)="dismiss()">
        Voltar
      </button>
    </div>
  </div>
</ion-content>