import { HomeService } from './../../landing/home/home.service';
import { GestaoRebanhoService } from './../../modals/gestao-rebanho/gestao-rebanho.service';
import { AnimaisService } from './../../animais/animais.service';
import { CalendarComponent } from './../../modals/components/calendar/calendar.component';
import { ModalController, AlertController, Platform, LoadingController } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { closeAllModals } from "app/app.service";

@Component({
  selector: 'app-add-ocorrencias',
  templateUrl: './add-ocorrencias.component.html',
  styleUrls: ['./add-ocorrencias.component.scss'],
})
export class AddOcorrenciasComponent implements OnInit {

  form: FormGroup;
  data;
  isMobile: boolean = false;

  constructor(
    private modalController: ModalController,
    private _formBuilder: FormBuilder,
    private _service: AnimaisService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private _gestaoRebanhoService: GestaoRebanhoService,
    private _homeService: HomeService,
    public platform: Platform
  ) { }

  ngOnInit() {
    this.platform.ready().then(async () => {
      if (this.platform.is('mobile') || this.platform.is('mobileweb')) {
        // this.isMobile = true;
      }
    });
    
    this.form = this._formBuilder.group({
      id_animal: [''],
      date: [new Date()],
      type: [''],
      description: [''],
    });

    if (!this.data.id_animal || this.data.id_animal == undefined)
      return this.dismiss();

    this.form.controls.id_animal.patchValue(this.data.id_animal);
  }

  async calendar(control, title) {
    const modal = await this.modalController.create({
      component: CalendarComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: {
          date: this.form.value[control],
        },
        title
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.form.controls[control].patchValue(data);
    }
  }

  async create() {
    this.form.disable();;
    if (!this.form.value.type) {
      this.form.enable();
      const alert = await this.alertController.create({
        header: 'Ops!',
        message: 'Tipo não informado',
        buttons: ['OK']
      });
      return await alert.present();
    }
    const loading = await this.loadingController.create({
      message: 'Por Favor Aguarde',
    });
    await loading.present();
    this._service.animalsOccurences(this.form.value)
      .then(async res => {
        this.form.enable();
        loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Feito!',
          message: 'Ocorrência gravada com sucesso!',
          buttons: ['OK']
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
        this.modalController.dismiss(true);
      }).catch(async err => {
        console.log(err);
        this.form.enable();
        loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Ops!',
          message: 'Erro ao gravar ocorrência. Tente novamente mais tarde',
          buttons: ['OK']
        });
        await alert.present();
      })
  }

  dismiss() {
    this.modalController.dismiss();
  }

  backToHome() {
    closeAllModals();
  }

}
