import { MatTooltipModule } from '@angular/material/tooltip';
import { AnimalWeightComponent } from './gestao-rebanho/animal-weight/animal-weight.component';
import { HistoricoCrescimentoComponent } from './gestao-rebanho/historico-crescimento/historico-crescimento.component';
import { ResumoBnComponent } from './gestao-rebanho/resumo-bn/resumo-bn.component';
import { MatTableModule } from '@angular/material/table';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { RegistrarPesagemComponent } from './gestao-rebanho/registrar-pesagem/registrar-pesagem.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CalendarComponent } from './components/calendar/calendar.component';
import { SelectComponent } from './components/select/select.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { GestaoRebanhoComponent } from './gestao-rebanho/gestao-rebanho.component';
import { MatIconModule } from '@angular/material/icon';
import { TreoCardModule } from './../../../@treo/components/card/card.module';
import { MatButtonModule } from '@angular/material/button';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from './../../shared/shared.module';
import { DashboardModalComponent } from './dashboard-modal/dashboard-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { GestaoRebanhoDetalhesComponent } from './gestao-rebanho/gestao-rebanho-detalhes/gestao-rebanho-detalhes.component';
import { ResumoBnConfigComponent } from './gestao-rebanho/resumo-bn-config/resumo-bn-config.component';



@NgModule({
  declarations: [
    DashboardModalComponent,
    GestaoRebanhoComponent,
    SelectComponent,
    CalendarComponent,
    RegistrarPesagemComponent,
    ResumoBnComponent,
    HistoricoCrescimentoComponent,
    GestaoRebanhoDetalhesComponent,
    AnimalWeightComponent,
    ResumoBnConfigComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    MatButtonModule,
    TreoCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    NgApexchartsModule,
    MatExpansionModule,
    NgxMaskModule,
    NgxCurrencyModule,
    MatButtonToggleModule,
    MatTableModule,
    MatTooltipModule,
  ],
  providers: [
    MatDatepickerModule,
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'pt-BR',
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'MM/YYYY',
        },
        display: {
          dateInput: 'MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
  exports: [
    DashboardModalComponent,
    GestaoRebanhoComponent,
    RegistrarPesagemComponent
  ]
})
export class ModalsModule { }
