import { ModalsModule } from "./modules/modals/modals.module";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
//import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from "@angular/platform-browser/animations";
import { CoreModule } from "./core/core.module";
import { LayoutModule } from "./layout/layout.module";
import { MarkdownModule } from "ngx-markdown";
import { appConfig } from "./core/config/app.config";
import { mockDataServices } from "./data/mock";
import { TreoModule } from "../@treo";
import { TreoConfigModule } from "../@treo/services/config";
import { TreoMockApiModule } from "../@treo/lib/mock-api";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { registerLocaleData } from "@angular/common";
import localePtBr from "@angular/common/locales/pt";
import { DashboardModule } from "./modules/dashboard-components/dashboard.module";
import { Device } from "@ionic-native/device/ngx";
import { SQLite } from "@ionic-native/sqlite/ngx";
import { DatabaseProvider } from "../../database";
registerLocaleData(localePtBr);

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    AppRoutingModule,

    TreoModule,
    TreoConfigModule.forRoot(appConfig),
    TreoMockApiModule.forRoot(mockDataServices),
    DashboardModule,
    CoreModule,
    LayoutModule,
    MarkdownModule.forRoot({}),
    ModalsModule,
  ],
  providers: [
    //StatusBar,
    SplashScreen,
    BarcodeScanner,
    Device,
    SQLite,
    DatabaseProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "pt" },
    { provide: DEFAULT_CURRENCY_CODE, useValue: "BRL" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
