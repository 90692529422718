import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, Platform, LoadingController, AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResumoDetalhadoService } from './resumo-detalhado.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import saveAs from "file-saver";
import { Filesystem, Directory } from '@capacitor/filesystem';
import { closeAllModals } from "app/app.service";
import * as moment from 'moment';

@Component({
  selector: 'app-resumo-detalhado',
  templateUrl: './resumo-detalhado.component.html',
  styleUrls: ['./resumo-detalhado.component.scss'],
})
export class ResumoDetalhadoComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  animais = [];
  @ViewChild(MatPaginator) animalDetailsPaginator: MatPaginator;
  @ViewChild('animalDetailsTable', { read: MatSort }) animalDetailsTableMatSort: MatSort;
  animalDetailsDataSource: MatTableDataSource<any> = new MatTableDataSource();
  animalDetailsTableColumns: string[] = [
    "earTag",
    "name",
    "dateLastPregnant",
    "del",
    "sex",
    "reproductiveState",
    "productiveState",
    "limetedCoverage",
    "dateCoverage",
    "diferenceLimit",
    "openDays",
    "dateNextPregnant",
    "iepDays",
    "iepMonth",
    "dateDrying"
  ];

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.animalDetailsDataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private _resumoDetalhadoService: ResumoDetalhadoService,
    public platform: Platform
  ) { }

  ngOnInit() {
    this._resumoDetalhadoService.getResumoRebanho()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        console.log('result')
        console.log(result)
        if (result.length > 0) {
          let dateLastPregnant = "";
          let del: any = "";
          let dateCoverage = "";
          let dateDrying = "";
          let dateNextPregnant = "";
          let dateNextPregnantAux = null;
          let afterLimetedCoverage;
          let limetedCoverage = "";
          let diferenceLimit = null;
          let reproductiveState = "";
          let productiveState = "";
          let openDays = null;
          let iepDays = null;
          let iepMonth = null;

          var detalhesAnimais = result.map((row) => {
            let validDatePregnant = moment(row.lastPregnant).isValid();
            let validDateCoverage = moment(row.coverageDate).isValid();
            if (validDatePregnant && row.lastPregnant !== null) {
              let auxDate = new Date(
                new Date(row.lastPregnant).valueOf() +
                new Date(row.lastPregnant).getTimezoneOffset() * 60 * 1000
              );

              afterLimetedCoverage = moment(auxDate).add(83, 'days');
              limetedCoverage = moment(afterLimetedCoverage).format('DD/MM/YYYY');
              dateLastPregnant = moment(auxDate).format('DD/MM/YYYY');
              del = moment().diff(moment(auxDate), 'days');
            } else {
              dateLastPregnant = "";
              del = "";
              limetedCoverage = "";
            }

            if (validDateCoverage && row.coverageDate !== null) {
              let auxDate = new Date(
                new Date(row.coverageDate).valueOf() +
                new Date(row.coverageDate).getTimezoneOffset() * 60 * 1000
              );

              let afterDays = moment(auxDate).add(285, 'days');
              let afterDaysDrying = moment(auxDate).add(225, 'days');

              dateCoverage = moment(auxDate).format('DD/MM/YYYY');

              dateNextPregnant = moment(afterDays).format('DD/MM/YYYY');

              dateNextPregnantAux = afterDays;

              dateDrying = moment(afterDaysDrying).format('DD/MM/YYYY');
            } else {
              dateCoverage = "";
              dateNextPregnant = "";
              dateNextPregnantAux = "";
              dateDrying = "";
            }

            if (validDatePregnant && row.lastPregnant !== null) {
              if (validDateCoverage && row.coverageDate !== null) {
                openDays = moment(row.coverageDate).diff(moment(row.lastPregnant), 'days');

                if (dateNextPregnantAux !== "") {
                  iepDays = moment(dateNextPregnantAux).diff(moment(row.lastPregnant), 'days');
                  iepMonth = moment(dateNextPregnantAux).diff((row.lastPregnant), 'month');
                } else {
                  iepDays = "";
                  iepMonth = "";
                }
              } else {
                openDays = moment().diff(moment(row.lastPregnant), 'days');
              }
            } else {
              openDays = "";
            }

            if (
              validDateCoverage &&
              row.coverageDate !== null &&
              limetedCoverage !== ""
            ) {
              diferenceLimit = moment(row.coverageDate).diff(moment(afterLimetedCoverage), 'days')

            } else {
              diferenceLimit = null;
            }

            let estagioReprodutivoVazia = [4, 7];
            let estagioReprodutivoPrenhe = [3, 6, 9];
            let estagioReprodutivoCoberta = [2, 5, 8];
            let estagioProdutivoLactante = [4, 5, 6];
            let estagioProdutivoSeca = [7, 8, 9];

            if (estagioReprodutivoVazia.includes(row.idCurrentStages)) {
              reproductiveState = "Vazia";
            } else if (estagioReprodutivoPrenhe.includes(row.idCurrentStages)) {
              reproductiveState = "Prenhe";
            } else if (estagioReprodutivoCoberta.includes(row.idCurrentStages)) {
              reproductiveState = "Coberta";
            } else {
              reproductiveState = "";
            }

            if (estagioProdutivoLactante.includes(row.idCurrentStages)) {
              productiveState = "Lactante";
            } else if (estagioProdutivoSeca.includes(row.idCurrentStages)) {
              productiveState = "Seca";
              dateDrying = "";
            } else {
              productiveState = "Bezerra/Novilha";
              dateDrying = "";
            }

            return {
              idAnimal: row.idAnimal,
              earTag: row.identification,
              name: row.name,
              dateLastPregnant,
              del,
              sex: row.sexCalve,
              reproductiveState,
              productiveState,
              limetedCoverage,
              dateCoverage,
              diferenceLimit,
              openDays,
              dateNextPregnant,
              iepDays,
              iepMonth,
              dateDrying,
            }
          });
        }

        console.log('detalhesAnimais')
        console.log(detalhesAnimais)
        this.animais = detalhesAnimais;
        this.animalDetailsDataSource.data = this.animais;
        this.animalDetailsDataSource.paginator = this.animalDetailsPaginator;
        this.animalDetailsDataSource.sort = this.animalDetailsTableMatSort;

      });
  }

  dismiss() {
    this.modalController.dismiss();
  }

  backToHome() {
    closeAllModals();
  }

  async reportPDF() {
    const loading = await this.loadingController.create({
      message: 'Por Favor Aguarde',
    });
    await loading.present();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (!user || !user.email) {
      loading.dismiss();
      const alert = await this.alertController.create({
        header: 'Ops...',
        message: `Erro ao enviar relatório`,
        buttons: ['OK']
      });
      await alert.present();
      return;
    }
    const animals = this.animais.map(an => {
      const animal = [];
      animal.push(an.idAnimal);
      animal.push(an.earTag);
      animal.push(an.name);
      animal.push(an.dateLastPregnant);
      animal.push(an.del);
      animal.push(an.sex);
      animal.push(an.reproductiveState);
      animal.push(an.productiveState);
      animal.push(an.limetedCoverage);
      animal.push(an.dateCoverage);
      animal.push(an.diferenceLimit);
      animal.push(an.openDays);
      animal.push(an.dateNextPregnant);
      animal.push(an.iepDays);
      animal.push(an.iepMonth);
      animal.push(an.dateDrying);
      return animal;
    });
    this._resumoDetalhadoService.reportPDF({
      data: animals,
      email: user.email,
      fileName: `Leitebem - Resumo do Rebanho ${moment().format('DD/MM/YYYY')}`,
      head: [
        "Id",
        "Brinco",
        "Nome",
        "Dt. Último Parto",
        "DEL",
        "Sexo Bezerro",
        "Est. Reprodut.",
        "Est. Produtivo",
        "Limite Cobertura",
        "Cobertura",
        "Dif. Limite",
        "Dias em Aberto",
        "Prev. Parto",
        "IEP Dias",
        "IEP Mês",
        "Prev. Secagem"
      ],
      orientation: 'l'
    }).then(async () => {
      loading.dismiss();
      const alert = await this.alertController.create({
        header: 'Feito!',
        message: `Relatório foi enviado para seu e-mail.`,
        buttons: ['OK']
      });
      await alert.present();
    }).catch(async err => {
      console.log(err);
      loading.dismiss();
      const alert = await this.alertController.create({
        header: 'Ops...',
        message: `Erro ao enviar relatório`,
        buttons: ['OK']
      });
      await alert.present();
    })
  }

  async downloadPDF() {
    const loading = await this.loadingController.create({
      message: 'Por Favor Aguarde',
    });
    await loading.present();
    let savedFile;
    try {
      const doc = new jsPDF('l');
      doc.setFontSize(20);
      doc.setTextColor("#00604E");
      doc.text(`Leitebem - Resumo do Rebanho ${moment().format('DD/MM/YYYY')}`, 1, 10);
      const data = this.animais.map(an => {
        const animal = [];
        animal.push(an.idAnimal);
        animal.push(an.earTag);
        animal.push(an.name);
        animal.push(an.dateLastPregnant);
        animal.push(an.del);
        animal.push(an.sex);
        animal.push(an.reproductiveState);
        animal.push(an.productiveState);
        animal.push(an.limetedCoverage);
        animal.push(an.dateCoverage);
        animal.push(an.diferenceLimit);
        animal.push(an.openDays);
        animal.push(an.dateNextPregnant);
        animal.push(an.iepDays);
        animal.push(an.iepMonth);
        animal.push(an.dateDrying);
        return animal;
      })
      autoTable(doc, {
        head: [[
          "Id",
          "Brinco",
          "Nome",
          "Dt. Último Parto",
          "DEL",
          "Sexo Bezerro",
          "Est. Reprodut.",
          "Est. Produtivo",
          "Limite Cobertura",
          "Cobertura",
          "Dif. Limite",
          "Dias em Aberto",
          "Prev. Parto",
          "IEP Dias",
          "IEP Mês",
          "Prev. Secagem"
        ]],
        body: data,
        margin: {
          horizontal: 1,
          bottom: 1
        },
        headStyles: {
          fontSize: 8
        },
        bodyStyles: {
          fontSize: 8
        }
      });

      if (!this.platform.is('mobile') || this.platform.is('mobileweb')) {
        doc.save(`Leitebem - Resumo do Rebanho ${moment().format('DD/MM/YYYY')}.pdf`);
        loading.dismiss();
      } else {
        const base64 = (await this.convertBlobToBase64(doc.output('blob'))) as string;
        savedFile = await Filesystem.writeFile({
          path: `Leitebem-Resumo-do-Rebanho-${moment().format("YYYYMMDDHHmmss")}.pdf`,
          data: base64,
          directory: Directory.Documents,
        });
        loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Feito!',
          message: `Arquivo salvo em Documentos`,
          buttons: ['OK']
        });
        await alert.present();
      }
    } catch (error) {
      console.log(error);
      loading.dismiss();
      const alert = await this.alertController.create({
        header: 'Ops...',
        message: `Erro ao baixar arquivo. Tente novamente mais tarde`,
        buttons: ['OK']
      });
      await alert.present();
    }

    /* if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
      try {
        loading.dismiss();
        await Browser.open({ url: savedFile.uri });
      } catch (error) {
        console.log(error);
        loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Ops...',
          message: `Erro ao abrir arquivo`,
          buttons: ['OK']
        });
        await alert.present();
      }
    } */
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    }
    reader.readAsDataURL(blob);
  })
}