<ion-content class="bg-gray-200" [fullscreen]="true">
  <div class="flex flex-col flex-auto w-full bg-white border border-b-2 sticky top-0 z-50"
    [ngClass]="{'p-3': platform.is('desktop'), 'pt-8': !platform.is('desktop')}">
    <div class="flex flex-wrap w-full">
      <div class="flex items-center justify-between w-full">
        <div class="cursor-pointer p-3" (click)="backToHome()">
          <img class="logo-text w-36" src="assets/images/logo/logo-text.png">
        </div>
        <button class="mr-2" mat-icon-button (click)="backToHome()">
          <mat-icon style="font-size: 32px !important; width: 32px !important; height: 32px !important; color: #5ba74d"
            class="cursor-pointer hover:text-green-100" [svgIcon]="'home'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="px-4 pb-4 mb-10">
    <h3 class="m-0 my-4">Tutoriais em vídeo sobre o uso do módulo {{ data?.title }}</h3>
    <div class="flex flex-col items-center justify-center w-full" *ngIf="videos.length">
      <treo-card style="max-width: 592px !important;" class="flex flex-col bg-white p-2 mb-4 w-full" *ngFor="let video of videos">
        <h6 class="m-0 p-4">{{ video.title }}</h6>
        <iframe class="video-yt" [src]="video.link | safeHtml" frameborder="0" autoplay="0" allowfullscreen></iframe>
      </treo-card>
    </div>
    <div class="flex flex-col items-center justify-center" *ngIf="!videos.length">
      <div>Sem vídeos para esse módulo</div>
    </div>
  </div>
  <div class="w-full">
    <button mat-raised-button class="fixed bottom-0 w-full" style="background-color: #5ba74d" (click)="dismiss()">
      Voltar
    </button>
  </div>
</ion-content>