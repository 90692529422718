<ion-content class="bg-gray-200" [fullscreen]="true">
  <div class="flex flex-col flex-auto w-full bg-white border border-b-2 sticky top-0 z-50"
    [ngClass]="{'p-3': platform.is('desktop'), 'pt-8': !platform.is('desktop')}">
    <div class="flex flex-wrap w-full">
      <div class="flex items-center justify-between w-full">
        <div class="cursor-pointer p-3" (click)="backToHome()">
          <img class="logo-text w-36" src="assets/images/logo/logo-text.png">
        </div>
        <button class="mr-2" mat-icon-button (click)="backToHome()">
          <mat-icon style="font-size: 32px !important; width: 32px !important; height: 32px !important; color: #5ba74d" class="cursor-pointer hover:text-green-100" [svgIcon]="'home'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="px-4 pb-4 mb-10">
    <h1>{{ data?.animal ? 'Editar': 'Cadastro'}}</h1>

    <img id="resized" hidden>

    <form [formGroup]="form" class="flex flex-col md:flex-row lg:flex-row xl:flex-row w-full">
      <div class="pr-4">
        <div class="relative flex items-center justify-center rounded-full avatar overflow-hidden ring-4 ring-bg-card">
            <!-- Upload / Remove avatar -->
            <div class="absolute inset-0 z-10"></div>
            <div class="absolute inset-0 flex items-center justify-center z-20">
                <div>
                    <input
                        id="avatar-file-input"
                        class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                        type="file"
                        [multiple]="false"
                        [accept]="'image/jpeg, image/png'"
                        (change)="uploadAvatar(avatarFileInput.files)"
                        #avatarFileInput>
                    <label
                        class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-hover"
                        for="avatar-file-input"
                        matRipple>
                        <mat-icon
                            class="text-white"
                            [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                    </label>
                </div>
            </div>
            <!-- Image/Letter -->
            <img
                class="object-cover w-full h-full"
                *ngIf="avatar || form.value.avatar"
                [src]="avatar || form.value.avatar">
        </div>
      </div>

      <div class="flex flex-col w-full">
        <div class="flex flex-col w-full mb-4">
          <div class="font-semibold">Sexo</div>
          <mat-radio-group [formControlName]="'sex'" (change)="toggleSex()">
            <mat-radio-button value="F">Fêmea</mat-radio-button>
            <mat-radio-button value="M" class="ml-4">Macho</mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field class="w-full">
          <mat-label>Data de nascimento</mat-label>
          <div (click)="calendar('dateBirth', 'Data de nascimento')" class="flex flex-row items-center w-full">
            <input matInput [formControlName]="'dateBirth'" [matDatepicker]="picker" [disabled]="isMobile">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </mat-form-field>
  
        <mat-form-field class="w-full">
          <mat-label>Nome do animal</mat-label>
          <input matInput [formControlName]="'name'">
        </mat-form-field>
  
        <mat-form-field class="w-full">
          <mat-label>Identificação</mat-label>
          <input matInput [formControlName]="'identification'">
        </mat-form-field>
  
        <mat-form-field class="w-full" *ngIf="isMobile">
          <mat-label>Raças</mat-label>
          <div (click)="select('breed', 'breeds', 'Raças')" class="w-full">
            <mat-select #singleSelect [formControlName]="'breed'" disabled>
              <mat-option *ngFor="let breed of breeds" [value]="breed.value">
                {{ breed.label }}
              </mat-option>
            </mat-select>
          </div>
        </mat-form-field>

        <mat-form-field  class="w-full" *ngIf="!isMobile">
          <mat-label>Raças</mat-label>
          <input matInput [matAutocomplete]="autoComplete1" #valueBreed
              (keyup)="getBreed(valueBreed.value)" [(ngModel)]="breedText" [ngModelOptions]="{standalone: true}">
          <mat-autocomplete matNativeControl #autoComplete1="matAutocomplete" name="autoComplete">
            <mat-option *ngFor="let e of breedsArray" [value]="e.value" (click)="setBreed(e)" >
              {{ e.label }}
            </mat-option>
            <mat-option *ngIf="!breedsArray.length" disabled>
              <span>Sem resultados</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
  
        <mat-form-field class="w-full" *ngIf="isMobile && !data?.animal && form.value.sex === 'F'">
          <mat-label>Estágios</mat-label>
          <div (click)="select('stage', 'stagesFemales', 'Estágios')" class="w-full">
            <mat-select #singleSelect [formControlName]="'stage'" required disabled>
              <mat-option *ngFor="let stage of stagesFemales" [value]="stage.value">
                {{ stage.label }}
              </mat-option>
            </mat-select>
          </div>
        </mat-form-field>

        <mat-form-field class="w-full" *ngIf="!isMobile && !data?.animal && form.value.sex === 'F'">
          <mat-label>Estágios</mat-label>
          <input matInput [matAutocomplete]="autoComplete2" #valueStagesFemales
              (keyup)="getStagesFemales(valueStagesFemales.value)" [(ngModel)]="stagesFemalesText" [ngModelOptions]="{standalone: true}">
          <mat-autocomplete matNativeControl #autoComplete2="matAutocomplete" name="autoComplete">
            <mat-option *ngFor="let e of stagesFemalesArray" [value]="e.value" (click)="setStagesFemales(e)" >
              {{ e.label }}
            </mat-option>
            <mat-option *ngIf="!stagesFemalesArray.length" disabled>
              <span>Sem resultados</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
  
        <mat-form-field class="w-full" *ngIf="isMobile && !data?.animal && form.value.sex === 'M'">
          <mat-label>Estágios</mat-label>
          <div (click)="select('stage', 'stagesMales', 'Estágios')" class="w-full">
            <mat-select #singleSelect [formControlName]="'stage'" required disabled>
              <mat-option *ngFor="let stage of stagesMales" [value]="stage.value">
                {{ stage.label }}
              </mat-option>
            </mat-select>
          </div>
        </mat-form-field>

        <mat-form-field class="w-full" *ngIf="!isMobile && !data?.animal && form.value.sex === 'M'">
          <mat-label>Estágios</mat-label>
          <input matInput [matAutocomplete]="autoComplete3" #valueStagesMales
              (keyup)="getStagesMales(valueStagesMales.value)" [(ngModel)]="stagesMalesText" [ngModelOptions]="{standalone: true}">
          <mat-autocomplete matNativeControl #autoComplete3="matAutocomplete" name="autoComplete">
            <mat-option *ngFor="let e of stagesMalesArray" [value]="e.value" (click)="setStagesMales(e)" >
              {{ e.label }}
            </mat-option>
            <mat-option *ngIf="!stagesMalesArray.length" disabled>
              <span>Sem resultados</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
  
        <mat-form-field class="w-full"
          *ngIf="!data?.animal && (form.value.stage === 4 || form.value.stage === 5 || form.value.stage === 6 || form.value.stage === 7 || form.value.stage === 8 || form.value.stage === 9)">
          <mat-label>Data do Último Parto</mat-label>
          <div (click)="calendar('dateParto', 'Data do Último Parto')" class="flex flex-row items-center w-full">
            <input matInput [formControlName]="'dateParto'" [matDatepicker]="picker" [disabled]="isMobile">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </mat-form-field>
  
        <mat-form-field class="w-full"
          *ngIf="!data?.animal && (form.value.stage === 2 || form.value.stage === 3 || form.value.stage === 5 || form.value.stage === 6 || form.value.stage === 8 || form.value.stage === 9)">
          <mat-label>Data da Cobertura</mat-label>
          <div (click)="calendar('dateCobertura', 'Data da Cobertura')" class="flex flex-row items-center w-full">
            <input matInput [formControlName]="'dateCobertura'" [matDatepicker]="picker" [disabled]="isMobile">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </mat-form-field>
  
        <mat-form-field class="w-full" *ngIf="!data?.animal && form.value.stage === 7">
          <mat-label>Data de secagem</mat-label>
          <div (click)="calendar('dateSecagem', 'Data de secagem')" class="flex flex-row items-center w-full">
            <input matInput [formControlName]="'dateSecagem'" [matDatepicker]="picker" [disabled]="isMobile">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </mat-form-field>
  
        <mat-form-field class="w-full" *ngIf="!data?.animal && (form.value.stage === 1 || form.value.stage === 2 || form.value.stage === 3)">
          <mat-label>Data da Pesagem</mat-label>
          <div (click)="calendar('datePesagem', 'Data da Pesagem')" class="flex flex-row items-center w-full">
            <input matInput [formControlName]="'datePesagem'" [matDatepicker]="picker" [disabled]="isMobile">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </mat-form-field>
  
        <mat-form-field class="w-full" *ngIf="!data?.animal && (form.value.stage === 1 || form.value.stage === 2 || form.value.stage === 3)">
          <mat-label>Peso do Animal (Kg)</mat-label>
          <input matInput [formControlName]="'peso'" type="tel" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',', align: 'left', allowNegative: false, precision: 2, nullable: false, max: 999.99}">
        </mat-form-field>
  
        <mat-form-field class="w-full" *ngIf="isMobile">
          <mat-label>Pai</mat-label>
          <div (click)="select('dad', 'animalsMale', 'Pai')" class="w-full">
            <mat-select #singleSelect [formControlName]="'dad'" disabled>
              <mat-option *ngFor="let animal of animalsMale" [value]="animal.value">
                {{ animal.label }}
              </mat-option>
            </mat-select>
          </div>
        </mat-form-field>

        <mat-form-field class="w-full" *ngIf="!isMobile">
          <mat-label>Pai</mat-label>
          <input matInput [matAutocomplete]="autoComplete4" #valueAnimalsMale
              (keyup)="getAnimalsMale(valueAnimalsMale.value)" [(ngModel)]="animalsMaleText" [ngModelOptions]="{standalone: true}">
          <mat-autocomplete matNativeControl #autoComplete4="matAutocomplete" name="autoComplete">
            <mat-option *ngFor="let e of animalsMaleArray" [value]="e.value" (click)="setAnimalsMale(e)" >
              {{ e.label }}
            </mat-option>
            <mat-option *ngIf="!animalsMaleArray.length" disabled>
              <span>Sem resultados</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
  
        <mat-form-field class="w-full" *ngIf="isMobile">
          <mat-label>Mãe</mat-label>
          <div (click)="select('mon', 'animalsFemale', 'Mãe')" class="w-full">
            <mat-select #singleSelect [formControlName]="'mon'" disabled>
              <mat-option *ngFor="let animal of animalsFemale" [value]="animal.value">
                {{ animal.label }}
              </mat-option>
            </mat-select>
          </div>
        </mat-form-field>

        <mat-form-field class="w-full" *ngIf="!isMobile">
          <mat-label>Mãe</mat-label>
          <input matInput [matAutocomplete]="autoComplete5" #valueAnimalsFemale
              (keyup)="getAnimalsFemale(valueAnimalsFemale.value)" [(ngModel)]="animalsFemaleText" [ngModelOptions]="{standalone: true}">
          <mat-autocomplete matNativeControl #autoComplete5="matAutocomplete" name="autoComplete">
            <mat-option *ngFor="let e of animalsFemaleArray" [value]="e.value" (click)="setAnimalsFemale(e)" >
              {{ e.label }}
            </mat-option>
            <mat-option *ngIf="!animalsFemaleArray.length" disabled>
              <span>Sem resultados</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </form>

    <div [ngClass]="{'md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2': !parto && !data?.animal}" class="grid grid-cols-1 gap-4 w-full">
      <button class="submit-button treo-mat-button-large w-full" mat-flat-button [color]="'primary'"
        style="background-color: #5ba74d" [disabled]="form.disabled" (click)="create(true)" *ngIf="!parto && !data?.animal">
        <span *ngIf="!form.disabled">
          Salvar e continuar
        </span>
        <mat-progress-spinner *ngIf="form.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
      </button>
      <button class="submit-button treo-mat-button-large w-full" mat-flat-button [color]="'primary'"
        style="background-color: #5ba74d" [disabled]="form.disabled" (click)="create(false)">
        <span *ngIf="!form.disabled">
          Salvar
        </span>
        <mat-progress-spinner *ngIf="form.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
      </button>
    </div>
  </div>

  <div class="w-full">
    <button mat-raised-button class="fixed bottom-0 w-full" style="background-color: #5ba74d" (click)="dismiss()">
      {{ parto ? 'Cadastrar Mais Tarde' : 'Voltar' }}
    </button>
  </div>
</ion-content>