import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {

  title;
  data;
  maxDate;

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    if (this.data && this.data.maxDate)
      this.maxDate = this.data.maxDate;
  }


  dismiss(value = false) {
    this.modalController.dismiss(value);
  }
}
