import {IConfig} from "ngx-mask";
import {KpiComponent} from "./kpi/kpi.component";
import {NgModule} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {TreoCardModule} from "../../../@treo/components/card";


@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        SharedModule,
        MatIconModule,
        MatButtonModule,
        TreoCardModule,
    ],
    exports: [
        KpiComponent
    ],
    declarations: [
        KpiComponent
    ],
    providers: []
})
export class DashboardModule {}
