import { takeUntil } from 'rxjs/operators';
import { GestaoRebanhoService } from './../gestao-rebanho.service';
import { ModalController } from '@ionic/angular';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexMarkers, ApexStroke, ApexTitleSubtitle, ApexXAxis, ChartComponent } from 'ng-apexcharts';
import { Component, Input, OnInit, ViewChild, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import { ResumoBnConfigComponent } from '../resumo-bn-config/resumo-bn-config.component';
import { Subject } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
};

@Component({
  selector: 'app-resumo-bn',
  templateUrl: './resumo-bn.component.html',
  styleUrls: ['./resumo-bn.component.scss'],
})
export class ResumoBnComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions;
  @Input() animal;

  dias = 180;
  pesoInicial = 40;
  pesoFinal = 300;
  ganhoDiario = (300 - 40) / 180;

  pesoRestante = 0;
  loading: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _gestaoRebanhoService: GestaoRebanhoService,
    private modalController: ModalController,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.chartOptions = {
      series: [
        {
          name: 'Esperado',
          data: []
        },
        {
          name: 'Realizado',
          data: []
        },
        {
          name: '',
          data: []
        }
      ],
      chart: {
        toolbar: {
          show: false
        },
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 4,
        dashArray: [0, 0, 5]
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        type: 'category',
        categories: []
      },
      colors: ['#3a34c8', '#6bd667', '#6bd667']
    };
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._gestaoRebanhoService.rbConfig$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(rbConfig => {
        if (rbConfig) {
          this.dias = rbConfig.rb_qtd_dias;
          this.pesoInicial = rbConfig.rb_peso_nascimento
          this.pesoFinal = rbConfig.rb_peso_ideal;
          this.ganhoDiario = (rbConfig.rb_peso_ideal - 40) / rbConfig.rb_qtd_dias;
        }
        this.popularGrafico();
      });
  }

  ngOnDestroy(): void {
    this.animal = null;
    this.chartOptions.series[0].data = [];
    this.chartOptions.series[1].data = [];
    this.chartOptions.series[2].data = [];
  }

  popularGrafico(): void {
    this.loading = true;

    this.chartOptions.series[0].data = [];
    this.chartOptions.series[1].data = [];
    this.chartOptions.series[2].data = [];
    this.chartOptions.xaxis.categories = [];

    const pesagens = this.animal.pesagens.sort((a, b) => moment(a.dateWeight).diff(moment(b.dateWeight)));
    const dataNascimento = moment(this.animal.dateBirth, 'DD/MM/YYYY')
    if (!pesagens.length || dataNascimento !== moment(pesagens[0].dateWeight.split('T')[0], 'DD/MM/YYYY')) {
      pesagens.unshift({
        dateWeight: moment(this.animal.dateBirth, 'DD/MM/YYYY').toISOString(),
        weight: 0
      });
    }

    for (const pesagem of pesagens) {
      const dias = moment(pesagem.dateWeight).diff(dataNascimento, 'days');
      const ganhoEsperado = this.pesoInicial + (this.ganhoDiario*dias);
      const ganhoRealizado = pesagem.weight;
      if (dias <= this.dias) {
        this.chartOptions.xaxis.categories.push(moment(pesagem.dateWeight).format('DD/MM/YYYY'));
        this.chartOptions.series[0].data.push(Number(ganhoEsperado.toFixed(2)));
        this.chartOptions.series[1].data.push(ganhoRealizado);
        this.chartOptions.series[2].data.push(ganhoRealizado);
      }
    }
    const ultimo = moment(dataNascimento).add(this.dias, 'days');
    this.chartOptions.xaxis.categories.push(moment(ultimo).format('DD/MM/YYYY'));
    this.chartOptions.series[0].data.push(Number(this.pesoFinal.toFixed(2)));
    this.chartOptions.series[2].data.push(Number(this.pesoFinal.toFixed(2)));

    this._changeDetectorRef.detectChanges();
    this.loading = false;
  }

  async configuracao() {
    const modal = await this.modalController.create({
      component: ResumoBnConfigComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: null
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }


}
