<treo-card class="flex flex-col bg-white rounded w-full">
  <div class="flex flex-row items-center justify-between py-6 px-3" style="background-color: #5ba74d">
    <div class="flex flex-col items-center justify-center">
      <div class="font-bold text-md text-white uppercase tracking-wider">Curva de Crescimento</div>
    </div>
    <button mat-icon-button [matTooltip]="'Nova Pesagem'" (click)="configuracao()" style="color: #fff">
      <mat-icon [svgIcon]="'settings'"></mat-icon>
    </button>
  </div>
  <div class="flex flex-col bg-white shadow-md rounded overflow-hidden" *ngIf="!loading">
    <div id="chart">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [dataLabels]="chartOptions.dataLabels"
        [grid]="chartOptions.grid"
        [stroke]="chartOptions.stroke"
        [colors]="chartOptions.colors"
      ></apx-chart>
    </div>
  </div>
  <treo-card class="p-4 bg-red-500 text-white">
    Este animal deverá ganhar {{ pesoRestante }} Kg para chegar a {{ pesoFinal }} Kg
  </treo-card>
</treo-card>