// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAjAIf0FCT082VnulV7wz8hzQUAzyK1DMc",
    authDomain: "leitebem.firebaseapp.com",
    projectId: "leitebem",
    storageBucket: "leitebem.appspot.com",
    messagingSenderId: "179318966140",
    appId: "1:179318966140:web:05713be7069c873a66c6f9"
  },
  api: 'https://api-leitebem.sistemafaeg.com.br',
  // api: "https://api.leitebem.com.br",
  // api: "https://bkleitebem.sistemafaeg.org.br",
  // api: "https://testebkleitebem.sistemafaeg.org.br",
  // api: 'https://apileitebem.sistemafaeg.com.br',
  // api: 'http://localhost:3032',
  apiOauth: "https://id.sistemafaeg.org.br",
  network: 'LeiteBem',
  version: '2.1.4',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
