<ion-content class="bg-gray-200" [fullscreen]="true">
  <div class="flex flex-col flex-auto w-full bg-white border border-b-2 sticky top-0 z-50"
    [ngClass]="{'p-3': platform.is('desktop'), 'pt-8': !platform.is('desktop')}">
    <div class="flex flex-wrap w-full">
      <div class="flex items-center justify-between w-full">
        <div class="cursor-pointer p-3" (click)="backToHome()">
          <img class="logo-text w-36" src="assets/images/logo/logo-text.png">
        </div>
        <button class="mr-2" mat-icon-button (click)="backToHome()">
          <mat-icon style="font-size: 32px !important; width: 32px !important; height: 32px !important; color: #5ba74d" class="cursor-pointer hover:text-green-100" [svgIcon]="'home'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="flex flex-col flex-auto w-full">
    <div class="flex flex-col w-full">
      <!-- max-w-screen-xl mx-auto p-6 md:p-8-->
      <div class="pl-4 m-1">
        <h1>Resumo Detalhado</h1>
      </div>

      <div class="pl-4">
        <button class="hover:bg-green-100" mat-stroked-button [color]="'primary'" (click)="downloadPDF()" style="color: #5ba74d">
          Baixar PDF
        </button>
        <button class="hover:bg-green-100 ml-4" mat-stroked-button [color]="'primary'" (click)="reportPDF()" style="color: #5ba74d">
          Enviar PDF
        </button>
      </div>

      <div class="w-full m-4">
        <div class="w-full pb-10">
          <div class="overflow-x-auto w-full">

            <div class="w-full mb-2">
              <mat-form-field class="treo-mat-dense treo-mat-no-subscript w-full  bg-white">
                <input class="text-black" matInput [autocomplete]="'off'" [placeholder]="'Buscar'"
                  (keyup)="applyFilter($event)">
              </mat-form-field>
            </div>

            <table class="overflow-x-auto w-full bg-white border" mat-table matSort
              [dataSource]="animalDetailsDataSource" #animalDetailsTable>
              <!-- Brinco -->
              <ng-container matColumnDef="earTag">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Brinco
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.earTag }}
                  </span>
                </td>
              </ng-container>

              <!-- Nome -->
              <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Nome
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.name }}
                  </span>
                </td>
              </ng-container>

              <!-- Dt. Nacimento -->
              <ng-container matColumnDef="dateLastPregnant">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Dt. Último Parto
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.dateLastPregnant }}
                  </span>
                </td>
              </ng-container>

              <!-- DEL -->
              <ng-container matColumnDef="del">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  DEL
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.del }}
                  </span>
                </td>
              </ng-container>

              <!-- Sexo Bezerro -->
              <ng-container matColumnDef="sex">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Sexo Bezerro
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.sex }}
                  </span>
                </td>
              </ng-container>

              <!-- Estágio Reprodutivo -->
              <ng-container matColumnDef="reproductiveState">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Est. Reprodutivo
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.reproductiveState }}
                  </span>
                </td>
              </ng-container>

              <!-- Estátio Produtivo -->
              <ng-container matColumnDef="productiveState">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Est. Produtivo
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.productiveState }}
                  </span>
                </td>
              </ng-container>

              <!-- Limite Cobertura -->
              <ng-container matColumnDef="limetedCoverage">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Limite Cobertura
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.limetedCoverage }}
                  </span>
                </td>
              </ng-container>

              <!-- Cobertura -->
              <ng-container matColumnDef="dateCoverage">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Cobertura
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.dateCoverage }}
                  </span>
                </td>
              </ng-container>

              <!-- Diferença Limite -->
              <ng-container matColumnDef="diferenceLimit">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Dif. Limite
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.diferenceLimit }}
                  </span>
                </td>
              </ng-container>

              <!-- Dias em Aberto -->
              <ng-container matColumnDef="openDays">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Parto
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.openDays }}
                  </span>
                </td>
              </ng-container>

              <!-- Previsão de Parto -->
              <ng-container matColumnDef="dateNextPregnant">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Prev. Parto
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.dateNextPregnant }}
                  </span>
                </td>
              </ng-container>

              <!-- IEP -->
              <ng-container matColumnDef="iepDays">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  IEP Dias
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.iepDays }}
                  </span>
                </td>
              </ng-container>

              <!-- IEP mês -->
              <ng-container matColumnDef="iepMonth">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  IEP Mês
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.iepMonth }}
                  </span>
                </td>
              </ng-container>

              <!-- IEP -->
              <ng-container matColumnDef="dateDrying">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Prev. Secagem
                </th>
                <td mat-cell *matCellDef="let animal">
                  <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    {{ animal.dateDrying }}
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="animalDetailsTableColumns"></tr>
              <tr class="order-row h-16" mat-row *matRowDef="let row; columns: animalDetailsTableColumns;">
              </tr>
            </table>
          </div>
          <div class="p-5 bg-white border border-t-0 border-l-0 border-r-0 border-b-1 w-full"
            *ngIf="animais.length == 0">
            <label>Nenhum dado encontrado.</label>
          </div>
          <mat-paginator class="w-full bg-white" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
            aria-label="Selecione a página">
          </mat-paginator>
        </div>
      </div>

      <div class="w-full">
        <button mat-raised-button class="fixed bottom-0 w-full" style="background-color: #5ba74d" (click)="dismiss()">
          Voltar
        </button>
      </div>

    </div>

  </div>
</ion-content>