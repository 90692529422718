import { ProfileService } from "./../../modules/profile/profile.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { AuthUtils } from "app/core/auth/auth.utils";
import firebase from "firebase";
import { environment } from "../../../environments/environment";
import { maskPhone } from "../utils/utils";
import * as moment from "moment";

@Injectable()
export class AuthService {
  // Private
  private _authenticated: boolean;
  private _user: BehaviorSubject<any>;
  private _userData: BehaviorSubject<any>;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _profileService: ProfileService
  ) {
    // Set the defaults
    this._authenticated = false;
    this._user = new BehaviorSubject(null);
    this._userData = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem("access_token", token);
  }

  set userData(token: string) {
    localStorage.setItem("userData", token);
  }

  get userData$(): Observable<any> {
    return this._userData.asObservable();
  }

  get accessToken(): string {
    return localStorage.getItem("access_token");
  }

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
  }

  get user$(): Observable<any> {
    return this._user.asObservable();
  }

  setUser(user) {
    this._user.next(user);
  }

  setAuthenticated(authenticated) {
    this._authenticated = true;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string, redirect: boolean }) {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError("User is already logged in.");
    }

    return this._httpClient
      .post(`${environment.api}/usuariosEmail`, { email: credentials.email })
      .toPromise()
      .then(async (users: any) => {
        if (users.length !== 0) {
          try {
            return this._httpClient
              .post(`${environment.apiOauth}/sessions`, {
                email: credentials.email,
                password: credentials.password,
              })
              .toPromise()
              .then(async (response: any) => {
                if (users && users[0] && !users[0].status) {
                  return this._user.error(`Usuário desativado.`);
                }

                const oauth = response;
                localStorage.setItem(
                  "Leitebem/dataUser",
                  JSON.stringify(users[0])
                );
                localStorage.setItem("userData", JSON.stringify(users));

                const infoWeightMode = await localStorage.getItem(
                  "Leitebem/weightMode"
                );

                if (infoWeightMode !== null) {
                  const aux = JSON.parse(infoWeightMode);
                  const { status } = aux;
                  if (status !== "offline") {
                    localStorage.setItem(
                      "Leitebem/weightMode",
                      JSON.stringify({ status: "online" })
                    );
                  }
                } else {
                  localStorage.setItem(
                    "Leitebem/weightMode",
                    JSON.stringify({ status: "online" })
                  );
                }
                localStorage.setItem(
                  "Leitebem/ApiOauth",
                  JSON.stringify(oauth)
                );
                await this._profileService.getUser(users[0].id).toPromise();

                this.accessToken = oauth.token;
                // Set the authenticated flag to true
                this._authenticated = true;
                // Return a new observable with the response
                this._user.next(users);
                if (credentials.redirect)
                  location.reload();
                return response;
              })
              .catch((error) => {
                console.log(error);
                if (
                  error &&
                  error.error === "Too many requests, please try again later."
                ) {
                  error.error =
                    "Seu acesso foi temporiariamente bloqueado. Devido a várias tentativas sem sucesso.";
                }
                this._user.error(
                  error?.error || `Erro de autenticação. Tente novamente.`
                );
              });
          } catch (error) {
            console.log(error);
            this._user.error(
              error?.error || `Erro de autenticação. Tente novamente.`
            );
          }
        } else {
          this._user.error(
            `Usuário não encontrado na base de dados Leitebem. Tente novamente.`
          );
        }
      })
      .catch((error) => {
        this._user.error(
          error?.error || `Erro de autenticação. Tente novamente.`
        );
      });
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient
      .post("api/auth/refresh-access-token", {
        access_token: this.accessToken,
      })
      .pipe(
        catchError(() => {
          // Return false
          return of(false);
        }),
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.access_token;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Clear the local storage
    localStorage.clear();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * sendEmail
   *
   * @param email
   */
  async sendEmail(email): Promise<void> {
    return await firebase.auth().sendPasswordResetEmail(email);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    // if (AuthUtils.isTokenExpired(this.accessToken)) {
    //     return of(false);
    // }

    return of(true);
  }

  // usuariosEmail(email): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient
  //       .post(`${environment.api}/usuariosEmail`, {
  //         email,
  //       })
  //       .subscribe(
  //         (res) => resolve(res),
  //         (err) => reject(err)
  //       );
  //   });
  // }

  novoUsuario(usuario): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${environment.api}/usuarios/novo`, {
          name: usuario.name,
          email: usuario.email,
          password: usuario.password,
          cpf: usuario.cpf,
          dataNascimento: usuario.dataNascimento
            ? moment(usuario.dataNascimento).format("YYYY-MM-DD") +
            "T00:00:00.000Z"
            : null,
          telefone: maskPhone(usuario.phone),
          IdCidade: usuario.IdCidade,
          helper: usuario.helper,
          date: new Date(),
          idTerm: usuario.idTerm,
          version: usuario.version,
        })
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  // userspublic(usuario): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient
  //       .post(`${environment.apiOauth}/userspublic`, {
  //         name: usuario.name,
  //         email: usuario.email,
  //         password: usuario.password,
  //         phone1: maskPhone(usuario.phone),
  //         phone2: "",
  //         city: usuario.IdCidade,
  //         state: usuario.estado,
  //         app: "Leitebem",
  //         url_email: "https://leitebem.sistemafaeg.org.br/validacao-email/",
  //       })
  //       .subscribe(
  //         (res) => resolve(res),
  //         (err) => reject(err)
  //       );
  //   });
  // }

  logged(device, version = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        const user =
          JSON.parse(localStorage.getItem("Leitebem/dataUser")) || "{}";
        this._httpClient
          .post(`${environment.api}/log`, {
            idUsuario: user?.id,
            device,
            accuracy: position.coords.accuracy,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            version: environment.version + version,
          })
          .subscribe(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    });
  }
}
