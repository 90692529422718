import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';

@Injectable()
export class DatabaseProvider {

    constructor(private sqlite: SQLite) { }

    /**
     * Cria um banco caso não exista ou pega um banco existente com o nome no parametro
     */
    public getDB() {
        return this.sqlite.create({
            name: 'dataWeights.db',
            location: 'default'
        })
    }

    /**
     * Cria a estrutura inicial do BD
     */
    public createDatabase() {
        return this.getDB()
            .then((db: SQLiteObject) => {
                this.list();
                this.createTables(db);
            })
            .catch(e => console.log(e));
    }

    /**
     * Criando a tabela no BD
     * @param db
     */
    private createTables(db: SQLiteObject) {
        // Criando as tabelas
        db.sqlBatch([
            [
                `
                    CREATE TABLE IF NOT EXISTS dataWeightsOffline (
                        PropriedadeId TEXT NOT NULL,
                        dataPesagem TEXT NOT NULL,
                        earring TEXT NOT NULL,
                        hashSync TEXT primary key NOT NULL,
                        idAnimal INTEGER NOT NULL,
                        name TEXT NOT NULL,
                        periodo TEXT NOT NULL,
                        pesoLeite DOUBLE NOT NULL,
                        syncStatus BOOLEAN NOT NULL
                    )
                `
            ],
        ])
            .then(() => { })
            .catch(e => console.error('Erro ao cria tabela', e));
    }

    /**
     * Inserindo dados no BD
     * @param data
     */
    insert(data: any) {
        return this.getDB()
            .then((db: SQLiteObject) => {
                db.sqlBatch([
                    ['insert into dataWeightsOffline (PropriedadeId, dataPesagem, earring, hashSync, idAnimal, name, periodo, pesoLeite, syncStatus) values (?, ?, ?, ?, ?, ?, ?, ?, ?)', [data.PropriedadeId, data.dataPesagem, data.earring, data.hashSync, data.idAnimal, data.name, data.periodo, data.pesoLeite, data.syncStatus]]
                ])
                    .then(() => { })
                    .catch(e => console.error('Erro ao incluir dados', e));

            })
            .catch(e => console.log(e));
    }

    /**
     * Atualizar campo sync no BD
     * @param hashSync
     * @param syncStatus
     */
    sync(hashSync, syncStatus) {
        return this.getDB()
            .then((db: SQLiteObject) => {
                db.sqlBatch([
                    ['update dataWeightsOffline set syncStatus = ? where hashSync = ?', [syncStatus, hashSync]]
                ])
                    .then(() => { })
                    .catch(e => console.error('Erro ao atualizar dados', e));

            })
            .catch(e => console.log(e));
    }

    /**
     * Listar dados do BD
     */
    list() {
        return this.getDB()
            .then((db: SQLiteObject) => {
                return db.executeSql('SELECT * FROM dataWeightsOffline', []).then(data => {
                    let items = [];
                    if (data.rows.length > 0) {
                        for (var i = 0; i < data.rows.length; i++) {
                            items.push({
                                PropriedadeId: data.rows.item(i).PropriedadeId,
                                dataPesagem: data.rows.item(i).dataPesagem,
                                earring: data.rows.item(i).earring,
                                hashSync: data.rows.item(i).hashSync,
                                idAnimal: data.rows.item(i).idAnimal,
                                name: data.rows.item(i).name,
                                periodo: data.rows.item(i).periodo,
                                pesoLeite: data.rows.item(i).pesoLeite,
                                syncStatus: data.rows.item(i).syncStatus,
                            });
                        }
                    }
                    return items;
                }).catch(err => console.log(err))
            })
            .catch(e => console.log(e));
    }
}