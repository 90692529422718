import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GestaoRebanhoService {
  private rbConfig: BehaviorSubject<any>;
  private breeds: BehaviorSubject<any>;
  private animaisFazenda: BehaviorSubject<any>;
  private animalPhoto: BehaviorSubject<any>;
  private animal: BehaviorSubject<any>;
  private summaryHerd: BehaviorSubject<any>;

  constructor(
    private _httpClient: HttpClient
  ) {
    this.rbConfig = new BehaviorSubject(null);
    this.breeds = new BehaviorSubject([]);
    this.animaisFazenda = new BehaviorSubject([]);
    this.animalPhoto = new BehaviorSubject(null);
    this.animal = new BehaviorSubject(null);
    this.summaryHerd = new BehaviorSubject([]);
  }

  get rbConfig$(): Observable<any> {
    return this.rbConfig.asObservable();
  }

  get breeds$(): Observable<any> {
    return this.breeds.asObservable();
  }

  get animaisFazenda$(): Observable<any> {
    return this.animaisFazenda.asObservable();
  }

  get animalPhoto$(): Observable<any> {
    return this.animalPhoto.asObservable();
  }

  get animal$(): Observable<any> {
    return this.animal.asObservable();
  }

  get summaryHerd$(): Observable<any> {
    return this.summaryHerd.asObservable();
  }

  getRbConfig(): Observable<any> {
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    if (!propriedade.idPropriedade) {
      return of(false);
    }
    return this._httpClient.get(`${environment.api}/configuracao-propriedade/${propriedade.idPropriedade}`)
      .pipe(
        catchError((error) => {
          console.log(error);
          const rbConfig = localStorage.getItem('rbConfig');
          return of(rbConfig ? JSON.parse(rbConfig) : []);
        }),
        tap((items) => {
          localStorage.setItem('rbConfig', JSON.stringify(items));
          this.rbConfig.next(items);
        })
      );
  }

  getBreeds(): Observable<any> {
    return this._httpClient.get(`${environment.api}/racas`)
      .pipe(
        catchError((error) => {
          console.log(error);
          const breeds = localStorage.getItem('breeds');
          return of(breeds ? JSON.parse(breeds) : []);
        }),
        tap((items) => {
          localStorage.setItem('breeds', JSON.stringify(items));
          this.breeds.next(items);
        })
      );
  }

  getAnimal(id): Observable<any> {
    return this._httpClient.get(`${environment.api}/animal/${id}`)
      .pipe(
        catchError((error) => {
          return of(this.animal.next(null));
        }),
        tap((animal) => {
          this.animal.next(animal);
        })
      );
    /* return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.api}/animal/${id}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    }); */
  }

  setAnimal(animal): void {
    this.animal.next(animal);
  }

  getSummaryHerd2(): Observable<any> {
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    if (!propriedade.idPropriedade) {
      return of(this.summaryHerd.next([]));
    }
    return this._httpClient.get(`${environment.api}/summaryHerd/${propriedade.idPropriedade}`)
      .pipe(
        catchError((error) => {
          return of(this.summaryHerd.next([]));
        }),
        tap((summaryHerd) => {
          this.summaryHerd.next(summaryHerd);
        })
      );
  }

  getAnimalPhoto(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.api}/animalPhoto/${id}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  getAnimaisFazenda() {
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    if (!propriedade.idPropriedade) {
      return of([]);
    }
    return this._httpClient.get(`${environment.api}/animaisFazenda/${propriedade.idPropriedade}`)
      .pipe(
        catchError((error) => {
          console.log(error);
          const animaisFazenda = localStorage.getItem('animaisFazenda');
          return of(animaisFazenda ? JSON.parse(animaisFazenda) : []);
        }),
        tap((items) => {
          localStorage.setItem('animaisFazenda', JSON.stringify(items));
          this.animaisFazenda.next(items);
        })
      );
  }

  getLactationPeriodsHistoryId(id): Promise<any> {
    return new Promise((resolve, reject) => {
      const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
      if (!propriedade.idPropriedade) {
        return resolve([]);
      }
      this._httpClient.get(`${environment.api}/lactationPeriodsHistoryId/${propriedade.idPropriedade}/${id}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  getAnimalsOccurences(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.api}/animalsOccurences/${id}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  deleteOcorrencia(animal, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete(`${environment.api}/animalsOccurences/${animal}/${id}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  getLactationPeriodsHistoryDetail(id, period): Promise<any> {
    return new Promise((resolve, reject) => {
      const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
      if (!propriedade.idPropriedade) {
        return resolve([]);
      }
      this._httpClient.get(`${environment.api}/lactationPeriodsHistoryDetail/${propriedade.idPropriedade}/${id}/${period}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  getAnimaisEstagios(id): Promise<any> {
    return new Promise((resolve, reject) => {
      const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
      if (!propriedade.idPropriedade) {
        return resolve([]);
      }
      this._httpClient.post(`${environment.api}/animaisEstagios`, {
        PropriedadeId: propriedade.idPropriedade,
        idAnimal: id
      })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  getSummaryHerd(): Promise<any> {
    return new Promise((resolve, reject) => {
      const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
      if (!propriedade.idPropriedade) {
        return resolve([]);
      }
      this._httpClient.get(`${environment.api}/summaryHerd/${propriedade.idPropriedade}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  getAnimalDetails(idPropriedade, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.api}/summaryAnimal/${idPropriedade}/${id}/`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  pesagensAnimaisAdd(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.api}/pesagensAnimaisAdd`, {
        ...data
      })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  resetSold(idSold, idAnimal): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete(`${environment.api}/animalsSold/${idSold}/${idAnimal}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  resetDead(idDead, idAnimal): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete(`${environment.api}/animalsDead/${idDead}/${idAnimal}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  criarPeso(peso): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.api}/pesagensAnimaisAdd`, peso)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  editarPeso(peso): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.api}/pesagensAnimaisUpdate`, peso)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  excluirPeso(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete(`${environment.api}/pesagensAnimais/${id}`)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  editarResumoBezerrasConfig(config): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.api}/configuracao-propriedade`, config)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }
}