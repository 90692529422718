import { GestaoRebanhoService } from './../gestao-rebanho.service';
import { ModalController, LoadingController, AlertController, Platform } from '@ionic/angular';
import { CalendarComponent } from './../../components/calendar/calendar.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { closeAllModals } from "app/app.service";

@Component({
  selector: 'app-registrar-pesagem',
  templateUrl: './registrar-pesagem.component.html',
  styleUrls: ['./registrar-pesagem.component.scss'],
})
export class RegistrarPesagemComponent implements OnInit {
  data;
  animal;
  form: FormGroup;
  isMobile: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private modalController: ModalController,
    private _gestaoRebanhoService: GestaoRebanhoService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    public platform: Platform
  ) { }

  ngOnInit() {
    this.platform.ready().then(async () => {
      if (this.platform.is('mobile') || this.platform.is('mobileweb')) {
        // this.isMobile = true;
      }
    });
    
    this.animal = this.data.animal;
    this.form = this._formBuilder.group({
      datePesagem: [new Date()],
      peso: [0],
    });
  }

  async calendar(control, title) {
    const modal = await this.modalController.create({
      component: CalendarComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: {
          date: this.form.value[control],
        },
        title
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.form.controls[control].patchValue(data);
    }
  }

  async save() {
    const loading = await this.loadingController.create({
      message: 'Por Favor Aguarde',
    });
    await loading.present();
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");
    if (!propriedade.idPropriedade) {
      return;
    }
    this._gestaoRebanhoService.pesagensAnimaisAdd({
      PropriedadeId: propriedade.idPropriedade,
      dateWeight: moment(this.form.value.datePesagem).format('YYYY-MM-DD'),
      idAnimal: this.animal.id,
      weight: this.form.value.peso
    }).then(async () => {
      const alert = await this.alertController.create({
        header: 'Feito!',
        message: `Pesagem cadastrada com sucesso!`,
        buttons: ['OK']
      });
      loading.dismiss();
      await alert.present();
      const { role } = await alert.onDidDismiss();
      this.modalController.dismiss();
    }).catch(async err => {
      console.log(err);
      const alert = await this.alertController.create({
        header: 'Ops...',
        message: `Erro ao cadastrar pesagem. Tente novamente mais tarde.`,
        buttons: ['OK']
      });
      loading.dismiss();
      await alert.present();
    })
  }

  dismiss() {
    this.modalController.dismiss();
  }

  backToHome() {
    closeAllModals();
  }

}
