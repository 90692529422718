import { AppService } from "./app.service";
import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
//import { StatusBar } from "@ionic-native/status-bar/ngx";
import { environment } from "../environments/environment";
import firebase from "firebase";
import { Router } from "@angular/router";
import { AppTrackingTransparency } from "capacitor-plugin-app-tracking-transparency";
import { DatabaseProvider } from "../../database";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import * as platdev from "@ionic-native/device/ngx";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    //private statusBar: StatusBar,
    private router: Router,
    private platform: Platform,
    private _dbProvider: DatabaseProvider,
    private _device: platdev.Device,
    private _appService: AppService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      firebase.initializeApp(environment.firebaseConfig);
      firebase.analytics();

      // this.statusBar.overlaysWebView(true);
      //  this.statusBar.backgroundColorByHexString("#ffffff");
      //this.statusBar.styleDefault();

      // this.splashScreen.hide();
      // Criando o banco de dados
      if (this.platform.is("capacitor")) {
        this._dbProvider
          .createDatabase()
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        if (this.platform.is("ios")) {
          try {
            const response = await AppTrackingTransparency.getStatus();
            if (response.status === "notDetermined") {
              await AppTrackingTransparency.requestPermission();
            }
          } catch (e) {
            console.log(e);
          }
        }

        this.platform.backButton.subscribeWithPriority(10, () => {
          this.router.navigateByUrl("..");
        });

        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === "granted") {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register().then().catch();
          } else {
            // Show some error
          }
        });

        // On success, we should be able to receive notifications
        PushNotifications.addListener("registration", (token: Token) => {
          localStorage.setItem("notificationToken", token.value);
          const user = JSON.parse(localStorage.getItem("Leitebem/dataUser"));
          this._appService
            .sendNotificationToken({
              user: user && user.id ? user.id : null,
              token: token.value,
              deviceId: this._device.uuid,
            })
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener("registrationError", (error: any) => {
          console.log("Error on registration: " + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification: PushNotificationSchema) => {
            console.log("Push received: " + JSON.stringify(notification));
          }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification: ActionPerformed) => {
            console.log(
              "Push action performed: " + JSON.stringify(notification)
            );
          }
        );
      }
    });
  }
}
