import { HomeService } from './../../landing/home/home.service';
import { AddAnimaisComponent } from './../../animais/add-animais/add-animais.component';
import { GestaoRebanhoService } from './../gestao-rebanho/gestao-rebanho.service';
import { AnimaisService } from './../../animais/animais.service';
import { SelectComponent } from './../components/select/select.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, NgZone } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import * as moment from 'moment';
import { closeAllModals } from "app/app.service";

@Component({
  selector: 'app-dashboard-modal',
  templateUrl: './dashboard-modal.component.html',
  styleUrls: ['./dashboard-modal.component.scss'],
})
export class DashboardModalComponent implements OnInit {

  form: FormGroup;
  array = new Array(0)
  filteredArray = new Array(0)
  data;
  step = 1;
  indexes = [];
  registers = [];
  bulls = [];
  maxDate = new Date();

  constructor(
    private modalController: ModalController,
    private _formBuilder: FormBuilder,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private _animaisService: AnimaisService,
    public ngZone: NgZone,
    private _gestaoRebanhoService: GestaoRebanhoService,
    private _homeService: HomeService,
    public platform: Platform
  ) { }

  ngOnInit() {
    this.form = this._formBuilder.group({
      animal: ['', [Validators.required]],
      register: ['', [Validators.required]],
      idBull: [''],
      date: ['', [Validators.required]],
    });

    const animais = JSON.parse(localStorage.getItem('animaisFazenda'))
    this.bulls = animais.filter((row) => row.sex === "M")
      .map(function (row) {
        return {
          value: row.id,
          label: `${row.identification} | ${row.name}`,
        };
      });
    switch (this.data.key) {
      case 'secagensatrasadas':
        this.array = this.data.animalsDryingNextSixtyDays;
        this.filteredArray = this.data.animalsDryingNextSixtyDays;
        this.registers = [
          {
            value: 'abortoComLactacao',
            label: 'Aborto com Lactação',
          },
          {
            value: 'secagem',
            label: 'Secagem',
          },
        ];
        break;
      case 'proximassecagens':
        this.array = this.data.animalsDryingNextNinetyDays;
        this.filteredArray = this.data.animalsDryingNextNinetyDays;
        this.registers = [
          {
            value: 'abortoComLactacao',
            label: 'Aborto com Lactação',
          },
          {
            value: 'secagem',
            label: 'Secagem',
          },
        ];
        break;
      case 'partosprevistos':
        this.array = this.data.animalsPregnantNextSixtyDays;
        this.filteredArray = this.data.animalsPregnantNextSixtyDays;
        this.registers = [
          {
            value: 'abortoComLactacao',
            label: 'Aborto com Lactação',
          },
          {
            value: 'abortoSemLactacao',
            label: 'Aborto sem Lactação',
          },
          {
            value: 'parto',
            label: 'Parto',
          },
        ];
        break;
      case 'vacasatrasadas':
        this.array = this.data.correctOrderDelayed;
        this.filteredArray = this.data.correctOrderDelayed;
        this.registers = [
          {
            value: 'cio',
            label: 'Cio',
          },
          {
            value: 'cobertura',
            label: 'Cobertura',
          },
        ];
        break;
      case 'aptasparacobertura':
        this.array = this.data.correctOrderAbled;
        this.filteredArray = this.data.correctOrderAbled;
        this.registers = [
          {
            value: 'cio',
            label: 'Cio',
          },
          {
            value: 'cobertura',
            label: 'Cobertura',
          },
        ];
        break;
      case 'realizardiagnostico':
        this.array = this.data.coveredAnimals;
        this.filteredArray = this.data.coveredAnimals;
        this.registers = [
          {
            value: 'cio',
            label: 'Cio',
          },
          {
            value: 'novaCobertura',
            label: 'Nova Cobertura',
          },
          {
            value: 'palpacaoPositiva',
            label: 'Palpação Positiva',
          },
          {
            value: 'palpacaoNegativa',
            label: 'Palpação Negativa',
          },
        ];
        break;
      case 'entregasnomesatual':
        this.array = this.data.entregasMesdeliveriesAll
        this.filteredArray = this.data.entregasMesdeliveriesAll
        break;
      case 'rankingdepesagens':
        this.array = this.data.weightAnimals
        this.filteredArray = this.data.weightAnimals
        break;
      case 'bezerras':
        this.array = this.data.herdCalvesAndHeifers
        this.filteredArray = this.data.herdCalvesAndHeifers
        break;
      case 'novilhas':
        this.array = this.data.allHeifers
        this.filteredArray = this.data.allHeifers
        break;
      case 'vacasLactacao':
        this.array = this.data.herdLacting
        this.filteredArray = this.data.herdLacting
        break;
      case 'vacasSecas':
        this.array = this.data.herdDry
        this.filteredArray = this.data.herdDry
        break;
      case 'bezerros':
        this.array = this.data.herdMale10
        this.filteredArray = this.data.herdMale10
        break;
      case 'novilhos':
        this.array = this.data.herdMale11
        this.filteredArray = this.data.herdMale11
        break;
      case 'touros':
        this.array = this.data.herdMale12
        this.filteredArray = this.data.herdMale12
        break;
      case 'rufioes':
        this.array = this.data.herdMale13
        this.filteredArray = this.data.herdMale13
        break;
      case 'animaisMortos':
        this.array = this.data.animalsDead
        this.filteredArray = this.data.animalsDead
        break;
      case 'animalsSold':
        this.array = this.data.animalsSold
        this.filteredArray = this.data.animalsSold
        break;
      case 'prenhas':
        this.array = this.data.prenhas
        this.filteredArray = this.data.prenhas
        break;
      case 'vazias':
        this.array = this.data.vazias
        this.filteredArray = this.data.vazias
        break;
      case 'animaiscomcobertura':
        this.array = this.data.bezerrasNovilhasCobertas
        this.filteredArray = this.data.bezerrasNovilhasCobertas
        break;
      case 'bezerrasnovilhassemcobertura':
        this.array = this.data.bezerrasNovilhasSemCobertas
        this.filteredArray = this.data.bezerrasNovilhasSemCobertas
        break;
    }

    if (this.data.animal) {
      this.form.controls.animal.patchValue(this.data.animal);
      switch (this.data.animal.idCurrentStages) {
        case 1: case 7:
          this.registers = [
            {
              value: 'cio',
              label: 'Cio',
            },
            {
              value: 'cobertura',
              label: 'Cobertura',
            },
          ];
          break;
        case 2: case 8:
          this.registers = [
            {
              value: 'cio',
              label: 'Cio',
            },
            {
              value: 'novaCobertura',
              label: 'Nova Cobertura',
            },
            {
              value: 'palpacaoPositiva',
              label: 'Palpação Positiva',
            },
            {
              value: 'palpacaoNegativa',
              label: 'Palpação Negativa',
            },
          ];
          break;
        case 3: case 9:
          this.registers = [
            {
              value: 'abortoComLactacao',
              label: 'Aborto com Lactação',
            },
            {
              value: 'abortoSemLactacao',
              label: 'Aborto sem Lactação',
            },
            {
              value: 'parto',
              label: 'Parto',
            },
          ];
          break;
        case 4:
          this.registers = [
            {
              value: 'cio',
              label: 'Cio',
            },
            {
              value: 'cobertura',
              label: 'Cobertura',
            },
            {
              value: 'secagem',
              label: 'Secagem',
            },
          ];
          break;
        case 5:
          this.registers = [
            {
              value: 'cio',
              label: 'Cio',
            },
            {
              value: 'novaCobertura',
              label: 'Nova Cobertura',
            },
            {
              value: 'palpacaoPositiva',
              label: 'Palpação Positiva',
            },
            {
              value: 'palpacaoNegativa',
              label: 'Palpação Negativa',
            },
            {
              value: 'secagem',
              label: 'Secagem',
            },
          ];
          break;
        case 6:
          this.registers = [
            {
              value: 'abortoComLactacao',
              label: 'Aborto com Lactação',
            },
            {
              value: 'secagem',
              label: 'Secagem',
            },
          ];
          break;
      }
    }

    if (this.data.step) {
      this.step = this.data.step;
    }

  };

  includes(idx) {
    this.indexes.find(e => e === idx);
  }

  set(control) {
    if (this.step === 1)
      this.form.controls.animal.patchValue(control);
    if (this.step === 2)
      this.form.controls.register.patchValue(control);
    if (this.step === 3)
      this.form.controls.date.patchValue(control);
    this.step++;
  }

  unset() {
    if (this.data.step && (this.step - 1) === 1)
      this.dismiss();
    this.step--;
    if (this.step === 1)
      this.form.reset();
  }

  reg(reg) {
    return this.registers.find(e => e.value === reg)?.label
  }

  async select(control, type, title) {
    console.log(this[type])
    const modal = await this.modalController.create({
      component: SelectComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: this[type],
        title
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.form.controls[control].patchValue(data);
    }
  }

  filter(e) {
    this.ngZone.run(() => {
      if (this.data.key === 'animaisMortos' || this.data.key === 'animalsSold') {
        this.filteredArray = this.array.filter(item =>
          (item.animal && item.animal.identification && item.animal.identification.toUpperCase().includes(e.toUpperCase())) ||
          (item.animal && item.animal.name && item.animal.name.toUpperCase().includes(e.toUpperCase()))
        );
      } else {
        this.filteredArray = this.array.filter(item =>
          (item.animal && item.animal.toUpperCase().includes(e.toUpperCase())) ||
          (item.name && item.name.toUpperCase().includes(e.toUpperCase())) ||
          (item.identification && item.identification.toUpperCase().includes(e.toUpperCase())) ||
          (item.dataEntrega && item.dataEntrega.split('-')[2].split('T')[0].toUpperCase().includes(e.toUpperCase()))
        );
      }
    });
  }

  async resetSold(card) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Atenção',
      message: 'Tem certeza que deseja desfazer a venda desse animal?',
      buttons: [
        {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Por Favor Aguarde',
            });
            await loading.present();
            this._gestaoRebanhoService.resetSold(card.id, card.idAnimal)
              .then(async () => {
                await this._homeService.getAnimalsSold().toPromise();
                const indexFilteredArray = this.filteredArray.findIndex(a => a.id === card.id);
                if (indexFilteredArray > -1)
                  this.filteredArray.splice(indexFilteredArray, 1);
                const indexArray = this.array.findIndex(a => a.id === card.id);
                if (indexArray > -1)
                  this.array.splice(indexArray, 1);
                const alert = await this.alertController.create({
                  header: 'Feito!',
                  message: `Venda desfeita com sucesso!`,
                  buttons: ['OK']
                });
                loading.dismiss();
                await alert.present();
              }).catch(async err => {
                console.log(err);
                const alert = await this.alertController.create({
                  header: 'Ops...',
                  message: `Erro ao desfazer venda. Tente novamente mais tarde.`,
                  buttons: ['OK']
                });
                loading.dismiss();
                await alert.present();
              })
          }
        },
        {
          text: 'Cancelar'
        }
      ]
    });
    return await alert.present();
  }

  async resetDead(card) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Atenção',
      message: 'Tem certeza que deseja desfazer a morte desse animal?',
      buttons: [
        {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Por Favor Aguarde',
            });
            await loading.present();
            this._gestaoRebanhoService.resetDead(card.id, card.idAnimal)
              .then(async () => {
                await this._homeService.getAnimalsDead().toPromise();
                const indexFilteredArray = this.filteredArray.findIndex(a => a.id === card.id);
                if (indexFilteredArray > -1)
                  this.filteredArray.splice(indexFilteredArray, 1);
                const indexArray = this.array.findIndex(a => a.id === card.id);
                if (indexArray > -1)
                  this.array.splice(indexArray, 1);
                const alert = await this.alertController.create({
                  header: 'Feito!',
                  message: `Morte desfeita com sucesso!`,
                  buttons: ['OK']
                });
                loading.dismiss();
                await alert.present();
              }).catch(async err => {
                console.log(err);
                const alert = await this.alertController.create({
                  header: 'Ops...',
                  message: `Erro ao desfazer morte. Tente novamente mais tarde.`,
                  buttons: ['OK']
                });
                loading.dismiss();
                await alert.present();
              })
          }
        },
        {
          text: 'Cancelar'
        }
      ]
    });
    return await alert.present();
  }

  async confirm() {
    // loading
    const loading = await this.loadingController.create({
      message: 'Por Favor Aguarde',
    });
    await loading.present();

    try {
      let newStage;
      const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");

      if (this.form.value.animal.idCurrentStages === 1) {
        if (this.form.value.register === "cobertura") {
          newStage = 2;
        } else if (this.form.value.register === "cio") {
          newStage = 1;
        }
      }

      if (this.form.value.animal.idCurrentStages === 2) {
        if (this.form.value.register === "cio") {
          newStage = 2;
        } else if (this.form.value.register === "novaCobertura") {
          newStage = 2;
        } else if (this.form.value.register === "palpacaoPositiva") {
          newStage = 3;
        } else if (this.form.value.register === "palpacaoNegativa") {
          newStage = 1;
        }
      }

      if (this.form.value.animal.idCurrentStages === 3) {
        if (
          this.form.value.register === "parto" ||
          this.form.value.register === "abortoComLactacao"
        ) {
          newStage = 4;
        } else if (this.form.value.register === "abortoSemLactacao") {
          newStage = 1;
        }
      }

      if (this.form.value.animal.idCurrentStages === 4) {
        if (this.form.value.register === "cio") {
          newStage = 4;
        } else if (this.form.value.register === "cobertura") {
          newStage = 5;
        } else if (this.form.value.register === "secagem") {
          newStage = 7;
        }
      }

      if (this.form.value.animal.idCurrentStages === 5) {
        if (this.form.value.register === "cio") {
          newStage = 5;
        } else if (this.form.value.register === "novaCobertura") {
          newStage = 5;
        } else if (this.form.value.register === "palpacaoPositiva") {
          newStage = 6;
        } else if (this.form.value.register === "palpacaoNegativa") {
          newStage = 4;
        } else if (this.form.value.register === "secagem") {
          newStage = 8;
        }
      }

      if (this.form.value.animal.idCurrentStages === 6) {
        if (this.form.value.register === "abortoComLactacao") {
          newStage = 4;
        } else if (this.form.value.register === "secagem") {
          newStage = 9;
        }
      }

      if (this.form.value.animal.idCurrentStages === 7) {
        if (this.form.value.register === "cio") {
          newStage = 7;
        } else if (this.form.value.register === "cobertura") {
          newStage = 8;
        }
      }

      if (this.form.value.animal.idCurrentStages === 8) {
        if (this.form.value.register === "cio") {
          newStage = 8;
        } else if (this.form.value.register === "novaCobertura") {
          newStage = 8;
        } else if (this.form.value.register === "palpacaoPositiva") {
          newStage = 9;
        } else if (this.form.value.register === "palpacaoNegativa") {
          newStage = 7;
        }
      }

      if (this.form.value.animal.idCurrentStages === 9) {
        if (this.form.value.register === "parto" || this.form.value.register === "abortoComLactacao") {
          newStage = 4;
        } else if (this.form.value.register === "abortoSemLactacao") {
          newStage = 7;
        } else if (this.form.value.register === "secagem") {
          newStage = 9;
        }
      }

      this._animaisService.estagiosReproducaoAdd({
        idAnimal: this.form.value.animal.idAnimal || this.form.value.animal.id,
        PropriedadeId: propriedade.idPropriedade,
        currentStage: "new",
        idCurrentStages: newStage,
        register: this.form.value.register,
        dateRegister: moment(this.form.value.date).format('YYYY-MM-DD'),
        idBull: this.form.value.idBull || null,
      }).then(async res => {
        await this._gestaoRebanhoService.getAnimaisFazenda().toPromise();
        let idBull = null;
        if (this.form.value.register === "parto") {
          const stagesAnimal = await this._gestaoRebanhoService.getAnimaisEstagios(this.form.value.animal.id || this.form.value.animal.idAnimal);
          console.log(stagesAnimal)
          if (stagesAnimal && stagesAnimal[0] && stagesAnimal[0].estagio) {
            for (const stage of stagesAnimal[0].estagio) {
              if (['cobertura', 'novaCobertura'].includes(stage.register)) {
                idBull = stage.idBull;
                break;
              }
            }
          }
        }

        const dataPesagem =
          localStorage.getItem('dataPesagem') ?
            JSON.parse(localStorage.getItem('dataPesagem')) :
            moment().format('YYYY-MM-DD');
        localStorage.setItem('dataPesagem', JSON.stringify(dataPesagem));

        const promises = [
          this._homeService.getAnimalsDead().toPromise(),
          this._homeService.getDashboardControlStage().toPromise(),
          this._homeService.getDashboardPregnant().toPromise(),
          this._homeService.getDashboardDrying().toPromise(),
          this._homeService.getDashboardDelayed().toPromise(),
          this._homeService.getCoveredAnimals().toPromise(),
          this._homeService.getDashboardWeights().toPromise(),
          this._homeService.getDashboardWeighingsAverage().toPromise(),
          this._homeService.getDashboardRecipe().toPromise(),
          this._homeService.getEntregasMes().toPromise(),
          this._homeService.getDashboardLastDeliveries().toPromise(),
          this._homeService.getPesagensLeiteIdPropriedade().toPromise(),
          this._homeService.getDetailsAnimalByPeriodAllDay(moment(dataPesagem).format('YYYY-MM-DD'))
        ];

        await Promise.all(promises);

        loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Feito!',
          message: `Registro realizado para animal ${this.form.value.animal.animal || this.form.value.animal.identification + ' | ' + this.form.value.animal.name}`,
          buttons: ['OK']
        });

        await alert.present();

        const { role } = await alert.onDidDismiss();

        this.modalController.dismiss();
        console.log()
        if (this.form.value.register === "parto") {
          const animais = JSON.parse(localStorage.getItem('animaisFazenda'))
          const mon = animais.find(a => a.id === this.form.value.animal.id || this.form.value.animal.idAnimal);
          const dad = animais.find(a => a.id === idBull);
          const modal = await this.modalController.create({
            component: AddAnimaisComponent,
            cssClass: 'my-custom-class',
            componentProps: {
              data: {
                mon: this.form.value.animal.id || this.form.value.animal.idAnimal,
                monName: mon ? mon.name : "",
                dad: idBull,
                dadName: dad ? dad.name : "",
                sex: 'F',
                stage: 1,
                dateBirth: this.form.value.date
              },
              parto: true
            }
          });
          await modal.present();
        }
      }).catch(async err => {
        console.log(err);
        loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Ops...',
          message: `Ocorreu um erro. Tente novamente mais tarde.`,
          buttons: ['OK']
        });

        await alert.present();
      });
    } catch (error) {
      console.log(error);
      loading.dismiss();
      const alert = await this.alertController.create({
        header: 'Ops...',
        message: `Ocorreu um erro. Tente novamente mais tarde.`,
        buttons: ['OK']
      });

      await alert.present();
    }
  }

  dismiss() {
    if (this.data.step && (this.step - 1) === 1)
      this.modalController.dismiss();
    this.step--;
    if (this.step === 3)
      this.form.controls.date.patchValue('');
    if (this.step === 1)
      this.form.reset();
    if (this.step === 0)
      this.modalController.dismiss();
  }

  format(number) {
    return (number ? number.toLocaleString({ minimumFractionDigits: 3 }) : "0,000") + " Litro(s)";
  }

  backToHome() {
    closeAllModals();
  }

}
