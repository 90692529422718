import { HomeService } from './../../landing/home/home.service';
import { GestaoRebanhoService } from './../../modals/gestao-rebanho/gestao-rebanho.service';
import { AnimaisService } from './../animais.service';
import { CalendarComponent } from './../../modals/components/calendar/calendar.component';
import { ModalController, AlertController, Platform } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { closeAllModals } from "app/app.service";
@Component({
  selector: 'app-morte',
  templateUrl: './morte.component.html',
  styleUrls: ['./morte.component.scss'],
})
export class MorteComponent implements OnInit {

  form: FormGroup;
  data;
  animal;
  isMobile: boolean = false;

  constructor(
    private modalController: ModalController,
    private _formBuilder: FormBuilder,
    private _service: AnimaisService,
    private alertController: AlertController,
    private _gestaoRebanhoService: GestaoRebanhoService,
    private _homeService: HomeService,
    public platform: Platform
  ) { }

  ngOnInit() {
    this.platform.ready().then(async () => {
      if (this.platform.is('mobile') || this.platform.is('mobileweb')) {
        // this.isMobile = true;
      }
    });

    this.form = this._formBuilder.group({
      date    : [new Date()],
    });

    if (!this.data.animal && !this.data.animal.idAnimal)
      this.dismiss();

    this.animal = this.data.animal;
  }

  async calendar(control, title) {
    const modal = await this.modalController.create({
      component: CalendarComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: {
          date: this.form.value[control],
        },
        title
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.form.controls[control].patchValue(data);
    }
  }

  async create() {
    this.form.disable();;
    if (!this.form.value.date) {
      this.form.enable();
      const alert = await this.alertController.create({
        header: 'Ops!',
        message: 'É necessário informar a data da morte',
        buttons: ['OK']
      });
      await alert.present();
      return;
    }
    this._service.animalsDead({
      date: moment(this.form.value.date).format('YYYY-MM-DD'),
      idAnimal: this.animal.id || this.animal.idAnimal,
    }).then(async res => {
      console.log(res);
      await this._gestaoRebanhoService.getAnimaisFazenda().toPromise();
      await this._homeService.getDashboardControlStage().toPromise();
      await this._homeService.getAnimalsDead().toPromise();
      this.form.enable();
      const alert = await this.alertController.create({
        header: 'Feito!',
        message: 'Morte do animal salva!',
        buttons: ['OK']
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      this.modalController.dismiss(true);
    }).catch(async err => {
      console.log(err);
      this.form.enable();
      const alert = await this.alertController.create({
        header: 'Ops!',
        message: 'Erro ao salvar morte do animal. Tente novamente mais tarde',
        buttons: ['OK']
      });
      await alert.present();
    })
  }

  dismiss() {
    this.modalController.dismiss();
  }

  backToHome() {
    closeAllModals();
  }

}
