import { EmployeesResolvers } from './modules/employees/employees.resolvers';
import { EstadosResolvers, TermResolvers } from './modules/profile/profile.resolvers';
import { PropriedadesResolvers } from './modules/home/property/property.resolvers';
import { PesagensTotalResolvers } from './modules/home/pesagem-leite/pesagem-leite.resolvers';
import { GestaoRebanhoResolvers, BreedsResolvers, RbConfigResolvers } from './modules/modals/gestao-rebanho/home.resolvers';
import { DashboardDryingResolvers, DashboardPregnantResolvers, DashboardControlStageResolvers, DashboardDelayedResolvers, CoveredAnimalsResolvers, DashboardWeightsResolvers, DashboardWeighingsAverageResolvers, EntregasMesResolvers, DashboardLastDeliveriesResolvers, TotalEntregasResolvers, DetailsAnimalByPeriodResolvers, DashboardRecipeResolvers, AnimaisLactantesResolvers, TotalEntregasFaturadasResolvers, HelpersFarmResolvers, PesagensLeiteIdPropriedadeResolvers, AnimalsDeadResolvers, AnimalsSoldResolvers, QualidadeLeiteResolvers, AnimaisCobertosNaoCobertosResolvers } from './modules/landing/home/home.resolvers';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { NgModule } from "@angular/core";
import { InitialDataResolver } from "./app.resolvers";
import { DairyResolvers } from './modules/home/entrega-leite/dairy.resolvers';
import { AllPropriedadesResolvers } from './modules/propriedades/propriedades.resolvers';
import { GetAllVideosResolvers } from './modules/videos-de-ajuda/videos-de-ajuda.resolvers';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
    },
    children: [{
      path: 'home',
      resolve: {
        propriedades: PropriedadesResolvers,
        data: InitialDataResolver,
        dashboardDrying: DashboardDryingResolvers,
        dashboardPregnant: DashboardPregnantResolvers,
        dashboardControl: DashboardControlStageResolvers,
        dashboardDelayed: DashboardDelayedResolvers,
        coveredAnimals: CoveredAnimalsResolvers,
        dashboardWeights: DashboardWeightsResolvers,
        gestaoRebanho: GestaoRebanhoResolvers,
        dashboardWeighingsAverage: DashboardWeighingsAverageResolvers,
        dashboardRecipe: DashboardRecipeResolvers,
        entregasMes: EntregasMesResolvers,
        dashboardLastDeliveries: DashboardLastDeliveriesResolvers,
        totalEntregas: TotalEntregasResolvers,
        totalEntregasFaturadas: TotalEntregasFaturadasResolvers,
        pesagensTotal: PesagensTotalResolvers,
        detailsAnimalByPeriod: DetailsAnimalByPeriodResolvers,
        animaisLactantes: AnimaisLactantesResolvers,
        estados: EstadosResolvers,
        term: TermResolvers,
        employees: EmployeesResolvers,
        dairy: DairyResolvers,
        helpersFarm: HelpersFarmResolvers,
        allPropriedades: AllPropriedadesResolvers,
        pesagensLeiteIdPropriedade: PesagensLeiteIdPropriedadeResolvers,
        animalsDead: AnimalsDeadResolvers,
        animalsSold: AnimalsSoldResolvers,
        qualidadeLeite: QualidadeLeiteResolvers,
        getAllVideosResolvers: GetAllVideosResolvers,
        breedsResolvers: BreedsResolvers,
        rbConfigResolvers: RbConfigResolvers,
        animaisCobertosNaoCobertosResolvers: AnimaisCobertosNaoCobertosResolvers,
      },
      loadChildren: () => import('app/modules/home/tab1.module').then(m => m.Tab1PageModule)
    }, {
      path: 'transactions',
      loadChildren: () => import('app/modules/transactions/tab2.module').then(m => m.Tab2PageModule)
    }, {
      path: 'profile',
      loadChildren: () => import('app/modules/profile/tab3.module').then(m => m.Tab3PageModule)
    }]
  },

  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: '/home'
  },

  // Auth routes (guest)
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
      { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
      { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
      { path: 'sign-in'/* , resolve: { version: VersionResolver } */, loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
      { path: 'cadastrar', resolve: { estados: EstadosResolvers }, loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
    ]
  },

  // Auth routes (logged in)
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
      { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) },
      { path: 'new-user', loadChildren: () => import('app/modules/auth/new-user/new-user.module').then(m => m.AuthNewUserModule) }
    ]
  },

  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'alterar-senha', loadChildren: () => import('app/modules/profile/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
      { path: 'privacidade', loadChildren: () => import('app/modules/privacy/privacy.module').then(m => m.PrivacyModule) },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
