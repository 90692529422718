import { HomeService } from './../../landing/home/home.service';
import { AnimaisService } from './../animais.service';
import { CalendarComponent } from '../../modals/components/calendar/calendar.component';
import { SelectComponent } from '../../modals/components/select/select.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { GestaoRebanhoService } from '../../modals/gestao-rebanho/gestao-rebanho.service';
import * as moment from 'moment';
import { closeAllModals } from "app/app.service";

@Component({
  selector: 'app-add-animais',
  templateUrl: './add-animais.component.html',
  styleUrls: ['./add-animais.component.scss'],
})
export class AddAnimaisComponent implements OnInit {

  data;
  parto;
  form: FormGroup;
  breeds = [];
  breedsArray = [];
  breedText = '';

  stagesFemales = [
    { value: 1, label: "Bezerra/Novilha" },
    { value: 2, label: "Novilha Coberta" },
    { value: 3, label: "Novilha Prenhe" },
    { value: 4, label: "Vaca em Lactação Vazia" },
    { value: 5, label: "Vaca em Lactação Coberta" },
    { value: 6, label: "Vaca em Lactação Prenhe" },
    { value: 7, label: "Vaca Seca Vazia" },
    { value: 8, label: "Vaca Seca Coberta" },
    { value: 9, label: "Vaca Seca Prenhe" },
    { value: 14, label: "Animais Externos" },
  ];
  stagesFemalesArray = [];
  stagesFemalesText = '';

  stagesMales = [
    { value: 10, label: "Bezerro" },
    { value: 11, label: "Novilho" },
    { value: 12, label: "Touros" },
    { value: 13, label: "Sêmen" },
    { value: 14, label: "Animais Externos" },
  ];
  stagesMalesArray = [];
  stagesMalesText = '';

  animaisFazenda = [];
  animalsMale = [];
  animalsMaleArray = [];
  animalsMaleText = '';
  animalsFemale = [];
  animalsFemaleArray = [];
  animalsFemaleText = '';
  file;
  avatar: any = '';
  isMobile: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private modalController: ModalController,
    private _formBuilder: FormBuilder,
    private _gestaoRebanhoService: GestaoRebanhoService,
    private _animaisService: AnimaisService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private _homeService: HomeService,
    public platform: Platform
  ) { }

  ngOnInit() {
    this.platform.ready().then(async () => {
      if (this.platform.is('mobile') || this.platform.is('mobileweb')) {
        // this.isMobile = true;
      }
    });
    this.form = this._formBuilder.group({
      avatar: [''],
      id: [''],
      sex: [''],
      dateBirth: [new Date(), [Validators.required]],
      name: [''],
      identification: ['', Validators.required],
      breed: [''],
      stage: ['', [Validators.required]],
      dad: [''],
      dadName: [''],
      mon: [''],
      monName: [''],
      dateParto: [new Date()],
      dateCobertura: [new Date()],
      dateSecagem: [new Date()],
      datePesagem: [new Date()],
      peso: [''],
    });

    this.getBreed('');
    this.getStagesFemales('');
    this.getStagesMales('');

    if (this.data && this.data.avatar) {
      this.avatar = this.data.avatar;
    }

    this._gestaoRebanhoService.breeds$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breeds => {
        this.breeds = breeds;
        this.breedsArray = breeds;
      });

    this._gestaoRebanhoService.animaisFazenda$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(animaisFazenda => {
        let lastAnimal = animaisFazenda[animaisFazenda.length - 1];
        this.animaisFazenda = animaisFazenda;
        if (!(this.data && this.data.animal)) {
          this.loadNextIdentification(lastAnimal.identification);
        }
        this.animalsMale = animaisFazenda.filter(animal => animal.sex === 'M')
          .map(animal => {
            return ({
              label: `${animal.identification} | ${animal.name}`,
              value: animal.id
            }
            );
          });
        this.getAnimalsMale('');
        this.animalsFemale = animaisFazenda.filter(animal => animal.sex === 'F')
          .map(animal => {
            return ({
              label: `${animal.identification} | ${animal.name}`,
              value: animal.id
            }
            );
          });
        this.getAnimalsFemale('');
      });

    if (this.parto) {
      this.form.patchValue({
        avatar: 'https://bkleitebem.sistemafaeg.org.br/tmp/uploads/7637acd395f3f789256c02dec7906bb5.jpeg',
        sex: this.data.sex,
        stage: this.data.stage,
        dateBirth: moment(this.data.dateBirth, 'DD/MM/YYYY').toDate(),
        dad: this.data.dad,
        dadName: this.data.dadName,
        mon: this.data.mon,
        monName: this.data.monName,
      })
    }

    if (!this.parto && this.data?.animal) {
      const mon = this.animaisFazenda.find(a => a.id === this.data.animal.idMother);
      const dad = this.animaisFazenda.find(a => a.id === this.data.animal.idDad);
      this.form.patchValue({
        avatar: this.data.animal.avatar,
        id: this.data.animal.id,
        sex: this.data.animal.sex,
        dateBirth: moment(this.data.animal.dateBirth, 'DD/MM/YYYY').toISOString(),
        name: this.data.animal.name,
        identification: this.data.animal.identification,
        breed: this.data.animal.breed,
        dad: this.data.animal.idDad,
        dadName: this.data.animal.dad ? this.data.animal.dad : (dad ? dad.name : ""),
        mon: this.data.animal.idMother,
        monName: this.data.animal.mother ? this.data.animal.mother : (mon ? mon.name : ""),
      })
      if (!this.form.value.avatar) this.form.controls.avatar.patchValue('https://bkleitebem.sistemafaeg.org.br/tmp/uploads/7637acd395f3f789256c02dec7906bb5.jpeg');
    } else {
      this.form.controls.avatar.patchValue('https://bkleitebem.sistemafaeg.org.br/tmp/uploads/7637acd395f3f789256c02dec7906bb5.jpeg');
    }

    const breed = this.breeds.find(e => e.value === this.form.value.breed);
    if (breed)
      this.setBreed(breed);
    const dad = this.animalsMale.find(e => e.value === this.form.value.dad);
    if (dad)
      this.setAnimalsMale(dad);
    const mon = this.animalsFemale.find(e => e.value === this.form.value.mon);
    if (mon)
      this.setAnimalsFemale(mon);
  }

  init() {
    this.form = this._formBuilder.group({
      avatar: [''],
      id: [''],
      sex: [''],
      dateBirth: [new Date(), [Validators.required]],
      name: [''],
      identification: ['', Validators.required],
      breed: [''],
      stage: ['', [Validators.required]],
      dad: [''],
      dadName: [''],
      mon: [''],
      monName: [''],
      dateParto: [new Date()],
      dateCobertura: [new Date()],
      dateSecagem: [new Date()],
      datePesagem: [new Date()],
      peso: [''],
    });
    this.form.controls.avatar.patchValue('https://bkleitebem.sistemafaeg.org.br/tmp/uploads/7637acd395f3f789256c02dec7906bb5.jpeg');
    let lastAnimal = this.animaisFazenda[this.animaisFazenda.length - 1];
    this.loadNextIdentification(lastAnimal.identification);
  }

  async calendar(control, title) {
    const modal = await this.modalController.create({
      component: CalendarComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: {
          date: this.form.value[control],
        },
        title
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.form.controls[control].patchValue(data);
    }
  }

  async select(control, type, title) {
    const modal = await this.modalController.create({
      component: SelectComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: this[type],
        title
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.form.controls[control].patchValue(data);
      if (control === 'mon' || control === 'dad') {
        const animal = this.animaisFazenda.find(a => a.id === data);
        this.form.controls[`${control}Name`].patchValue(animal ? animal.name : "");
      }
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async create(continuar) {
    //this.form.disable();
    // loading
    const loading = await this.loadingController.create({
      message: 'Por Favor Aguarde',
    });
    await loading.present();
    const erros = [];
    const stages = [];
    const propriedade = JSON.parse(localStorage.getItem('Leitebem/dataPropriedade') || "{}");

    if (!this.form.get('identification').valid) {
      erros.push('É obrigatório informar a identificação!')
    }

    if (!(this.data && this.data.animal) && !this.form.get('stage').valid) {
      erros.push('É obrigatório informar o estágio!')
    }

    if (!this.form.get('dateBirth').valid) {
      erros.push('É obrigatório informar a data de nascimento!')
    }

    if (
      !this.form.value.identification ||
      !this.form.value.sex ||
      !propriedade.idPropriedade ||
      (!(this.data && this.data.animal) && !this.form.value.stage) ||
      !this.form.value.dateBirth
    ) {
      const alert = await this.alertController.create({
        header: 'Ops...',
        message: `Preencha todos os campos obrigatórios`,
        buttons: ['OK']
      });
      loading.dismiss();
      await alert.present();
      return;
    }

    if (!(this.data && this.data.animal)) {
      let validatedIdentification = [];
      if (this.animaisFazenda.length > 0) {
        validatedIdentification = this.animaisFazenda.filter(
          (id) => id.identification === this.form.value.identification
        );
      } this.data

      if (validatedIdentification.length > 0) {
        const alert = await this.alertController.create({
          header: 'Ops...',
          message: `Já existe um animal com a identificação ${this.form.value.identification}`,
          buttons: ['OK']
        });
        loading.dismiss();
        await alert.present();
        return;
      }
    }

    if (!moment(this.form.value.dateBirth).isValid()) {
      const alert = await this.alertController.create({
        header: 'Ops...',
        message: `Data de nascimento inválida`,
        buttons: ['OK']
      });
      loading.dismiss();
      await alert.present();
      return;
    }

    if (!(this.data && this.data.animal) && (this.form.value.stage !== 2 && this.form.value.stage !== 3 && this.form.value.stage !== 1)) {
      if (!moment(this.form.value.dateParto).isValid()) {
        const alert = await this.alertController.create({
          header: 'Ops...',
          message: `Data do Parto Inválida!`,
          buttons: ['OK']
        });
        loading.dismiss();
        await alert.present();
        return;
      }
    }

    if (!(this.data && this.data.animal) && (this.form.value.stage !== 4 && this.form.value.stage !== 7 && this.form.value.stage !== 1)) {
      if (!moment(this.form.value.dateCobertura).isValid()) {
        const alert = await this.alertController.create({
          header: 'Ops...',
          message: `Data de Cobertura Inválida!`,
          buttons: ['OK']
        });
        loading.dismiss();
        await alert.present();
        return;
      }
    }

    if (!(this.data && this.data.animal) && this.form.value.stage === 7) {
      if (!moment(this.form.value.dateSecagem).isValid()) {
        const alert = await this.alertController.create({
          header: 'Ops...',
          message: `Data de Secagem Inválida!`,
          buttons: ['OK']
        });
        loading.dismiss();
        await alert.present();
        return;
      }
    }

    if (!(this.data && this.data.animal) && (this.form.value.stage === 2 || this.form.value.stage === 3 || this.form.value.stage === 1)) {
      if (!moment(this.form.value.datePesagem).isValid()) {
        const alert = await this.alertController.create({
          header: 'Ops...',
          message: `Data de Pesagem Inválida!`,
          buttons: ['OK']
        });
        loading.dismiss();
        await alert.present();
        return;
      }
      if (!this.form.value.peso) {
        const alert = await this.alertController.create({
          header: 'Ops...',
          message: `Informe o Peso do Animal`,
          buttons: ['OK']
        });
        loading.dismiss();
        await alert.present();
        return;
      }
    }

    if (erros.length > 0) {
      const alert = await this.alertController.create({
        header: 'Dados incorretos!',
        message: erros.join(' '), //erros.toString(),
        buttons: ['OK']
      });
      loading.dismiss();
      await alert.present();
    } else {
      if (this.form.value.stage === 6) {
        stages.push(
          { currentStage: 4, register: "parto", date: moment(this.form.value.dateParto).format('YYYY-MM-DD') },
          { currentStage: 5, register: "cobertura", date: moment(this.form.value.dateCobertura).format('YYYY-MM-DD') },
          {
            currentStage: 6,
            register: "palpacaoPositiva",
            date: moment(this.form.value.dateCobertura).format('YYYY-MM-DD'),
          }
        );
      } else if (this.form.value.stage === 9) {
        stages.push(
          { currentStage: 4, register: "parto", date: moment(this.form.value.dateParto).format('YYYY-MM-DD') },
          { currentStage: 7, register: "secagem", date: moment(this.form.value.dateParto).format('YYYY-MM-DD') },
          { currentStage: 8, register: "cobertura", date: moment(this.form.value.dateCobertura).format('YYYY-MM-DD') },
          {
            currentStage: 9,
            register: "palpacaoPositiva",
            date: moment(this.form.value.dateCobertura).format('YYYY-MM-DD'),
          }
        );
      } else if (this.form.value.stage === 5) {
        stages.push(
          { currentStage: 4, register: "parto", date: moment(this.form.value.dateParto).format('YYYY-MM-DD') },
          { currentStage: 5, register: "cobertura", date: moment(this.form.value.dateCobertura).format('YYYY-MM-DD') }
        );
      } else if (this.form.value.stage === 8) {
        stages.push(
          { currentStage: 4, register: "parto", date: moment(this.form.value.dateParto).format('YYYY-MM-DD') },
          { currentStage: 7, register: "secagem", date: moment(this.form.value.dateParto).format('YYYY-MM-DD') },
          { currentStage: 8, register: "cobertura", date: moment(this.form.value.dateCobertura).format('YYYY-MM-DD') }
        );
      } else if (this.form.value.stage === 4) {
        stages.push({
          currentStage: 4,
          register: "parto",
          date: moment(this.form.value.dateParto).format('YYYY-MM-DD'),
        });
      } else if (this.form.value.stage === 7) {
        stages.push(
          { currentStage: 4, register: "parto", date: moment(this.form.value.dateParto).format('YYYY-MM-DD') },
          { currentStage: 7, register: "secagem", date: moment(this.form.value.dateSecagem).format('YYYY-MM-DD') }
        );
      } else if (this.form.value.stage === 2) {
        stages.push({
          currentStage: 2,
          register: "cobertura",
          date: moment(this.form.value.dateCobertura).format('YYYY-MM-DD'),
        });
      } else if (this.form.value.stage === 3) {
        stages.push(
          { currentStage: 2, register: "cobertura", date: moment(this.form.value.dateCobertura).format('YYYY-MM-DD') },
          {
            currentStage: 3,
            register: "palpacaoPositiva",
            date: moment(this.form.value.dateCobertura).format('YYYY-MM-DD'),
          }
        );
      } else if (this.form.value.stage === 1) {
        stages.push({
          currentStage: 1,
          register: "birthDay",
          date: moment(this.form.value.dateBirth).format('YYYY-MM-DD'),
        });
      } else if (this.form.value.stage === 10) {
        stages.push({
          currentStage: 10,
          register: "male",
          date: moment(this.form.value.dateBirth).format('YYYY-MM-DD'),
        });
      } else if (this.form.value.stage === 11) {
        stages.push({
          currentStage: 11,
          register: "male",
          date: moment(this.form.value.dateBirth).format('YYYY-MM-DD'),
        });
      } else if (this.form.value.stage === 12) {
        stages.push({
          currentStage: 12,
          register: "male",
          date: moment(this.form.value.dateBirth).format('YYYY-MM-DD'),
        });
      } else if (this.form.value.stage === 13) {
        stages.push({
          currentStage: 13,
          register: "male",
          date: moment(this.form.value.dateBirth).format('YYYY-MM-DD'),
        });
      } else if (this.form.value.stage === 14) {
        stages.push({
          currentStage: 14,
          register: "external",
          date: moment(this.form.value.dateBirth).format('YYYY-MM-DD'),
        });
      }

      if (this.data && this.data.animal) {
        const formData: FormData = new FormData();
        // if (this.file) formData.append("image", this.file, "image");
        formData.append("avatar", this.avatar);
        formData.append("periodo", this.form.value.sex);
        formData.append("nName", this.form.value.name);
        formData.append("nIdentification", this.form.value.identification);
        formData.append("name", this.form.value.name);
        formData.append("identification", this.form.value.identification);
        formData.append("dateBirth", this.form.value.dateBirth);
        formData.append("dad", this.form.value.dadName === "" || this.form.value.dadName === undefined ? "" : this.form.value.dadName);
        formData.append("mother", this.form.value.monName === "" || this.form.value.monName === undefined ? "" : this.form.value.monName);
        formData.append("idDad", this.form.value.dad === "" || this.form.value.dad === undefined ? null : this.form.value.dad);
        formData.append("idMother", this.form.value.mon === "" || this.form.value.mon === undefined ? null : this.form.value.mon);
        formData.append("breed", this.form.value.breed);
        formData.append("sex", this.form.value.sex);
        formData.append("PropriedadeId", propriedade.idPropriedade);
        formData.append("animalId", this.data.animal.idAnimal);

        this._animaisService.update(formData)
          .then(async res => {
            try {
              await Promise.all([
                this._gestaoRebanhoService.getAnimaisFazenda().toPromise(),
                this._gestaoRebanhoService.getAnimal(this.data.animal.idAnimal).toPromise(),
                this._homeService.getAnimalsDead().toPromise(),
                this._homeService.getAnimalsSold().toPromise(),
                this._homeService.getDashboardControlStage().toPromise(),
                this._homeService.getDashboardPregnant().toPromise(),
                this._homeService.getDashboardDrying().toPromise(),
                this._homeService.getDashboardDelayed().toPromise(),
                this._homeService.getCoveredAnimals().toPromise(),
                this._homeService.getDashboardWeights().toPromise(),
                this._homeService.getDashboardWeighingsAverage().toPromise(),
                this._homeService.getDashboardRecipe().toPromise(),
                this._homeService.getEntregasMes().toPromise(),
                this._homeService.getDashboardLastDeliveries().toPromise(),
                this._gestaoRebanhoService.getAnimalPhoto(this.data.animal.idAnimal)
              ]);
            } catch (error) {
              console.log(error);
            }
            const dataPesagem = JSON.parse(localStorage.getItem('dataPesagem') || JSON.stringify(new Date()));
            await this._homeService.getDetailsAnimalByPeriodAllDay(dataPesagem);
            loading.dismiss();

            const alert = await this.alertController.create({
              header: 'Feito!',
              message: `Animal atualizado`,
              buttons: ['OK']
            });

            await alert.present();

            const { role } = await alert.onDidDismiss();
            this.dismiss();
          }).catch(async err => {
            console.log(err);
            this.form.enable();
            loading.dismiss();
            const alert = await this.alertController.create({
              header: 'Ops...',
              message: `Erro ao atualizar. Tente novamente.`,
              buttons: ['OK']
            });

            await alert.present();
            const { role } = await alert.onDidDismiss();
          })
        return;
      }

      const formData: FormData = new FormData();
      // if (this.file) formData.append("image", this.file, "image");
      formData.append("avatar", this.avatar);
      formData.append("name", this.form.value.name);
      formData.append("identification", this.form.value.identification);
      formData.append("dateBirth", this.form.value.dateBirth);
      formData.append("dad", this.form.value.dadName === "" || this.form.value.dadName === undefined ? "" : this.form.value.dadName);
      formData.append("mother", this.form.value.monName === "" || this.form.value.monName === undefined ? "" : this.form.value.dadName);
      formData.append("idDad", this.form.value.dad === "" ? null : this.form.value.dad);
      formData.append("idMother", this.form.value.mon === "" ? null : this.form.value.mon);
      formData.append("sex", this.form.value.sex);
      formData.append("breed", this.form.value.breed);
      formData.append("stages", JSON.stringify(stages));
      formData.append("dateWeight", moment(this.form.value.datePesagem).format('YYYY-MM-DD'));
      formData.append("weight", this.form.value.peso);
      formData.append("PropriedadeId", propriedade.idPropriedade);

      this._animaisService.create(formData)
        .then(async res => {
          try {
            const promises = [
              this._gestaoRebanhoService.getAnimaisFazenda().toPromise(),
              this._homeService.getAnimalsDead().toPromise(),
              this._homeService.getAnimalsSold().toPromise(),
              this._homeService.getDashboardControlStage().toPromise(),
              this._homeService.getDashboardPregnant().toPromise(),
              this._homeService.getDashboardDrying().toPromise(),
              this._homeService.getDashboardDelayed().toPromise(),
              this._homeService.getCoveredAnimals().toPromise(),
              this._homeService.getDashboardWeights().toPromise(),
              this._homeService.getDashboardWeighingsAverage().toPromise(),
              this._homeService.getDashboardRecipe().toPromise(),
              this._homeService.getEntregasMes().toPromise(),
              this._homeService.getDashboardLastDeliveries().toPromise(),
            ];
            if (res && res.dados)
              promises.push(this._gestaoRebanhoService.getAnimalPhoto(res?.dados?.id))
            await Promise.all(promises);
          } catch (error) {
            console.log(error);
          }
          const dataPesagem = JSON.parse(localStorage.getItem('dataPesagem') || JSON.stringify(new Date()));
          await this._homeService.getDetailsAnimalByPeriodAllDay(dataPesagem);

          loading.dismiss();

          const alert = await this.alertController.create({
            header: 'Feito!',
            message: `Cadastro realizado`,
            buttons: ['OK']
          });
          await alert.present();
          const { role } = await alert.onDidDismiss();
          if (!continuar)
            this.dismiss();

          this.init();
        }).catch(async err => {
          console.log(err);
          this.form.enable();
          loading.dismiss();
          const alert = await this.alertController.create({
            header: 'Ops...',
            message: `Erro no cadastro. Tente novamente.`,
            buttons: ['OK']
          });

          await alert.present();
        })
    }
  }

  loadNextIdentification(lastId: string): void {
    let numberLastId = Number(lastId);
    if (Number.isNaN(numberLastId)) {
      this.form.controls['identification'].setValue('');
      return;
    }
    let nextId = '';
    if (numberLastId > 0 && numberLastId <= 8) {
      nextId = `00${numberLastId + 1}`;
    } else if (numberLastId === 9) {
      nextId = "010";
    } else if (numberLastId > 9 && numberLastId <= 98) {
      nextId = `0${numberLastId + 1}`;
    } else if (numberLastId === 99) {
      nextId = "100";
    } else {
      nextId = `${numberLastId + 1}`;
    }
    this.form.controls['identification'].setValue(nextId);
  }

  /**
   * Upload avatar
   *
   * @param fileList
   */
  async uploadAvatar(fileList: FileList) {
    if (!fileList.length)
      return;
    const allowedTypes = ['image/jpeg', 'image/png'];
    const file = fileList[0];
    if (!allowedTypes.includes(file.type)) {
      const alert = await this.alertController.create({
        header: 'Fortato da imagem inválido',
        message: `Só são permitidos fotos no formato jpg, jpeg e png`,
        buttons: ['OK']
      });
      await alert.present();
      return;
    }
    const image = fileList[0];
    const src = URL.createObjectURL(image);
    this.resizeImage(src, { height: 512 }).then((blob: any) => {
      const image = document.querySelector("#resized") as HTMLImageElement || null;
      image.src = URL.createObjectURL(blob);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        this.file = file;
        this.avatar = reader.result;
        // console.log(reader.result);
      };
    });
  }

  loadImage(img, src) {
    return new Promise((resolve, reject) => {
      img.src = src;
      img.completed ? resolve(img) : img.addEventListener('load', () => resolve(img));
      img.addEventListener('error', reject);
    });
  }

  resizeImage(src, options) {
    return this.loadImage(document.createElement('img'), src)
      .then((image: any) => {
        const canvas = document.createElement('canvas');
        if (options.width && !options.height) {
          options.height = image.height * (options.width / image.width);
        } else if (!options.width && options.height) {
          options.width = image.width * (options.height / image.height);
        }
        Object.assign(canvas, options);
        canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
        return new Promise((resolve) => {
          canvas.toBlob(resolve, options.type || 'image/png', options.quality);
        });
      });
  }

  toggleSex() {
    this.form.controls.stage.patchValue(null);
    this.stagesFemalesText = '';
    this.stagesMalesText = '';
    if (this.parto) {
      if (this.form.value.sex === 'F') {
        this.form.controls.stage.patchValue(1);
      } else {
        this.form.controls.stage.patchValue(10);
      }
    }
  }

  backToHome() {
    closeAllModals();
  }

  getBreed(text) {
    this.breedsArray = this.breeds.filter(e => e.label.toUpperCase().includes(text.toUpperCase()));
  }

  setBreed(e) {
    this.breedText = e.label;
    this.form.controls.breed.patchValue(e.value);
  }

  getStagesFemales(text) {
    this.stagesFemalesArray = this.stagesFemales.filter(e => e.label.toUpperCase().includes(text.toUpperCase()));
  }

  setStagesFemales(e) {
    this.stagesFemalesText = e.label;
    this.form.controls.stage.patchValue(e.value);
  }

  getStagesMales(text) {
    this.stagesMalesArray = this.stagesMales.filter(e => e.label.toUpperCase().includes(text.toUpperCase()));
  }

  setStagesMales(e) {
    this.stagesMalesText = e.label;
    this.form.controls.stage.patchValue(e.value);
  }

  getAnimalsMale(text) {
    this.animalsMaleArray = this.animalsMale.filter(e => e.label.toUpperCase().includes(text.toUpperCase()));
  }

  setAnimalsMale(e) {
    this.animalsMaleText = e.label;
    this.form.controls.dad.patchValue(e.value);
  }

  getAnimalsFemale(text) {
    this.animalsFemaleArray = this.animalsFemale.filter(e => e.label.toUpperCase().includes(text.toUpperCase()));
  }

  setAnimalsFemale(e) {
    this.animalsFemaleText = e.label;
    this.form.controls.mon.patchValue(e.value);
  }
}
