import { ConnectivityService } from './../../connectivity.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PropriedadesService {
    private propriedades: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private connectivityProvider: ConnectivityService
    ) {
        this.propriedades = new BehaviorSubject([]);
    }

    get propriedades$(): Observable<any> {
        return this.propriedades.asObservable();
    }

    getPropriedades() {
        const user = JSON.parse(localStorage.getItem('Leitebem/dataUser') || '{}');
        if (!user) {
            return of([]);
        }

        const idGroupAdmin = 3;
        const isAdmin = user?.groups && user?.groups.length && user?.groups.length > 0 && user?.groups.find(el => el.group_id === idGroupAdmin);
        let urlGetProperty = this._httpClient.post(`${environment.api}/propriedadesId`, { idProprietario: user?.id});
        if (isAdmin && isAdmin.group_id === idGroupAdmin) {
            urlGetProperty = this._httpClient.get(`${environment.api}/propriedades/${user?.id}`);
        }

        return urlGetProperty.pipe(
            catchError((error) => {
                console.log(error);
                const propriedades = localStorage.getItem('Leitebem/propriedades');
                return of(propriedades ? JSON.parse(propriedades) : []);
            }),
            tap((items) => {
                localStorage.setItem('Leitebem/propriedades', JSON.stringify(items));
                this.propriedades.next(items);
            })
        );
    }

    save(propriedade): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.api}/propriedades`, propriedade)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    atualizar(propriedade): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${environment.api}/propriedade`, propriedade)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    deletar(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(`${environment.api}/propriedade/${id}`)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }
}
