import { takeUntil } from 'rxjs/operators';
import { GestaoRebanhoService } from './../gestao-rebanho.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { AnimalWeightComponent } from '../animal-weight/animal-weight.component';
import { Subject } from 'rxjs';
import { formatarValor } from 'app/core/utils/utils';

@Component({
  selector: 'app-historico-crescimento',
  templateUrl: './historico-crescimento.component.html',
  styleUrls: ['./historico-crescimento.component.scss'],
})
export class HistoricoCrescimentoComponent implements OnInit {
  animal;
  historicoCrescimento = [];
  historicoCrescimentoDataSource: MatTableDataSource<any> = new MatTableDataSource();
  historicoCrescimentoTableColumns: string[] = ['n', 'data', 'dias', 'pesagem', 'acumulado', 'acoes'];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private _gestaoRebanhoService: GestaoRebanhoService
  ) { }

  ngOnInit() {
    this._gestaoRebanhoService.animal$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(animal => {
        this.animal = animal;
        this.historicoCrescimento = [];
        this.historicoCrescimentoDataSource.data = [];
        animal.pesagens.sort((a, b) => moment(a.dateWeight).diff(moment(b.dateWeight)));
        animal.pesagens.map((p, i) => {
          const days = i === 0 ? 0 : moment(moment(p.dateWeight.split('T')[0]).format('YYYY-MM-DD')).diff(moment(moment(this.historicoCrescimento[i - 1].data, 'DD/MM/YYYY'), 'YYYY-MM-DD'), 'days');
          this.historicoCrescimento.push({
            id: p.id,
            weight: p.weight,
            dateWeight: p.dateWeight,
            n: i + 1,
            data: moment(p.dateWeight.split('T')[0]).format('DD/MM/YYYY'),
            days,
            total_acumulado: i === 0 ? '-' : formatarValor((p.weight - this.historicoCrescimento[i - 1].weight)/days)
          });
        });
        this.historicoCrescimentoDataSource.data = this.historicoCrescimento;
      })
  }

  async editarPesagem(pesagem = null) {
    const modal = await this.modalController.create({
      component: AnimalWeightComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: {
          pesagem:
            pesagem ? {
              animal: this.animal.identification + ' - ' + this.animal.name,
              id: pesagem.id,
              idAnimal: this.animal.id,
              dateWeight: pesagem.dateWeight,
              weight: pesagem.weight,
            } : {
              animal: this.animal.identification + ' - ' + this.animal.name,
              idAnimal: this.animal.id,
              PropriedadeId: this.animal.PropriedadeId,
            }
        }
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

  async deletarPesagem(id) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Deseja realmente concluir essa ação?',
      message: 'Clicando em "Sim" você estará excluindo esta pesagem.',
      buttons: [
        {
          text: 'Sim',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Por Favor Aguarde',
            });
            await loading.present();
            this._gestaoRebanhoService.excluirPeso(id)
              .then(async () => {
                await this._gestaoRebanhoService.getAnimal(this.animal.id).toPromise();
                loading.dismiss();
                const alert = await this.alertController.create({
                  header: 'Feito!',
                  message: `Peso excluída com sucesso`,
                  buttons: ['OK']
                });
                await alert.present();
              }).catch(async err => {
                loading.dismiss();
                const alert = await this.alertController.create({
                  header: 'Ops!',
                  message: `Erro ao excluir peso. Tente novamente mais tarde.`,
                  buttons: ['OK']
                });
                await alert.present();
              })
          }
        },
        {
          text: 'Cancelar'
        }
      ]
    });
    return await alert.present();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

}
