import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AppService {
  constructor(private _httpClient: HttpClient) {}

  sendNotificationToken(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${environment.api}/notification-token`, data)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }
}

export function closeAllModals() {
  const modals = document.getElementsByTagName("ion-modal");

  for (var i = 0; i < modals?.length; i++) {
    modals.item(i)?.dismiss();
  }
}
