import { GestaoRebanhoService } from './../gestao-rebanho.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, Platform, AlertController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { CalendarComponent } from '../../components/calendar/calendar.component';
import * as moment from 'moment';
import { closeAllModals } from "app/app.service";

@Component({
  selector: 'app-animal-weight',
  templateUrl: './animal-weight.component.html',
  styleUrls: ['./animal-weight.component.scss'],
})
export class AnimalWeightComponent implements OnInit {
  data;
  form: FormGroup;
  animal = '';
  isMobile: boolean = false;

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private _formBuilder: FormBuilder,
    private _gestaoRebanhoService: GestaoRebanhoService,
    public platform: Platform
  ) { }

  ngOnInit() {
    this.platform.ready().then(async () => {
      if (this.platform.is('mobile') || this.platform.is('mobileweb')) {
        // this.isMobile = true;
      }
    });
    
    this.form = this._formBuilder.group({
      id: [''],
      idAnimal: [''],
      PropriedadeId: [''],
      dateWeight: ['', [Validators.required]],
      weight: ['', [Validators.required]],
    });

    if (this.data && this.data.pesagem) {
      if (this.data.pesagem.id) {
        this.animal = this.data.pesagem.animal;
        this.form.patchValue({
          id: this.data.pesagem.id,
          dateWeight: moment(moment(this.data.pesagem.dateWeight.split('T')[0]).format('YYYY-MM-DD')).toDate(),
          weight: this.data.pesagem.weight,
        });
      } else {
        if (!this.data.pesagem.idAnimal || !this.data.pesagem.PropriedadeId)
          return;
        this.animal = this.data.pesagem.animal;
        this.form.patchValue({
          idAnimal: this.data.pesagem.idAnimal,
          PropriedadeId: this.data.pesagem.PropriedadeId,
          dateWeight: moment().toDate(),
          weight: 0,
        });
      }
    }
  }

  async calendar(control, title) {
    const modal = await this.modalController.create({
      component: CalendarComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: {
          date: this.form.value[control],
        },
        title
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.form.controls[control].patchValue(data);
    }
  }

  async salvar() {
    if (!this.form.valid) {
      const alert = await this.alertController.create({
        header: 'Ops!',
        message: `É necessário preencher todos os campos.`,
        buttons: ['OK']
      });
      return await alert.present();
    }
    this.form.disable();
    if (this.form.value.id) {
      this._gestaoRebanhoService.editarPeso({
        id: this.form.value.id,
        dateWeight: this.form.value.dateWeight,
        weight: this.form.value.weight,
      }).then(async () => {
        await this._gestaoRebanhoService.getAnimal(this.data.pesagem.idAnimal).toPromise();
        this.form.enable();
        const alert = await this.alertController.create({
          header: 'Feito!',
          message: `Peso atualizado com sucesso.`,
          buttons: ['OK']
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
        this.dismiss();
      }).catch(async () => {
        this.form.enable();
        const alert = await this.alertController.create({
          header: 'Ops!',
          message: `Erro ao atualizar peso. Tente novamente mais tarde.`,
          buttons: ['OK']
        });
        await alert.present();
      })
    } else {
      this._gestaoRebanhoService.criarPeso({
        idAnimal: this.form.value.idAnimal,
        PropriedadeId: this.form.value.PropriedadeId,
        dateWeight: this.form.value.dateWeight,
        weight: this.form.value.weight,
      }).then(async () => {
        await this._gestaoRebanhoService.getAnimal(this.data.pesagem.idAnimal).toPromise();
        this.form.enable();
        const alert = await this.alertController.create({
          header: 'Feito!',
          message: `Peso salvo com sucesso.`,
          buttons: ['OK']
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
        this.dismiss();
      }).catch(async () => {
        this.form.enable();
        const alert = await this.alertController.create({
          header: 'Ops!',
          message: `Erro ao salvar peso. Tente novamente mais tarde.`,
          buttons: ['OK']
        });
        await alert.present();
      })
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  backToHome() {
    closeAllModals();
  }

}
