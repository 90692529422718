import { GestaoRebanhoDetalhesComponent } from './gestao-rebanho-detalhes/gestao-rebanho-detalhes.component';
import { VideosDeAjudaComponent } from './../../videos-de-ajuda/videos-de-ajuda.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RegistrarPesagemComponent } from './registrar-pesagem/registrar-pesagem.component';
import { MorteComponent } from './../../animais/morte/morte.component';
import { HomeService } from './../../landing/home/home.service';
import { SelectComponent } from './../components/select/select.component';
import { AnimaisService } from './../../animais/animais.service';
import { AddAnimaisComponent } from './../../animais/add-animais/add-animais.component';
import { ResumoDetalhadoComponent } from './../../animais/resumo-detalhado/resumo-detalhado.component';
import { VenderComponent } from './../../animais/vender/vender.component';
import { AddOcorrenciasComponent } from './../../ocorrencias/add-ocorrencias/add-ocorrencias.component';
import { DashboardModalComponent } from './../dashboard-modal/dashboard-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GestaoRebanhoService } from './gestao-rebanho.service';
import { Component, OnInit, NgZone, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import * as moment from 'moment';
import { CoberturaComponent } from 'app/modules/cobertura/cobertura.component';
import { closeAllModals } from "app/app.service";
@Component({
  selector: 'app-gestao-rebanho',
  templateUrl: './gestao-rebanho.component.html',
  styleUrls: ['./gestao-rebanho.component.scss'],
})
export class GestaoRebanhoComponent implements OnInit {

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  array = [];
  filteredArray = [];
  stages = [
    { value: "", label: "Todos" },
    { value: 1, label: "Bezerra/Novilha" },
    { value: 2, label: "Novilha Coberta" },
    { value: 3, label: "Novilha Prenhe" },
    { value: 4, label: "Vaca em Lactação Vazia" },
    { value: 5, label: "Vaca em Lactação Coberta" },
    { value: 6, label: "Vaca em Lactação Prenhe" },
    { value: 7, label: "Vaca Seca Vazia" },
    { value: 8, label: "Vaca Seca Coberta" },
    { value: 9, label: "Vaca Seca Prenhe" },
    { value: 10, label: "Bezerro" },
    { value: 11, label: "Novilho" },
    { value: 12, label: "Touros" },
    { value: 13, label: "Sêmen" },
    { value: 14, label: "Animais Externos" },
  ];
  search = "";
  stage = 0;
  mom = moment;
  ordenarPor = [
    {
      label: 'Nome',
      value: 'nome'
    },
    {
      label: 'Brinco',
      value: 'brinco'
    }
  ]
  meses = [
    {
      label: '0 meses',
      value: 0
    },
    {
      label: '1 mês',
      value: 1
    },
    {
      label: '2 meses',
      value: 2
    },
    {
      label: '3 meses',
      value: 3
    },
    {
      label: '4 meses',
      value: 4
    },
    {
      label: '5 meses',
      value: 5
    },
    {
      label: '6 meses',
      value: 6
    },
    {
      label: '7 meses',
      value: 7
    },
    {
      label: '8 meses',
      value: 8
    },
    {
      label: '9 meses',
      value: 9
    },
    {
      label: '10 meses',
      value: 10
    },
    {
      label: '11 meses',
      value: 11
    },
    {
      label: '12 meses',
      value: 12
    },
  ]
  filters = {
    ordenarPor: 'brinco',
    order: 'asc',
    de: 0,
    ate: 12
  }

  page = 0;
  showHelp = false;

  constructor(
    private _gestaoRebanhoService: GestaoRebanhoService,
    private _homeService: HomeService,
    private modalController: ModalController,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private _animaisService: AnimaisService,
    public ngZone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef,
    public platform: Platform
  ) {
    const helpVideos = JSON.parse(localStorage.getItem('helpVideos') || '[]');
    if (helpVideos.filter(e => e === 'cadastro-animais').length)
      this.showHelp = true;
  }

  async ngOnInit() {
    this._gestaoRebanhoService.animaisFazenda$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(animaisFazenda => {
        this.array = animaisFazenda;
        this.filteredArray = animaisFazenda;
        this.filter();
      })
  }

  async select(title) {
    const modal = await this.modalController.create({
      component: SelectComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: this.stages,
        title
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data || data === '') {
      this.stage = data;
      this.filter();
    }
  }

  async select2(title, key) {
    let meses = [];
    if (key === 'ate') {
      meses = this.meses.slice(this.filters.de);
    } else {
      const bkmeses = JSON.parse(JSON.stringify(this.meses));
      bkmeses.splice(this.filters.ate + 1);
      meses = bkmeses;
    }
    const modal = await this.modalController.create({
      component: SelectComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: key === 'ordenarPor' ? this[key] : meses,
        title
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();

    if (data || data === '' || data === 0) {
      this.filters[key] = data;
      this.filter();
    }
  }

  filter() {
    this.ngZone.run(() => {
      this.filteredArray = this.array.filter(animal => {
        if (this.stage) {
          if (Number(this.stage) === 1) {
            const diff = moment().diff(moment(animal.dateBirth), 'months');
            if (this.search) {
              return (
                animal.idCurrentStages === this.stage && animal.name.toUpperCase().includes(this.search.toUpperCase()) || animal.identification.toUpperCase().includes(this.search.toUpperCase()) && diff >= this.filters.de && diff <= this.filters.ate
              );
            } else {
              return (
                animal.idCurrentStages === this.stage && diff >= this.filters.de && diff <= this.filters.ate
              );
            }
          } else {
            if (this.search) {
              return (
                animal.idCurrentStages === this.stage && animal.name.toUpperCase().includes(this.search.toUpperCase()) || animal.identification.toUpperCase().includes(this.search.toUpperCase())
              );
            } else {
              return (
                animal.idCurrentStages === this.stage
              );
            }
          }
        } else {
          return (
            animal.name.toUpperCase().includes(this.search.toUpperCase()) || animal.identification.toUpperCase().includes(this.search.toUpperCase())
          );
        }
      })
      this.filter2();
    });
  }

  filter2() {
    if (this.filters.ordenarPor === 'brinco') {
      if (this.filters.order === 'asc') {
        this.filteredArray = this.filteredArray.sort((a, b) => Number(a.identification) - Number(b.identification));
      } else {
        this.filteredArray = this.filteredArray.sort((a, b) => Number(b.identification) - Number(a.identification));
      }
    } else {
      if (this.filters.order === 'asc') {
        this.filteredArray = this.filteredArray.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.filteredArray = this.filteredArray.sort((a, b) => b.name.localeCompare(a.name));
      }
    }
  }

  async open(animal) {
    const modal = await this.modalController.create({
      component: DashboardModalComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: {
          step: 2,
          animal,
        }
      }
    });
    return await modal.present();
  }

  async desfazer(animal) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: `${animal.identification} - ${animal.name} | ${animal.nameStages}`,
      message: 'Você deseja desfazer o último registro do animal?',
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            alert.dismiss();
          }
        },
        {
          text: 'Sim',
          handler: () => {
            this.undo(animal.stageId)
          }
        }
      ]
    });
    return await alert.present();
  }

  async undo(currentIdStage) {
    // loading
    const loading = await this.loadingController.create({
      message: 'Por Favor Aguarde',
    });
    await loading.present();

    if (currentIdStage !== "") {
      try {
        this._animaisService.estagiosReproducao(currentIdStage)
          .then(async res => {
            const dataPesagem =
              localStorage.getItem('dataPesagem') ?
                JSON.parse(localStorage.getItem('dataPesagem')) :
                moment().format('YYYY-MM-DD');
            localStorage.setItem('dataPesagem', JSON.stringify(dataPesagem));

            const promises = [
              this._gestaoRebanhoService.getAnimaisFazenda().toPromise(),
              this._homeService.getPesagensLeiteIdPropriedade().toPromise(),
              this._homeService.getDetailsAnimalByPeriodAllDay(moment(dataPesagem).format('YYYY-MM-DD'))
            ];

            await Promise.all(promises);

            this._changeDetectorRef.detectChanges();
            loading.dismiss();

            const alert = await this.alertController.create({
              header: 'Feito!',
              message: `Estágio retrocedido.`,
              buttons: ['OK']
            });

            await alert.present();

            const { role } = await alert.onDidDismiss();
            // updateAnimals();
          }).catch(async err => {
            console.log(err);
            loading.dismiss();
            const message = err?.error?.Erro === 'Não há estágios para excluir!' ? 'Não há estágios para retroceder!' : 'Ocorreu um erro. Tente novamente mais tarde.';
            const alert = await this.alertController.create({
              header: 'Ops...',
              message,
              buttons: ['OK']
            });

            await alert.present();
          })
      } catch (err) {
        console.log(err);
        loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Ops...',
          message: `Não existe nenhum lançamento sobre reprodução.`,
          buttons: ['OK']
        });
      }
    } else {
      loading.dismiss();
      const alert = await this.alertController.create({
        header: 'Ops...',
        message: `Não existe nenhum lançamento sobre reprodução.`,
        buttons: ['OK']
      });
    }
  }

  async set(id) {
    if (!id)
      return;
    const modal = await this.modalController.create({
      component: GestaoRebanhoDetalhesComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: {
          animalId: id
        }
      }
    });
    await modal.present();
  }

  async novo() {
    const dataPropriedade = localStorage.getItem("Leitebem/dataPropriedade");
    if (!dataPropriedade) {
      const alert = await this.alertController.create({
        header: 'Lembrete!',
        message: 'É necessário selecionar uma propriedade antes de continuar', //erros.toString(),
        buttons: ['OK']
      });
      await alert.present();
      return false;
    }
    let modal;
    modal = await this.modalController.create({
      component: AddAnimaisComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: null
      }
    });
    return await modal.present();
  }

  async resumoDetalhado() {
    const dataPropriedade = localStorage.getItem("Leitebem/dataPropriedade");
    if (!dataPropriedade) {
      const alert = await this.alertController.create({
        header: 'Lembrete!',
        message: 'É necessário selecionar uma propriedade antes de continuar', //erros.toString(),
        buttons: ['OK']
      });
      await alert.present();
      return false;
    }
    let modal;
    modal = await this.modalController.create({
      component: ResumoDetalhadoComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: null
      }
    });
    return await modal.present();
  }

  async cobertura() {
    const dataPropriedade = localStorage.getItem("Leitebem/dataPropriedade");
    if (!dataPropriedade) {
      const alert = await this.alertController.create({
        header: 'Lembrete!',
        message: 'É necessário selecionar uma propriedade antes de continuar', //erros.toString(),
        buttons: ['OK']
      });
      await alert.present();
      return false;
    }
    let modal;
    modal = await this.modalController.create({
      component: CoberturaComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: null
      }
    });
    return await modal.present();
  }

  async registrarPesagem(animal) {
    const modal = await this.modalController.create({
      component: RegistrarPesagemComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: {
          animal,
        }
      }
    });
    return await modal.present();
  }

  correctStage(stage, birthDate) {
    if (stage === 1) {
      const onlyDate = birthDate.substr(0, 10);
      const afterOneYear = moment(new Date(onlyDate)).add(1, 'year');
      const today = new Date();
      const comparationDateBezerra = moment(afterOneYear).isSameOrAfter(today);

      if (comparationDateBezerra) {
        return "Bezerra";
      } else {
        return "Novilha";
      }
    }
  };

  dismiss() {
    this.modalController.dismiss();
  }

  backToHome() {
    closeAllModals();
  }

  async openHelpVideos() {
    const modal = await this.modalController.create({
      component: VideosDeAjudaComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        data: {
          title: 'de cadastro de animais',
          key: 'cadastro-animais'
        }
      }
    });
    await modal.present();
  }

}
