import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dashboard-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class KpiComponent implements OnInit {
  @Input() value;
  @Input() percent;
  @Input() icon;
  @Input() key;
  @Input() title;
  @Input() subtitle;
  @Input() action;
  @Input() loading;
  @Input() color;

  constructor() { }

  ngOnInit() {}

  round(percent) {
    return parseFloat(percent).toFixed(2);
  }
}
