import { LoadingController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { VideosDeAjudaService } from './videos-de-ajuda.service';
import { Component, OnInit } from '@angular/core';
import { closeAllModals } from "app/app.service";

@Component({
  selector: 'app-videos-de-ajuda',
  templateUrl: './videos-de-ajuda.component.html',
  styleUrls: ['./videos-de-ajuda.component.scss'],
})
export class VideosDeAjudaComponent implements OnInit {
  videos = [];
  data;

  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    public platform: Platform,
    private _videosDeAjudaService: VideosDeAjudaService
  ) { }

  async ngOnInit() {
    if (this.data && this.data.key) {
      const loading = await this.loadingController.create({
        message: 'Por Favor Aguarde',
      });
      await loading.present();
      this._videosDeAjudaService.getVideos(this.data.key)
        .then(res => {
          this.videos = res;
          loading.dismiss();
        })
        .catch(err => {
          console.log(err);
          loading.dismiss();
        })
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  backToHome() {
    closeAllModals();
  }

}
