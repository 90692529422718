import { takeUntil } from "rxjs/operators";
import { AnimaisService } from "./../../../animais/animais.service";
import { HomeService } from "./../../../landing/home/home.service";
import { MorteComponent } from "./../../../animais/morte/morte.component";
import { VenderComponent } from "./../../../animais/vender/vender.component";
import { AddAnimaisComponent } from "./../../../animais/add-animais/add-animais.component";
import { AddOcorrenciasComponent } from "./../../../ocorrencias/add-ocorrencias/add-ocorrencias.component";
import {
  AlertController,
  LoadingController,
  Platform,
  ModalController,
} from "@ionic/angular";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { GestaoRebanhoService } from "./../gestao-rebanho.service";
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";
import { closeAllModals } from "app/app.service";
import { environment } from "environments/environment";

@Component({
  selector: "app-gestao-rebanho-detalhes",
  templateUrl: "./gestao-rebanho-detalhes.component.html",
  styleUrls: ["./gestao-rebanho-detalhes.component.scss"],
})
export class GestaoRebanhoDetalhesComponent implements OnInit, OnDestroy {
  env = environment;
  data;

  animal;
  photo;
  array = [];

  loading: boolean = false;
  lactationPeriodsHistoryDetail;

  ocorrencias = [];
  filteredOcorrencias = [];
  selectedOccurence = "";
  occurencesTypes = {
    manejo: "Manejo",
    nutricao: "Nutrição",
    outros: "Outros",
    reproducao: "Reprodução",
    sanidade: "Sanidade",
  };

  page = 0;
  loadingLactacao: Boolean = false;
  lactations = [];
  lactationDataSource: MatTableDataSource<any> = new MatTableDataSource();
  lactationTableColumns: string[] = [
    "n",
    "data",
    "dias",
    "del",
    "litros",
    "acumulado",
    "producao",
  ];

  chartOptions = {
    series: [
      {
        name: "Média",
        data: [],
      },
    ],
    chart: {
      toolbar: {
        show: false,
      },
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [],
    },
    colors: ["#5ba74d"],
  };
  subscriptionAnimal: Subscription;
  subscriptionAnimais: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _gestaoRebanhoService: GestaoRebanhoService,
    private _animaisService: AnimaisService,
    private _homeService: HomeService,
    private _changeDetectorRef: ChangeDetectorRef,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    public platform: Platform
  ) {}

  ngOnInit() {
    this.subscriptionAnimais =
      this._gestaoRebanhoService.animaisFazenda$.subscribe((animaisFazenda) => {
        this.array = animaisFazenda;
      });

    this.subscriptionAnimal = this._gestaoRebanhoService.animal$.subscribe(
      (animal) => {
        console.log(animal);
        if (!animal) return;
        this.animal = null;
        this.lactationPeriodsHistoryDetail = null;

        this.ocorrencias = animal.animalsOccurrences;
        this.filteredOcorrencias = animal.animalsOccurrences;

        this.photo = animal.photo;

        let dateLastPregnant = "";
        let withAbortion = "";
        let dateCoverage = "";
        let dateDrying = "";
        let dateNextPregnant = "";
        let dateNextPregnantAux = "";
        let afterLimetedCoverage = "";
        let limetedCoverage = "";
        let diferenceLimit = "";
        let reproductiveState = "";
        let productiveState = "";
        let today = new Date();
        let openDays = "";
        let iepDays = "";
        let iepMonth = "";
        let dad: any = "";
        let mon: any = "";

        if (animal.summaryHerd) {
          let validDatePregnant = moment(
            animal.summaryHerd.lastPregnant
          ).isValid();
          let validDateCoverage = moment(
            animal.summaryHerd.coverageDate
          ).isValid();
          if (validDatePregnant && animal.summaryHerd.lastPregnant !== null) {
            let auxDate = new Date(
              new Date(animal.summaryHerd.lastPregnant).valueOf() +
                new Date(animal.summaryHerd.lastPregnant).getTimezoneOffset() *
                  60 *
                  1000
            );

            afterLimetedCoverage = moment(auxDate)
              .add(83, "days")
              .format("YYYY-MM-DD");
            limetedCoverage = moment(afterLimetedCoverage).format("DD/MM/YYYY");
            dateLastPregnant = moment(auxDate).format("DD/MM/YYYY");
            withAbortion = animal.summaryHerd.withAbortion;
          } else {
            dateLastPregnant = "";
            withAbortion = "";
            limetedCoverage = "";
          }

          if (validDateCoverage && animal.summaryHerd.coverageDate !== null) {
            let auxDate = new Date(
              new Date(animal.summaryHerd.coverageDate).valueOf() +
                new Date(animal.summaryHerd.coverageDate).getTimezoneOffset() *
                  60 *
                  1000
            );
            let afterDays = moment(auxDate)
              .add(275, "days")
              .format("YYYY-MM-DD");
            let afterDaysDrying = moment(auxDate)
              .add(215, "days")
              .format("YYYY-MM-DD");

            dateCoverage = moment(auxDate).format("DD/MM/YYYY");
            dateNextPregnant = moment(afterDays).format("DD/MM/YYYY");
            dateNextPregnantAux = afterDays;
            dateDrying = moment(afterDaysDrying).format("DD/MM/YYYY");
          } else {
            dateCoverage = "";
            dateNextPregnant = "";
            dateNextPregnantAux = "";
            dateDrying = "";
          }

          if (validDatePregnant && animal.summaryHerd.lastPregnant !== null) {
            if (validDateCoverage && animal.summaryHerd.coverageDate !== null) {
              openDays = moment(animal.summaryHerd.coverageDate)
                .diff(animal.summaryHerd.lastPregnant, "days")
                .toString();

              if (dateNextPregnantAux !== "") {
                iepDays = moment(dateNextPregnantAux)
                  .diff(animal.summaryHerd.lastPregnant, "days")
                  .toString();
                iepMonth = moment(dateNextPregnantAux)
                  .diff(animal.summaryHerd.lastPregnant, "months")
                  .toString();
              } else {
                iepDays = "";
                iepMonth = "";
              }
            } else {
              openDays = moment(moment().format("YYYY-MM-DD"))
                .diff(animal.summaryHerd.lastPregnant, "days")
                .toString();
            }
          } else {
            openDays = "";
          }

          if (
            validDateCoverage &&
            animal.summaryHerd.coverageDate !== null &&
            limetedCoverage !== ""
          ) {
            diferenceLimit = moment(animal.summaryHerd.coverageDate)
              .diff(afterLimetedCoverage, "days")
              .toString();
          } else {
            diferenceLimit = "";
          }

          if (
            animal.summaryHerd.idCurrentStages === 4 ||
            animal.summaryHerd.idCurrentStages === 7
          ) {
            reproductiveState = "Vazia";
          } else if (
            animal.summaryHerd.idCurrentStages === 3 ||
            animal.summaryHerd.idCurrentStages === 6 ||
            animal.summaryHerd.idCurrentStages === 9
          ) {
            reproductiveState = "Prenhe";
          } else if (
            animal.summaryHerd.idCurrentStages === 2 ||
            animal.summaryHerd.idCurrentStages === 5 ||
            animal.summaryHerd.idCurrentStages === 8
          ) {
            reproductiveState = "Coberta";
          }

          if (
            animal.summaryHerd.idCurrentStages === 4 ||
            animal.summaryHerd.idCurrentStages === 5 ||
            animal.summaryHerd.idCurrentStages === 6
          ) {
            productiveState = "Lactante";
          } else if (
            animal.summaryHerd.idCurrentStages === 7 ||
            animal.summaryHerd.idCurrentStages === 8 ||
            animal.summaryHerd.idCurrentStages === 9
          ) {
            productiveState = "Seca";
            dateDrying = "";
          } else {
            productiveState = "Bezerra/Novilha";
            dateDrying = "";
          }
        }

        this.animal = {
          id: animal.id,
          idAnimal: animal.id,
          avatar: animal.avatar,
          identification: animal.identification,
          name: animal.name,
          dateBirth: moment(animal.dateBirth.split("T")[0]).format(
            "DD/MM/YYYY"
          ),
          sex: animal.sex,
          sexBezerro: animal.sexBezerro,
          idDad: animal.idDad,
          dad: animal.namedad || animal.dad,
          idMother: animal.idMother,
          mother: animal.namemother || animal.mother,
          breed: animal.breed,
          del: animal.del,
          pesagens: animal.pesagens,
          primeiraPesagem: animal.primeiraPesagem,
          ultimaPesagem: animal.ultimaPesagem,
          dateLastPregnant: dateLastPregnant,
          withAbortion: withAbortion,
          reproductiveState: reproductiveState,
          productiveState: productiveState,
          touroCobertura: animal.ultimoBoiInformadoCobertura,
          limetedCoverage: limetedCoverage,
          dateCoverage: dateCoverage,
          diferenceLimit: diferenceLimit,
          openDays: openDays,
          dateNextPregnant: dateNextPregnant,
          iepDays: iepDays,
          iepMonth: iepMonth,
          dateDrying: dateDrying,
          quantifyCoverage: animal.quantidadeCoberturas,
          idCurrentStages: animal.summaryHerd
            ? animal.summaryHerd.idCurrentStages
            : null,
        };

        animal.periodos.map((p) => {
          this.lactations.push({
            id: p.id,
            idAnimal: p.idAnimal,
            start: p.start
              ? moment(p.start.split("T")[0]).format("DD/MM/YYYY")
              : "",
            end: p.end ? moment(p.end.split("T")[0]).format("DD/MM/YYYY") : "",
          });
        });
        this.setPage(this.lactations.length - 1);
        this.loading = false;
      }
    );

    if (!this.data || !this.data.animalId) return;

    try {
      this.loading = true;
      this._gestaoRebanhoService.getAnimal(this.data.animalId).subscribe();
    } catch (err) {
      console.log(err);
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this._gestaoRebanhoService.setAnimal(null);
    this.subscriptionAnimal.unsubscribe();
    this.subscriptionAnimais.unsubscribe();
  }

  filterOrorrencias(tipo) {
    if (tipo === "") return (this.filteredOcorrencias = this.ocorrencias);
    this.filteredOcorrencias = this.ocorrencias.filter((o) => o.type === tipo);
  }

  async deletarOcorrencia(id_animal, id) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Deseja realmente concluir essa ação?",
      message: 'Clicando em "Sim" você estará excluindo a ocorrência.',
      buttons: [
        {
          text: "Sim",
          handler: async () => {
            const loading = await this.loadingController.create({
              message: "Por Favor Aguarde",
            });
            await loading.present();
            this._gestaoRebanhoService
              .deleteOcorrencia(id_animal, id)
              .then(async () => {
                const index = this.ocorrencias.findIndex((o) => o.id === id);
                if (index > -1) {
                  this.ocorrencias.splice(index, 1);
                  this.filteredOcorrencias = this.ocorrencias;
                }
                const alert = await this.alertController.create({
                  header: "Feito!",
                  message: `Ocorrência excluída com sucesso`,
                  buttons: ["OK"],
                });
                loading.dismiss();
                await alert.present();
              })
              .catch(async (err) => {
                console.log(err);
                const alert = await this.alertController.create({
                  header: "Ops...",
                  message: `Erro ao excluir ocorrência. Tente novamente mais tarde.`,
                  buttons: ["OK"],
                });
                loading.dismiss();
                await alert.present();
              });
          },
        },
        {
          text: "Cancelar",
        },
      ],
    });
    return await alert.present();
  }

  async ocorrencia(animal) {
    console.log(animal);
    if (!animal || !animal.id || animal.id == undefined) return;
    const modal = await this.modalController.create({
      component: AddOcorrenciasComponent,
      cssClass: "my-custom-class",
      componentProps: {
        data: {
          id_animal: animal.id,
        },
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      const loading = await this.loadingController.create({
        message: "Por Favor Aguarde",
      });
      await loading.present();
      try {
        const occurences =
          await this._gestaoRebanhoService.getAnimalsOccurences(animal.id);
        this.ocorrencias = occurences;
        this.filterOrorrencias(this.selectedOccurence);
        loading.dismiss();
      } catch (error) {
        loading.dismiss();
        const alert = await this.alertController.create({
          header: "Ops!",
          message:
            "Não foi possível atualizar a lista de ocorrências do animal. Por favor, recarregue a página",
          buttons: ["OK"],
        });
        return await alert.present();
      }
    }
  }

  async editar(animal) {
    if (!animal || !animal.id || animal.id == undefined) return;
    const modal = await this.modalController.create({
      component: AddAnimaisComponent,
      cssClass: "my-custom-class",
      componentProps: {
        data: {
          animal,
          avatar: this.photo,
        },
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

  async deletarAnimal(animal) {
    if (!animal || !animal.id || animal.id == undefined) return;
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Deseja realmente concluir essa ação?",
      message: 'Clicando em "Sim" você estará excluindo o animal.',
      buttons: [
        {
          text: "Cancelar",
          handler: () => {
            alert.dismiss();
          },
        },
        {
          text: "Sim",
          handler: async () => {
            const loading = await this.loadingController.create({
              message: "Por Favor Aguarde",
            });
            await loading.present();
            this._animaisService
              .animalDesativar(animal.id)
              .then(async (res) => {
                await this._gestaoRebanhoService
                  .getAnimaisFazenda()
                  .toPromise();
                await this._homeService.getDashboardControlStage().toPromise();
                loading.dismiss();
                const alert = await this.alertController.create({
                  header: "Feito!",
                  message: "Animal deletado!",
                  buttons: ["OK"],
                });
                await alert.present();
                const { role } = await alert.onDidDismiss();
                this.dismiss();
              })
              .catch(async (err) => {
                console.log(err);
                loading.dismiss();
                const alert = await this.alertController.create({
                  header: "Ops!",
                  message: "Erro ao deletar animal. Tente novamente mais tarde",
                  buttons: ["OK"],
                });
                await alert.present();
              });
          },
        },
      ],
    });
    return await alert.present();
  }

  async vender(animal) {
    if (!animal || !animal.id || animal.id == undefined) return;
    const modal = await this.modalController.create({
      component: VenderComponent,
      cssClass: "my-custom-class",
      componentProps: {
        data: {
          animal,
        },
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    console.log(data);
    if (data) {
      setTimeout(() => {
        this.dismiss();
      }, 500);
    }
  }

  async morte(animal) {
    if (!animal || !animal.id || animal.id == undefined) return;
    const modal = await this.modalController.create({
      component: MorteComponent,
      cssClass: "my-custom-class",
      componentProps: {
        data: {
          animal,
        },
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      setTimeout(() => {
        this.dismiss();
      }, 500);
    }
  }

  setPage(page) {
    if (page >= 0 && page < this.lactations.length) {
      this.page = page;
      this.lac(
        this.lactations[this.page].idAnimal,
        this.lactations[this.page].id
      );
    }
  }

  async lac(id, period) {
    try {
      this.loadingLactacao = true;
      this.lactationDataSource.data = [];
      this.chartOptions.xaxis.categories = [];
      this.chartOptions.series = [
        {
          name: "Média",
          data: [],
        },
      ];
      const lactationPeriodsHistoryDetail =
        await this._gestaoRebanhoService.getLactationPeriodsHistoryDetail(
          id,
          period
        );
      const arrayTemp = [];
      lactationPeriodsHistoryDetail.lactatingDetailById.map((e, i) => {
        if (i === 0) {
          arrayTemp.push({
            label: moment(e.date_weighings.split("T")[0]).format("MM/YYYY"),
            value: e.sum_weighings,
            total: 1,
          });
        } else {
          if (
            moment(e.date_weighings.split("T")[0]).format("MM/YYYY") ===
            arrayTemp[arrayTemp.length - 1].label
          ) {
            arrayTemp[arrayTemp.length - 1].value += e.sum_weighings;
            arrayTemp[arrayTemp.length - 1].total += 1;
          } else {
            arrayTemp.push({
              label: moment(e.date_weighings.split("T")[0]).format("MM/YYYY"),
              value: e.sum_weighings,
              total: 1,
            });
          }
        }
      });
      for (const e of arrayTemp) {
        this.chartOptions.xaxis.categories.push(e.label);
        this.chartOptions.series[0].data.push((e.value / e.total).toFixed(2));
      }
      this.lactationDataSource.data =
        lactationPeriodsHistoryDetail.lactatingDetailById.map((l, i) => {
          return {
            n: i + 1,
            data: moment(l.date_weighings.split("T")[0]).format("DD/MM/YYYY"),
            days: lactationPeriodsHistoryDetail.diasCorridos[i].day,
            del: l.lactating_days.days,
            sum_weighings: l.sum_weighings.toFixed(2),
            total_acumulado:
              lactationPeriodsHistoryDetail.total_acumulado[i].total.toFixed(2),
            producaoPeriodo:
              lactationPeriodsHistoryDetail.producaoPeriodo[i].total.toFixed(2),
          };
        });
      this._changeDetectorRef.detectChanges();
      this.loadingLactacao = false;
    } catch (error) {
      console.log(error);
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  backToHome() {
    closeAllModals();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
