<div style="height: 27px;" class="font-semibold text-3xl tracking-tighter leading-tight justify-items-end flex flex-row content-center">
  <span [ngClass]="{'text-black': !color, 'color-red': color === 'red', 'color-green': color === 'green'}">{{ value || 0 }} <span *ngIf="percent" class="text-sm" [ngClass]="{'text-black': !color, 'color-red': color === 'red', 'color-green': color === 'green'}" >({{ round(percent) }}%)</span></span>
  <img class="w-14" *ngIf="loading" [src]="'../../../../assets/pontos.gif'">
  <mat-icon class="m-1" *ngIf="icon" svgIcon="mood_bad"></mat-icon>
  <button *ngIf="action" (click)="action(title, key)" mat-button class="m-0 p-0 -mt-1">
    <mat-icon svgIcon="visibility" [ngClass]="{'text-black': !color, 'color-red': color === 'red', 'color-green': color === 'green'}" ></mat-icon>
  </button>
</div>
<div class="flex flex-col content-center">
  <span class="text-1xl" [ngClass]="{'text-black': !color, 'color-red': color === 'red', 'color-green': color === 'green'}">{{ key === 'rankingdepesagens' ? 'Animais' : title }}</span>
  <span *ngIf="subtitle" class="text-xs absolute mt-4" [ngClass]="{'text-black': !color, 'color-red': color === 'red', 'color-green': color === 'green'}" >{{ subtitle }}</span>
  <!--  <button mat-button>-->
  <!--    <mat-icon svgIcon="arrow_forward"></mat-icon>-->
  <!--  </button>-->
</div>
